  // 修改antd基础组件样式，以满足UI组件规范。全局引入
  html {
    body {
      .ant-tabs {
        color: @blueGray-4;
        position: relative;

        .ant-tabs-nav {
          background: #fff;

          &::before {
            border-bottom: none;
          }
        }

        .ant-tabs-tab {
          font-size: 16px;
          line-height: 38px;
          padding: 0;

          .ant-tabs-tab-btn-fit {
            color: @blueGray-5;
            margin-left: 4px;
            // position: absolute;
          }

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              font-weight: @font-weight-medium;
            }
          }
        }

        .ant-tabs-ink-bar {
          background-color: @primary-color;
          height: 2px !important;
          border-radius: @border-radius-lg;
        }

        .ant-tabs-extra-content {
          margin-bottom: -6px;
          margin-right: -2px;
        }
      }

      // 带数字的tab组件需要加.dk-tabs-has-fit类名
      .ant-tabs.dk-tabs-has-fit {
        .ant-tabs-tab:not(:first-child) {
          margin-left: 32px;
        }
      }

      .ant-tooltip {
        max-width: 320px;
        border-radius: 4px !important;
        z-index: 2001;

        .ant-tooltip-arrow {
          display: none;
        }

        .ant-tooltip-inner {
          border-radius: 4px !important;
          padding: 4px 8px;
          min-height: auto;
          background-color: #2A2D31;
          color: rgba(255, 255, 255, 0.98);
          font-size: 12px;
          line-height: 17px;
        }
      }

      .dynamic-popover{
        .ant-popover-arrow-content::before{
          background-color: #385782 !important;
        }
        .ant-popover-inner-content{
          padding: 0 !important;
        }
      }

      .filter-tooltip {
        max-width: 100% !important;
      }

      .ant-tooltip-placement-bottom,
      .ant-tooltip-placement-bottomLeft,
      .ant-tooltip-placement-bottomRight {
        padding-top: 0 !important;
      }

      .ant-tooltip-placement-top,
      .ant-tooltip-placement-topLeft,
      .ant-tooltip-placement-topRight {
        padding-bottom: 0 !important;
      }

      &.hide {
        display: none;
      }

      //新功能提示的tips
      .new-functions-tooltip {
        z-index: 1000; //不能在预览上边

        .ant-tooltip-inner {
          background: @primary-color;
          color: #fff;
        }

        .ant-tooltip-arrow {
          display: block;
        }

        .blue-tip {
          background: @primary-color;
          color: #fff;
          padding: 0;
          margin: 0;
        }
      }

      .ant-popover {
        z-index: 999;

        .ant-popover-arrow {
          display: none;
        }

        .ant-popover-inner {
          background: #FFFFFF;
          box-shadow: 0px 5px 16px 0px rgba(60, 68, 88, 0.12);
          border-radius: @border-radius-attach;
          border: 1px solid #EFF0F1;
        }

        .ant-popover-inner-content {
          padding: 6px;
          color: #222A35;

          li {
            height: 32px;
            margin-bottom: 4px;
            font-size: 14px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            cursor: pointer;
            white-space: nowrap;
            position: relative;
            display: flex;
            // align-items: center;
            // justify-content: space-between;

            &:hover {
              background: @blueGray-12;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }


      .cooper_popover_Msg_arrow{
        .ant-popover-arrow{
          display: block !important;
        }
        .ant-popover-inner-content{
          padding: 16px;
          word-break: break-all;
        }
      }

      .ant-popover-placement-bottomLeft {
        padding-top: 0 !important;
      }

      .ant-popover-placement-bottomRight {
        padding-top: 0 !important;
      }

      .ant-popover-placement-topLeft {
        padding-bottom: 0 !important;
      }

      .ant-popover-placement-topRight {
        padding-bottom: 0 !important;
      }

      // btn的样式覆盖
      .ant-btn {
        border-radius: 4px;
        line-height: normal;

        >i {
          margin-right: 8px !important;
        }
      }
      .ant-btn-secondary[disabled], .ant-btn-secondary[disabled]:hover, .ant-btn-secondary[disabled]:focus, .ant-btn-secondary[disabled]:active{
        cursor: not-allowed !important;
        opacity: 0.5;
        color: @primary-color;
        border-color: @primary-color;
        background: white;
        :hover {
          color: @primary-color;
          border-color: @primary-color;
          background: white;
          opacity: 0.5;
        }
      }

      .ant-btn-primary {
        background-color: @primary-color;
        border: 1px solid @primary-color;
        color: #fff;
        box-shadow: none;

        &:hover {
          background-color: @primary-8;
        }

        &:focus {
          background-color: @primary-8;
        }
      }

      .ant-btn-primary[disabled],
      .ant-btn-primary[disabled]:hover,
      .ant-btn-primary[disabled]:focus,
      .ant-btn-primary[disabled]:active {
        background-color: @primary-4;
        color: #fff;
        border-color: transparent;
        cursor: not-allowed;
      }

      .ant-btn-secondary {
        background-color: @white;
        border: 1px solid @primary-color;
        color: @primary-color;

        &:hover {
          background-color: rgba(4, 127, 254, 0.1);
        }

        &:focus {
          background-color: rgba(4, 127, 254, 0.1);
        }
      }

      .ant-select {
        width: 100%;
      }

      .ant-select-selection-placeholder {
        font-size: 14px;
        right: 12px;
        left: 12px;
        color: @blueGray-6;
      }

      .ant-select:not(.ant-select-customize-input):not(.ant-select-multiple) .ant-select-selector {
        border: 1px solid @blueGray-9;
        border-radius: @border-radius-attach;
        font-size: 14px;
        padding: 0 12px;
      }

      .ant-input-affix-wrapper {
        border-radius: 6px;
      }

      .ant-select-single .ant-select-selector .ant-select-selection-search {
        right: 12px;
        left: 12px;
      }

      .ant-select.ant-select-multiple .ant-select-selector {
        height: 34px;
        border: 1px solid @blueGray-10;
        border-radius: @border-radius-attach;
        padding: 0 8px;
        margin-top: 3px;
      }

      .ant-select-selection-overflow-item {
        max-width: 80%;
      }

      .ant-select .ant-select-selector {
        &:hover {
          border: 1px solid @primary-color !important;
          cursor: pointer;
        }
      }

      .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
        box-shadow: none !important;
        border: 1px solid @primary-color !important;


      }

      .ant-select-dropdown {
        border-radius: @border-radius-lg;

        .rc-virtual-list {
          .rc-virtual-list-holder {
            max-height: 308px !important;
            padding: 2px 6px;

            .ant-select-item {
              margin-bottom: 2px;
              padding: 6px 8px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

      }


      .anticon .ant-input-clear-icon,
      .ant-input-clear-icon {
        color: @blueGray-8;
        font-size: 14px;
      }


      .ant-input-group-wrapper {
        border: 1px solid rgba(220,221,224,1);
        border-radius: @border-radius-sm;

        .ant-input-group-addon {
          background: @white;
          border: none;
          padding: 0 8px 0 9px;
        }

        .ant-input-affix-wrapper {
          padding: 0px;
          border: none;
          box-shadow: none;
        }

        &:hover {
          border: 1px solid @primary-color;
        }
      }

      .ant-radio-wrapper {
        &:hover {
          .ant-radio-inner {
            border-color: @primary-color;
          }
        }
      }

      .ant-input {
        font-size: 14px;
        height: 30px;
      }

      .ant-radio-inner {
        width: 14px;
        height: 14px;
        border: 1px solid @blueGray-8;

        &::after {
          display: none;
        }
      }

      .ant-radio-checked .ant-radio-inner {
        border: 4px solid @primary-color;
      }

      .ant-radio-wrapper {
        .font-size(14px);
      }
    }


    .ant-checkbox{
      transform: scale(0.9375);
      .ant-checkbox-inner{
        border-color: @blueGray-8;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-radius: 3px;
        border-color: @primary-color;
      }
    }


    .cooper-tip-popover {
      .ant-popover-inner {
        background-color: #FEFBE8 !important;
        box-shadow: 0 4px 22px 0 rgba(18, 19, 21, 0.16);
        border-radius: @border-radius-lg;
      }
    }

    .cooper-tip-popover.ant-popover-placement-rightTop {
      .ant-popover-content {
        transform: translateX(12px);
      }
    }

    .create-team-space {
      .ant-modal-content {
        height: 546px !important;
        border-radius: 12px;
        overflow: hidden;

        .ant-modal-close {
          display: none;
        }

        .ant-modal-body {
          height: 100%;
          padding: 0;
        }
      }
    }

    .team-space-init {
      .ant-modal-content {
        .ant-modal-close {
          display: block;
        }
      }
    }

    .folderTree-noAuthModal {
      .ant-modal-content {
        .ant-modal-confirm-btns {
          >button:first-child {
            display: none;
          }
        }
      }
    }

    .ant-modal-root {
      .folderTree-noAuthModal {
        .ant-modal-content {
          .ant-modal-footer {
            button:first-child {
              display: none;
            }
          }
        }
      }
    }

    .ant-input::placeholder {
      color: @blueGray-6;
    }

  }
 
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after{
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .ant-form{
    .ant-input:focus, .ant-input-focused{
      border: 1px solid @primary-color;
    }
  }
  // .ant-input-affix-wrapper {
  //   border: 1px solid #D3D4D7;
  // }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
    box-shadow: none;
    border-color: rgba(0,102,255,0.7);
  }
  .ant-input-textarea-show-count::before{
    float: right;
    color: rgba(34, 42, 53, 0.6);
    white-space: nowrap;
    content: attr(data-count);
    pointer-events: none;
    margin-top: -24px;
    padding-bottom: 8px;
    font-size: 12px;
  }
  .ant-input-textarea-show-count::after{
    display: none;
  }

  .base-service-modal{
    .ant-modal{
      width: 500px;
    }
    .ant-modal-content{
      border-radius: 8px;
      .ant-modal-close-x{
        position: relative;
        top: 6px;
        right: 4px;
      }
    }
    .ant-modal-body{
      padding: 20px 24px 0 24px
    }
    .ant-modal-header{
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 24px 24px 0 24px
    }
    .ant-modal-footer{
      border-top: none;
      padding: 4px 24px 24px 24px;
    }
  }
  .double-confirmation {
    .ant-modal-body {
      padding: 32px 32px 24px;
    }
  }
  
  .ant-tooltip.hover-no-auth-tip {
    .ant-tooltip-arrow {
      display: block !important;
      .ant-tooltip-arrow-content {
        background-color: #FFFFFF !important;
        --antd-arrow-background-color: '';
      }
    }
  }