/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.office-player {
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 0;
  left: 0;
}
.office-player .container {
  height: calc(100% - 1px);
  width: 100%;
  overflow: auto;
}
.office-player .container iframe {
  display: block;
  min-width: 100%;
  min-height: 100%;
  border: none;
  transform-origin: 0 0;
}
.office-player .tools {
  position: absolute;
  bottom: 70px;
  left: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.office-player .tools > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  width: 140px;
  opacity: 0;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  transition: 0.5s;
}
.office-player .tools:hover > div {
  opacity: 1;
}
.office-player .tools button {
  width: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.office-player .tools button img {
  height: 20px;
  width: 20px;
}
