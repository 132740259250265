/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.chrome-115-tips-modal {
  width: 1px;
  height: 1px;
}
.chrome-115-tips-modal :global .ant-modal {
  position: fixed !important;
  right: 20px;
  top: 20px !important;
}
.chrome-115-tips-modal :global .ant-modal-body {
  padding: 24px 24px 20px;
}
.chrome-115-tips-modal :global .ant-modal-footer {
  border-top: none;
}
.chrome-115-tips-modal .chrome-115-tips .chrome-115-tips-title {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
}
.chrome-115-tips-modal .chrome-115-tips .chrome-115-tips-title .chrome-115-tips-icon {
  font-size: 22px;
  color: #ffa50c;
  margin-right: 16px;
}
.chrome-115-tips-modal .chrome-115-tips .chrome-115-tips-title span {
  font-size: 16px;
  font-weight: 500;
}
.chrome-115-tips-modal .chrome-115-tips .chrome-115-tips-content {
  padding-left: 40px;
  font-size: 14px;
  line-height: 22px;
  color: #4E555D;
  margin-bottom: 16px;
}
.chrome-115-tips-modal .chrome-115-tips .chrome-115-tips-link {
  padding-left: 40px;
  font-size: 12px;
}
.chrome-115-tips-modal .chrome-115-tips .chrome-115-tips-link a {
  display: block;
  color: #047FFE !important;
}
.chrome-115-tips-modal .chrome-115-tips .chrome-115-tips-link a:hover {
  text-decoration: underline;
}
.chrome-115-tips-modal .chrome-115-tips .chrome-115-tips-link a:last-child {
  margin-top: 6px;
}
.chrome-115-tips-modal .chrome-115-tips-footer {
  padding-left: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
