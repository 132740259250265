/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.ant-table-column-sorter {
  margin-left: 4px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 14px;
  width: 16px;
  overflow: hidden;
  cursor: pointer;
}
.ant-table-column-sorter .ant-table-column-sorter-up.off {
  width: 0;
  height: 0;
  border-bottom: 5px solid #bec5d2;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.ant-table-column-sorter .ant-table-column-sorter-up.on {
  width: 0;
  height: 0;
  border-bottom: 5px solid #1890ff;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.ant-table-column-sorter .ant-table-column-sorter-up:last-child.off {
  width: 0;
  height: 0;
  border-top: 5px solid #bec5d2;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: none;
}
.ant-table-column-sorter .ant-table-column-sorter-up:last-child.on {
  width: 0;
  height: 0;
  border-top: 5px solid #1890ff;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: none;
}
