// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;






@color-primary: #06f;
.cooper-window__sharecoop {
  .ant-modal-body {
    padding: 16px 0;
  }

  .ant-modal-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .ant-modal-title {
    font-size: 16px;
    color: #333;
    line-height: 22px;
    font-weight: normal;
  }
  .ant-modal-close-x {
    font-size: 20px;
    line-height: 62px;
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
    height: 36px;
    line-height: 36px;
  }
  .ant-select-combobox .ant-select-search__field {
    height: 40px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-wrapper {
    max-height: 150px;
    overflow: scroll;
  }
  .ant-table-placeholder {
    border: none;
  }
}


.share-modal__form {
  padding: 0 0 24px 0;
  border-bottom: 1px solid #F1F1F4;

  .modal-field {
    margin-bottom: 24px;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    &.modal-field__radio {
      display: flex;
      align-items: flex-start;
      margin-bottom: 14px;
      .ant-radio-wrapper,
      .ant-checkbox-wrapper {
        position: relative;
        margin-right: 8px;
        bottom: 2px;
      }
      .ant-select-selection-selected-value {
        font-size: 14px;
        color: #17233e;
      }

      .ant-select-selection {
        margin-bottom: 0;
        border-color: #e4e9f3;
      }

      .ant-checkbox-inner {
        border-radius: 7px;
        border-color: #bdc5d2 !important;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #3f81ff !important;
        border-color: #3f81ff !important;
      }

      .ant-select-selection:hover,
      .ant-select-selection:focus {
        border-color: #3f81ff;
      }
    }
  }

  .field-name {
    display: inline-block;
    width: 80px;
    line-height: 26px;
    margin-right: 20px;
    text-align: right;
    font-size: 14px;
    color: #17233E;
    vertical-align: middle;
    &.field-sharename {
      vertical-align: top;
    }
  }

  .ant-radio-group small{
    font-size: 14px;
    color: #8a93a8;
    margin-left: 8px;
  }

  span.ant-radio + * {
    font-size: 14px;
  }

  .ant-radio-button-wrapper-checked {
    color: @color-primary;
    border-color: @color-primary;
    box-shadow: -1px 0 0 0 @color-primary;
  }
  .ant-radio-button-wrapper-checked:hover {
    border-color: @color-primary;
    box-shadow: -1px 0 0 0 @color-primary;
  }
  .ant-radio-button-wrapper:hover, .ant-radio-button-wrapper-focused {
    color: @color-primary;
  }
}

.share-modal__shareform {
  margin-top: 24px;
  border: none;
  .share-input__wrapper {
    position: relative;
    display: inline-block;
    flex: 1;
    // width: 456px;
    border-radius: 4px;
    border: 1px solid #e4e9f3;
    input {
      display: inline-block;
      padding: 7px;
      line-height: 30px;
      margin-right: 0;
      border-radius: 4px;
      width: 100%;
      height: 30px;
      outline: none;
      overflow: hidden;
      text-overflow: ellipsis;
      border: none;
      font-size: 14px;
      color: #17233E;
    }
    &.active {
      padding-right: 147px;
    }
    .share-pwd {
      position: absolute;
      right: 0;
      top: 0;
      width: 140px;
      bottom: 0;
      border-left: 1px solid #e4e9f3;
      text-align: center;
      line-height: 32px;
      color: #8a93a8;
      font-size: 14px;
    }
  }
}

.share-modal__actions {
  // margin: 0 24px;
  margin-top: 16px;
  text-align: right;
  .btn-share__cancel {
      background: #FFF;
      color: #17233e;
      padding: 5px 19px;
      line-height: 20px;
      font-size: 16px;
      outline: none;
      font-weight: normal;
      cursor: pointer;
      border: 1px solid #e4e9f3;
      margin-right: 10px;
      border-radius: 4px;
      transition: color .3s ease;
      font-size: 14px;
      &:hover {
        color: @color-primary;
        border: 1px solid @color-primary;
      }
  }

  .btn-share__ok {
      background: none;
      background: @color-primary;
      color: #FFF;
      padding: 5px 15px;
      line-height: 20px;
      font-size: 14px;
      outline: none;
      font-weight: normal;
      cursor: pointer;
      border-radius: 4px;
      transition: background .3s ease;
      border: 1px solid transparent;
      &:hover {
        background: @color-primary;
      }
  }
}

.share-modal__tip {
  margin-top: 8px;
  text-align: center;
  color: #ff3f5a;
  font-size: 14px;
  padding-bottom: 20px;
}

.share-perm {
  .perm-label {
    color: #666;
    font-size: 14px;
  }

  .ant-checkbox-disabled + span .perm-label {
    color: #999;
  }

  .ant-checkbox-group {
    display: inline-block;
  }
}
