/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
}
.cooper-confirm .ant-modal-body {
  padding: 0;
}
.cooper-confirm :global .ant-modal-content {
  border-radius: 8px !important;
}
.cooper-confirm :global .ant-modal-body .ant-modal-confirm-title {
  padding-right: 14px;
}
.cooper-confirm :global .ant-modal-body .user-link {
  color: #047FFE;
}
.cooper-confirm :global .ant-modal-header {
  border-radius: 8px 8px 0 0 !important;
  padding: 32px 32px 16px;
  border-bottom: 1px solid transparent;
}
.cooper-confirm :global .ant-modal-footer {
  border-top: 1px solid transparent;
  padding: 10px 32px 32px;
}
.cooper-confirm :global .ant-modal-close {
  top: 22px;
  right: 14px;
}
.cooper-confirm :global .dk-iconfont {
  font-size: 28px;
}
.cooper-confirm :global .dk-iconfont:hover {
  background-color: #EBEEF1;
  border-radius: 4px;
}
.cooper-confirm .ant-confirm-body {
  margin: 40px;
}
.cooper-confirm .ant-confirm-body::after {
  content: '';
  display: block;
  clear: right;
}
.cooper-confirm .ant-confirm-title,
.cooper-confirm .ant-confirm-content {
  word-break: break-word;
  font-size: 16px;
  display: inline-block;
  width: calc(100% - 40px);
}
.cooper-confirm .ant-confirm-content {
  font-size: 14px;
  float: right;
  margin-left: 40px;
  line-height: 22px;
}
.cooper-confirm .ant-confirm-content > div {
  min-height: 13px;
}
.cooper-confirm .ant-confirm-btns {
  margin: 0 24px 24px;
}
.cooper-confirm .ant-confirm-btns button {
  min-width: 68px;
  padding: 0 16px;
}
.cooper-confirm .ant-confirm-btns button:first-child {
  border-color: #e4e9f3;
}
.cooper-confirm .ant-confirm-btns button.ant-btn-primary {
  border: none;
}
.cooper-confirm .anticon-question-circle {
  padding: 0;
}
.cooper-confirm .anticon-question-circle::before {
  content: '';
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
}
.cooper-confirm .icon-close {
  position: absolute;
  top: 32px;
  right: 32px;
  display: block;
  width: 12px;
  height: 12px;
  background-image: url(./icon/icon_guanbi.svg);
  background-size: 12px 12px;
  cursor: pointer;
}
.cooper-confirm .icon-close:hover {
  background-image: url(./icon/icon_guanbi_hover.svg);
}
.cooper-confirm-no-cancel :global .ant-modal-content .ant-modal-confirm-btns > button:first-child {
  display: none;
}
.cooper-confirm-ask .anticon-question-circle::before {
  background-image: url(./icon/ask.svg);
}
.cooper-confirm-warn .anticon-question-circle::before {
  background-image: url(./icon/icon_xinxi_fill.svg);
}
.cooper-confirm-success .anticon-question-circle::before {
  background-image: url(./icon/icon_queren_fill.svg);
}
