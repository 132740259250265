/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.newfeature-popup :global .customer-service-popover .ant-popover-inner {
  border-radius: 8px !important;
  box-shadow: 0px 4px 20px 0px rgba(60, 68, 88, 0.16) !important;
}
.newfeature-popup :global .customer-service-popover .ant-popover-arrow {
  display: block !important;
}
.newfeature-popup :global .customer-service-popover .popup-main {
  width: 254px;
  max-height: 228px;
}
.newfeature-popup :global .customer-service-popover .ant-popover-inner-content {
  padding: 16px !important;
}
.newfeature-popup :global .customer-service-popover .customer-service-popup-newfeature {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.newfeature-popup :global .customer-service-popover .customer-service-popup-newfeature .customer-service-popup-newfeature-title {
  font-size: 14px;
  font-weight: normal;
  /* 带蓝中性色/BlueGray1 */
  color: #222A35;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 22px;
  max-height: 44px;
}
.newfeature-popup :global .customer-service-popover .customer-service-popup-newfeature .customer-service-popup-newfeature-img {
  border-radius: 6px;
  height: 124px;
  object-fit: cover;
  margin-top: 8px;
}
.newfeature-popup :global .customer-service-popover .customer-service-popup-newfeature .customer-service-popup-newfeature-op {
  text-align: right;
  margin-top: 20px;
  width: 100%;
}
.newfeature-popup :global .customer-service-popover .customer-service-popup-newfeature .customer-service-popup-newfeature-op .ant-btn:first-child {
  margin-right: 10px;
}
