/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.dk-upload-card-icon,
.dk-upload-operation--icon-wrap {
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dk-upload-card-icon::after {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-position: center center;
  background-repeat: no-repeat;
}
.dk-upload-operation {
  display: flex;
  width: 70px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  bottom: 5px;
  margin-left: 2px;
}
.dk-upload-operation-cancel::after {
  background-image: url(../../icon/cancel.svg);
}
.dk-upload-operation-cancel:hover::after {
  background-image: url(../../icon/cancel-hover.svg);
}
.dk-upload-operation-hint {
  font-size: 12px;
  color: #94A0B0;
  margin-right: 4px;
}
.dk-upload-operation .dk-upload-jumpTo {
  font-size: 16px;
  color: #C9CFD7;
  cursor: pointer;
}
.dk-upload-operation .dk-upload-jumpTo:hover {
  color: #047FFE;
}
.dk-upload-operation .dk-upload-result {
  color: red;
  font-size: 16px;
  cursor: pointer;
}
.dk-upload-operation .dk-upload-success {
  color: green;
}
.dk-upload-operation .dk-upload-fail {
  color: red;
}
