/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.file-tree-wrap :global .ant-tree-iconEle {
  margin-top: 6px;
}
.file-tree-wrap :global .ant-tree-switcher.ant-tree-switcher-noop::after {
  content: '';
}
.file-tree-wrap :global .ant-tree-treenode {
  width: 100%;
  line-height: 40px;
  height: 40px;
  border-radius: 6px;
  transition: all 0.2s;
}
.file-tree-wrap :global .ant-tree-treenode.ant-tree-treenode-selected {
  background: rgba(4, 127, 254, 0.09);
}
.file-tree-wrap :global .ant-tree-treenode:hover {
  background: #F7F7F7;
}
.file-tree-wrap :global .ant-tree-treenode .ant-tree-checkbox {
  margin: 12px 4px 0 0;
}
.file-tree-wrap :global .ant-tree-switcher {
  line-height: 40px;
}
.file-tree-wrap :global .ant-tree-treenode .ant-tree-node-content-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  line-height: 40px;
  height: 40px;
  cursor: default;
  overflow: hidden;
}
.file-tree-wrap :global .ant-tree-treenode .ant-tree-node-content-wrapper .ant-tree-title {
  flex: 1;
  position: relative;
  overflow: hidden;
}
.file-tree-wrap :global .ant-tree-treenode .ant-tree-node-content-wrapper .file-node-item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.file-tree-wrap :global .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: none;
}
.file-tree-wrap :global .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: none;
}
