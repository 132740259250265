/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.feedback-modal :global .ant-modal-content {
  padding: 24px;
  border-radius: 8px;
}
.feedback-modal :global .ant-modal-content .ant-modal-header {
  margin-bottom: 24px;
  border-bottom: none;
  padding: 0;
}
.feedback-modal :global .ant-modal-content .ant-modal-body {
  padding: 0;
}
.feedback-modal :global .ant-modal-content .ant-modal-footer {
  padding: 0;
  border-top: none;
}
.feedback-modal :global .ant-modal-content .ant-modal-footer .ant-btn {
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
  border: none;
  height: auto;
  padding: 8px 28px;
}
.feedback-modal :global .ant-modal-content .ant-modal-footer .ant-btn:first-child {
  color: #444B4F;
  background: #F6F6F6;
  margin-right: 8px;
}
.feedback-form .btn-wrap {
  text-align: right;
  margin-top: 14px;
}
.feedback-form .btn-wrap .btn-cancel {
  margin-right: 8px;
}
.feedback-form :global .ant-form-item-label {
  padding: 0 0 4px !important;
  font-weight: 500;
}
.feedback-form :global .ant-form-item {
  margin-bottom: 16px;
}
.feedback-form :global .red-num::before {
  color: #EA0000;
}
.feedback-form :global .textarea textarea {
  resize: none;
  border-radius: 4px;
}
.feedback-form :global .textarea textarea ::-webkit-input-placeholder {
  color: #BDC0C3;
}
.feedback-form :global #feedback_contactMsg {
  border-radius: 4px;
}
.feedback-form :global #feedback_contactMsg ::-webkit-input-placeholder {
  color: #BDC0C3;
}
