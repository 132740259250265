/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.error-tips-wrap {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -60px;
  font-size: 14px;
  color: rgba(34, 42, 53, 0.7);
}
.error-tips-wrap .title {
  margin-top: 25px;
  height: 20px;
  line-height: 20px;
}
.error-tips-wrap .sub-div {
  height: 60px;
}
.error-tips-wrap .desc {
  margin-top: 12px;
  height: 20px;
  line-height: 20px;
}
.error-tips-wrap .icon {
  width: 210px;
}
.error-tip-inPhone {
  width: 80%;
}
