/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.custom-filter-dropdown li {
  min-width: 128px;
  justify-content: space-between;
  margin-bottom: 2px !important;
}
.custom-filter-dropdown li .icon-selected {
  display: none;
  color: #076BFF;
}
.custom-filter-dropdown :global .ant-checkbox {
  transform: scale(0.875);
}
.custom-filter-dropdown :global .ant-checkbox-wrapper {
  width: 100%;
}
.custom-filter-dropdown :global .ant-checkbox-wrapper .ant-checkbox + span {
  padding-left: 6px;
  padding-right: 6px;
}
.custom-filter-dropdown .filter-resent {
  color: rgba(4, 127, 254, 0.4) !important;
  cursor: not-allowed !important;
}
.custom-filter-dropdown .filter-action {
  color: #047ffe !important;
  cursor: pointer !important;
}
.custom-filter-dropdown .filter-tip {
  justify-content: flex-start;
  position: relative;
  color: rgba(34, 42, 53, 0.5);
  font-size: 12px !important;
  cursor: default !important;
}
.custom-filter-dropdown .filter-tip:hover {
  background-color: #fff !important;
}
.custom-filter-dropdown .filter-tip .icon-tip {
  margin-left: 2px;
  font-size: 12px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-filter-dropdown .filter-tip-hover-content {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.98);
  font-family: PingFangSC-Regular;
}
.custom-filter-dropdown .filter-divider {
  margin: 0 0 2px;
}
.file-handle {
  cursor: pointer;
  height: 26px;
  padding-left: 4px;
  line-height: 24px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.file-handle .icon-shaixuan2 {
  padding: 2px 2px 0;
  font-size: 14px;
}
.file-handle:hover {
  background-color: rgba(34, 42, 53, 0.06);
}
.file-handle .filter-owner-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
}
.file-checked {
  display: flex;
  align-items: center;
  background-color: rgba(4, 127, 254, 0.1);
  color: #047FFE;
}
.file-checked .icon-shaixuan2 {
  padding: 2px 2px 0;
  font-size: 14px;
}
.file-checked:hover {
  background: rgba(4, 127, 254, 0.2);
  color: #047FFE;
}
