// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.search-modal-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  .search-page-wrap {
    min-width: 200px;
    max-width: 1072px !important;
    top: 0 !important;
    height: 80%;
    margin-top: 60px;
    max-height: 720px;
    min-height: 150px;
    padding-bottom: 0px;
  }
}
  
    
.search-page-wrap {
  // overflow: visible;
  :global {
    .ant-modal-content {
      height: 100%;
      border-radius: 6px;
    }
    .ant-modal-header {
      padding: 0;
      border-radius: 6px 6px 0 0;
    }
    .ant-modal-close-x {
      display: none;
    }
    .ant-input-lg {
      font-size: 18px;
    }
    
    .ant-input-clear-icon {
      svg {
        display: none;
      }
      &::after {
        content: '\6e05\9664';
        color: rgba(34, 42, 53, 0.5);
        font-size: 14px;
        transition: all 0.3s;
        z-index: 10;
      }
    }
    .ant-input-suffix:hover {
      .ant-input-clear-icon {
        &::after {
          color: #222A35;
        }
      }
    }
    .ant-modal-body {
      padding: 0;
      display: flex;
      height: calc(100% - 65px);
      overflow: hidden;
      &>:first-child {
        flex: 1;
        box-sizing: border-box;
        overflow: auto;
      }
      &>:last-child {
        width: 224px;
        box-sizing: border-box;
        padding: 0;
        height: 100%;
        overflow: auto;
        border-left: 1px solid #eee;
      }
    }
  }
  .ant-input-clear-icon {
    svg {
      display: none;
    }
    &::after {
      font-family: "dk-iconfont" !important;
      content: '\e6fd';
      font-size: 24px;
      z-index: 10;
      font-weight: 300;
      line-height: 24px;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      transition: all 0.3s;
      transform: scale(1.1);
    }
    &:hover {
      &::after {
        background: rgba(47, 52, 60, 0.08);
      }
    }
  }
  .search-modal-header {
    display: flex;
    height: 64px;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 23px 10px 26px;
    line-height: 20px;
    .search-header-left {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 18px;
      :global {
        input.ant-input {
          line-height: 1.66667 !important;
        }
        .ant-input-affix-wrapper-lg {
          padding: 3.4px 11px;
        }
      }
    }
    .search-header-right {
      cursor: pointer;
      width: 48px;
      height: 24px;
      line-height: 24px;
      vertical-align: middle;
      text-align: right;
      color: #505050;
      border-left: 1px solid #eee;
      padding-left: 24px;
    }
  }
}

.search-header-left-en {
  :global{
    .ant-input-clear-icon {
      svg {
        display: none;
      }
      &::after {
        content: 'Clear';
        color: rgba(34, 42, 53, 0.5);
        font-size: 14px;
        transition: all 0.3s;
        z-index: 10;
      }
    }
  }
}


.search-page-side {
  width: 280px;
  height: 100%;
  border-right: 1px solid @border-color-split;
  min-width: 280px;
  p {
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    color: @primary-color;
    line-height: 20px;
    padding: 7px 16px;
    border-radius: 17px;
    border: 1px solid @primary-color;
    margin: 30px auto;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background-color: @primary-color;
      color: #fff;
    }
  }
}
.search-page-main {
  position: relative;
  box-sizing: border-box;
  padding: 40px 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  &>div {
    width: 1032px;
  }
}


@media (max-width: 750px) {
  .search-page-wrap {
    :global {
      .ant-modal-body {
        &>:last-child {
          display: none;
        }
      }
    }
  }
}
