// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.cooper-list-content {
  .widthAdaptation();
  margin: -8px auto 0;

  .folder-tree {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .tb-header {
    font-size: 14px;
    font-weight: 500;
    color: #656A72;
    position: sticky;
    top: 36px;
    background-color: #fff;
    padding-left: 29px;
    padding-right: 32px;
    z-index: 4;

    .tb-header-div {
      height: 36px !important;
      line-height: 36px !important;
      border-bottom: 1px solid @blueGray-11;
      position: relative;

      .file-name {
        position: relative;
        overflow: visible;
        display: flex;
        align-items: center;

        .file-resizer {
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(50%);
        }
      }
    }

  }

  .dc-tb-header {
    padding-left: 21px;
  }

  .tb-header>.tb-header-div,
  .tb-body>li {
    height: 46px;
    line-height: 46px;
    width: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: @blueGray-4;

    >span {
      display: inline-block;
    }

    >.file-name {
      max-width: initial;
      min-width: 250px;
      height: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 3;
      cursor: pointer;
      padding-right: 12px;
    }


    .file-resizer {
      width: 20px;
      margin-right: 8px;
      margin-left: 52px;
      position: relative;
      text-align: center;

      .resizer-line {
        width: 1px;
        height: 50px;
        background: #076BFF;
        position: absolute;
        top: 0;
        left: 50%;
      }
    }

    .file-content-box {
      margin-right: 12px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .file-owner-content-box {
      padding-left: 4px;
    }

    >.file-address {
      height: 100%;
      width: 300px;
      min-width: 200px;
      display: flex;
      align-items: center;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .file-content-box {
        padding: 0 2px;
        height: 22px;
        border-radius: 4px;
        line-height: 22px;
        cursor: pointer;
        width: auto;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }


    >.file-owner,
    >.file-time {
      height: 100%;
      width: 130px;
      min-width: 130px;
      display: flex;
      align-items: center;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 12px;

      .file-content-box {
        cursor: default;
      }
    }

    .file-owner {
      width: 150px;
      min-width: 150px;
    }

    >.file-operate {
      width: 80px;
      height: 100%;
      display: flex;
      justify-content: right;
      align-items: center;
    }
  }

  @media screen and (max-width: 1050px) {

    .tb-header>.tb-header-div,
    .tb-body>li {

      >.file-name {
        min-width: 230px;
      }

      >.file-owner {
        min-width: 150px;
        overflow: hidden;
      }

      >.file-address {
        min-width: 130px;
      }
    }
  }

  .tb-body {
    padding-left: 27px;
    padding-right: 28px;
    padding-top: 8px;

    >li {
      padding-left: 4px;
      padding-right: 4px;

      .file-name {
        color: @blueGray-1;
      }
    }
  }

  .dc-tb-body {
    padding-left: 19px;
  }

  .tb-body-row:hover {
    border-radius: 4px;
    background: @blueGray-12;
  }
}


.file-time-sort {
  cursor: pointer;
}

.cooper-recent-table-loading {
  position: relative;
  margin-top: 16px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: @blueGray-4;
  height: 100%;
}

.loading-tip {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: @blueGray-4;

  .text {
    margin-left: 4px;
  }
}