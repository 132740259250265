/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.dropdown-checkbox {
  position: relative;
  display: inline-block;
  min-width: 140px;
  height: 32px;
  border-radius: 4px;
  width: 140px;
  cursor: pointer;
  vertical-align: middle;
  overflow: hidden;
}
.dropdown-checkbox.dropdown-smcheckbox {
  height: 28px;
  min-width: 120px;
  width: 120px;
  box-sizing: content-box;
}
.dropdown-checkbox.dropdown-smcheckbox .dropdown-checkbox__value .dropdown-checkbox__caret {
  height: 26px;
}
.dropdown-checkbox.dropdown-smcheckbox .dropdown-checkbox__value span {
  font-size: 12px;
}
.dropdown-checkbox.active {
  box-shadow: none !important;
}
.dropdown-checkbox .dropdown-checkbox__value {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #17233E;
  background: #FFF;
  border: 1px solid #D3D4D7;
  border-radius: 4px;
}
.dropdown-checkbox .dropdown-checkbox__value .dropdown-checkbox__caret {
  position: absolute;
  display: inline-block;
  width: 12px;
  height: 30px;
  right: 10px;
  top: 0;
  background-image: url('./ico-caret.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.dropdown-checkbox .dropdown-checkbox__value span {
  height: 32px;
  padding: 5px 28px 5px 8px;
  font-size: 12px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  vertical-align: top;
}
.dropdown-checkbox.dropdown-smcheckbox .dropdown-checkbox__value span {
  height: 28px;
  line-height: 18px;
}
.dropdown-checkbox-abled:hover .dropdown-checkbox__value {
  border-color: #0066FF;
}
.dropdown-checkbox-abled.active {
  box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
}
.dropdown-checkbox-abled.active .dropdown-checkbox__value {
  border-color: #0066FF;
}
.dropdown-checkbox-abled.active .dropdown-checkbox__caret {
  transform: rotate(0);
}
.dropdown-checkbox-disabled {
  cursor: not-allowed;
}
.dropdown-checkbox-disabled .dropdown-checkbox__value {
  color: #bbbbbb !important;
}
.dropdown-checkbox-disabled .dropdown-checkbox__value .dropdown-checkbox__caret {
  display: none;
}
.tether-element {
  z-index: 2999;
}
.tether-element .dropdown-checkbox__wrapper {
  padding: 4px 0;
}
.tether-element .dropdown-checkbox__items {
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(23, 35, 62, 0.2);
  border-radius: 4px;
  overflow: hidden;
}
.tether-element .dropdown-checkbox__item {
  position: relative;
  padding: 10px 28px 10px 10px;
  transition: background 0.3s ease;
  line-height: 20px;
  height: 40px;
  cursor: pointer;
}
.tether-element .dropdown-checkbox__item:hover {
  background: #F4F4F4;
}
.tether-element .dropdown-checkbox__item.dropdown-checkbox__itemaction {
  border-top: 1px solid #e5e6ec;
}
.tether-element .dropdown-checkbox__item.dropdown-checkbox__smitem {
  padding: 7px 28px 7px 10px;
  transition: background 0.3s ease;
  line-height: 16px;
  height: 32px;
}
.tether-element .dropdown-checkbox__item:hover .dropdown-checkbox__ico {
  display: inline-block;
}
.tether-element .dropdown-checkbox__item.dropdown-checkbox__chkitem .dropdown-checkbox__ico {
  display: inline-block;
  background-image: url('./ico-checked.png');
}
.tether-element .dropdown-checkbox__item.dropdown-checkbox__disableitem {
  cursor: not-allowed;
  color: #bec5d2;
}
.tether-element .dropdown-checkbox__item.dropdown-checkbox__disableitem .dropdown-checkbox__ico {
  display: none;
  background-image: url('./ico-hover.png');
}
.tether-element .dropdown-checkbox__item.dropdown-checkbox__disableitem_global.dropdown-checkbox__chkitem {
  cursor: not-allowed;
  color: #bec5d2;
}
.tether-element .dropdown-checkbox__item.dropdown-checkbox__disableitem_global.dropdown-checkbox__chkitem .dropdown-checkbox__ico {
  display: inline-block;
  background-image: url('./ico-hover.png');
}
.tether-element .dropdown-checkbox__item.dropdown-checkbox__disableitem_global {
  cursor: not-allowed;
  color: #bec5d2;
}
.tether-element .dropdown-checkbox__item span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font-size: 14px;
}
.tether-element .dropdown-checkbox__item .desc {
  font-size: 12px;
  color: #8a93a8;
  margin-left: 8px;
}
.tether-element .dropdown-checkbox__item .dropdown-checkbox__ico {
  display: none;
  position: absolute;
  display: inline-block;
  width: 12px;
  height: 12px;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.tether-element .dropdown-checkbox__hover_old:hover .dropdown-checkbox__ico {
  background-image: url('./ico-hover.png');
}
.dropdown-checkbox_cooper .dropdown-checkbox__items {
  width: 142px;
  background: #FFFFFF;
  box-shadow: 0px 6px 20px 0px rgba(18, 19, 21, 0.08);
  border-radius: 8px;
  padding: 7px;
}
.dropdown-checkbox_cooper .dropdown-checkbox__items .dropdown-checkbox__item {
  border-radius: 4px;
  height: 36px;
  padding: 8px 28px 8px 13px;
}
.dropdown-checkbox_cooper .dropdown-checkbox__items .dropdown-checkbox__item:hover {
  background: #F4F4F4;
}
.dropdown-checkbox_cooper .dropdown-checkbox__items .dropdown-checkbox__point {
  color: #F34C42;
}
.dropdown-checkbox_cooper .dropdown-checkbox__items .border-line {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  margin-top: 7px;
  margin-bottom: 7px;
}
.dropdown-checkbox_cooper .dropdown-checkbox__items .dropdown-checkbox__lonely {
  border-top: none;
}
.dropdown-checkbox_cooper .dropdown-checkbox__items .dropdown-checkbox__itemaction {
  border: none;
}
.dropdown-checkbox_cooper .dropdowm-checkbox__hover_recovery {
  background: rgba(4, 127, 254, 0.08);
  padding: 7px 8px !important;
  line-height: 17px;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  height: auto !important;
  margin-bottom: 1px;
}
.dropdown-checkbox_cooper .dropdowm-checkbox__hover_recovery:hover {
  background: rgba(4, 127, 254, 0.14) !important;
}
.dropdown-checkbox_cooper .dropdowm-checkbox__hover_recovery .recovery_label {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  text-align: justify;
}
.dropdown-checkbox_cooper .dropdowm-checkbox__hover_recovery .recovery_tip {
  position: absolute;
  right: 8px;
  font-size: 12px;
  margin-left: 11px;
  font-weight: 400;
  color: #047FFE;
  vertical-align: text-top;
}
.cooper-ant-tooltip__reset .ant-tooltip-arrow {
  display: none;
}
.cooper-ant-tooltip__reset.hide {
  display: none;
}
.cooper-ant-tooltip__reset .ant-tooltip-inner {
  max-width: 1200px;
  padding: 2px 8px;
  min-height: auto;
  background-color: #2A2D31;
  font-weight: 500;
  font-size: 12px;
}
