/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.search-modal-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.search-modal-wrap .search-page-wrap {
  min-width: 200px;
  max-width: 1072px !important;
  top: 0 !important;
  height: 80%;
  margin-top: 60px;
  max-height: 720px;
  min-height: 150px;
  padding-bottom: 0px;
}
.search-page-wrap :global .ant-modal-content {
  height: 100%;
  border-radius: 6px;
}
.search-page-wrap :global .ant-modal-header {
  padding: 0;
  border-radius: 6px 6px 0 0;
}
.search-page-wrap :global .ant-modal-close-x {
  display: none;
}
.search-page-wrap :global .ant-input-lg {
  font-size: 18px;
}
.search-page-wrap :global .ant-input-clear-icon svg {
  display: none;
}
.search-page-wrap :global .ant-input-clear-icon::after {
  content: '\6e05\9664';
  color: rgba(34, 42, 53, 0.5);
  font-size: 14px;
  transition: all 0.3s;
  z-index: 10;
}
.search-page-wrap :global .ant-input-suffix:hover .ant-input-clear-icon::after {
  color: #222A35;
}
.search-page-wrap :global .ant-modal-body {
  padding: 0;
  display: flex;
  height: calc(100% - 65px);
  overflow: hidden;
}
.search-page-wrap :global .ant-modal-body > :first-child {
  flex: 1;
  box-sizing: border-box;
  overflow: auto;
}
.search-page-wrap :global .ant-modal-body > :last-child {
  width: 224px;
  box-sizing: border-box;
  padding: 0;
  height: 100%;
  overflow: auto;
  border-left: 1px solid #eee;
}
.search-page-wrap .ant-input-clear-icon svg {
  display: none;
}
.search-page-wrap .ant-input-clear-icon::after {
  font-family: "dk-iconfont" !important;
  content: '\e6fd';
  font-size: 24px;
  z-index: 10;
  font-weight: 300;
  line-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  transition: all 0.3s;
  transform: scale(1.1);
}
.search-page-wrap .ant-input-clear-icon:hover::after {
  background: rgba(47, 52, 60, 0.08);
}
.search-page-wrap .search-modal-header {
  display: flex;
  height: 64px;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 23px 10px 26px;
  line-height: 20px;
}
.search-page-wrap .search-modal-header .search-header-left {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 18px;
}
.search-page-wrap .search-modal-header .search-header-left :global input.ant-input {
  line-height: 1.66667 !important;
}
.search-page-wrap .search-modal-header .search-header-left :global .ant-input-affix-wrapper-lg {
  padding: 3.4px 11px;
}
.search-page-wrap .search-modal-header .search-header-right {
  cursor: pointer;
  width: 48px;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  text-align: right;
  color: #505050;
  border-left: 1px solid #eee;
  padding-left: 24px;
}
.search-header-left-en :global .ant-input-clear-icon svg {
  display: none;
}
.search-header-left-en :global .ant-input-clear-icon::after {
  content: 'Clear';
  color: rgba(34, 42, 53, 0.5);
  font-size: 14px;
  transition: all 0.3s;
  z-index: 10;
}
.search-page-side {
  width: 280px;
  height: 100%;
  border-right: 1px solid rgba(34, 42, 53, 0.08);
  min-width: 280px;
}
.search-page-side p {
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: #047FFE;
  line-height: 20px;
  padding: 7px 16px;
  border-radius: 17px;
  border: 1px solid #047FFE;
  margin: 30px auto;
  cursor: pointer;
  transition: all 0.2s;
}
.search-page-side p:hover {
  background-color: #047FFE;
  color: #fff;
}
.search-page-main {
  position: relative;
  box-sizing: border-box;
  padding: 40px 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}
.search-page-main > div {
  width: 1032px;
}
@media (max-width: 750px) {
  .search-page-wrap :global .ant-modal-body > :last-child {
    display: none;
  }
}
