// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.ant-select-dropdown-menu-item {
  font-size: 14px;
  color: #17233e;
}

.cooper-share-modal {
  z-index: 2001 !important;
  .hidden {
    display: none;
  }
  .batch-add-title {
    line-height: 28px;
    display: flex;
    align-items: center;
    .back {
      cursor: pointer;
      margin-right: 16px;
      width: 24px;
      height: 24px;
      background: url('@/assets/icon/createKnowledgeBack.png') no-repeat;
      background-size: contain;
    }
  }


  .ant-modal-title > div {
    max-width: 450px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-modal-content {
    min-height: 364px;
    border-radius: 12px;
    background-color: #fefefe;
    box-shadow: 0 2px 8px 0 rgba(23,35,62,0.20);
  }

  .ant-modal-close {
    top: 6px;
    right: 8px;
  }

  .ant-modal-close-x {
    font-size: 18px;
    font-weight: 100;
    color: #bec5d2;

    &:hover {
      color: #0066FF;
    }
  }

  .ant-modal-header {
    border-bottom: 0;
    padding: 32px 32px 16px;
    border-radius: 8px;

    .ant-modal-title {
      font-size: 20px;
      color: #17233e;

      span {
        margin-right: 12px;
      }
    }
  }

  .ant-modal-body {
    padding: 0;
    .category {
      padding: 1px 32px 8px;
      a {
        font-size: 15px;
        color: #999999;
        position: relative;

        &:first-child {
          margin-right: 40px;
        }

        &.active {
          color: #333333;
          &::after {
            content: ' ';
            display: block;
            position: absolute;
            width: 24px;
            height: 2px;
            background:#0066FF;
            left: 17px;
            bottom: -5px;
          }
        }
      }
    }

    .direct-share {
      padding: 15px 0 32px 0;
    }

    .addMember-tabs {
      .ant-tabs-nav-wrap {
        padding: 0 32px;
      }
    }

  }
  &.show-batch-add .ant-modal-body .direct-share{
    padding: 0;
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    position: relative;
    margin-right: 8px;
  }

  .ant-radio-inner {
    border-color: #bec5d2;
  }


  .ant-select-selection-selected-value {
    font-size: 14px;
    color: #17233e;
  }

  .ant-select-selection {
    margin-bottom: 0;
    border-color: #e4e9f3;
  }

  .ant-checkbox-inner {
    border-radius: 12px;
    border-color: #bdc5d2 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0066FF !important;
    border-color: #0066FF !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    left: 2px;
    top: 2px;
    border: 0;
    height: 10px;
    width: 10px;
    background-image: url(./icon/icon_gouxuan.svg);
    background-size: 10px 10px;
    transform: rotate(0deg) scale(1);
  }

  .ant-select-selection:hover,
  .ant-select-selection:focus {
    border-color: #0066FF;
  }
}
.show-batch-add, .cooper-share-modal {
  .ant-modal {
    width: 640px !important;
  }
}