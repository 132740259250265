/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.import-wiki-modal {
  margin: 40px 0;
}
.import-wiki-modal :global .ant-modal-close .ant-modal-close-x {
  width: 24px;
  height: 24px;
  margin-top: 24px;
  margin-right: 24px;
}
.import-wiki-modal :global .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.import-wiki-modal :global .ant-modal-close .ant-modal-close-x .ant-modal-close-icon:hover {
  background-color: #F2F3F3;
  border-radius: 4px;
}
.import-wiki-modal :global .ant-modal-content {
  border-radius: 8px;
}
.import-wiki-modal :global .ant-upload.ant-upload-drag .ant-upload {
  padding: 12px 0 !important;
  color: #047FFE;
}
.import-wiki-modal :global .ant-upload.ant-upload-drag:not(.ant-upload-disabled) {
  border-color: rgba(4, 127, 254, 0.4);
  background-color: white;
  border-radius: 6px;
  margin: 0 0 8px 0;
}
.import-wiki-modal :global .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: rgba(4, 127, 254, 0.4);
  background-color: rgba(4, 127, 254, 0.06);
}
.import-wiki-modal :global .ant-upload-list-text-container {
  display: grid;
  gap: 2px;
}
.import-wiki-modal :global .ant-upload-list-text-container > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  opacity: 1;
  background: #F4F5F7;
  padding: 5px 8px;
}
.import-wiki-modal :global .ant-upload-list-text-container > div:hover {
  background: #EDEFF2;
}
.import-wiki-modal :global .ant-upload-list-text-container > div:hover .close-icon {
  display: flex;
}
.import-wiki-modal :global .ant-upload-list-text-container > div > div {
  flex: 1;
}
.import-wiki-modal :global .ant-upload-list-text-container > div .close-icon {
  flex: 0 18px;
  margin-left: 10px;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  display: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.import-wiki-modal :global .ant-upload-list-text-container > div .close-icon i {
  color: #909499;
}
.import-wiki-modal :global .ant-upload-list-text-container > div .close-icon:hover {
  background-color: #E0E2E3;
}
.import-wiki-modal :global .ant-upload-list-text-container > div .close-icon:hover i {
  color: #4E555D;
}
.import-wiki-modal :global .ant-upload-list-text-container > div .single-line {
  white-space: nowrap;
  overflow: hidden;
  width: 333px;
  text-overflow: ellipsis;
}
.import-wiki-modal :global .ant-upload-list-text-container > div .icon-zip {
  margin-right: 6px;
  display: block;
  width: 18px;
  height: 18px;
  background-image: url(./icon/zip.svg);
  background-size: 18px 18px;
}
.import-wiki-modal .import-wiki-title {
  width: 392px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #222A35;
  margin-bottom: 12px;
}
.import-wiki-modal .import-wiki-title-content-text {
  color: #4E555D;
  font-weight: 400;
  max-height: calc(100vh - 216px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -22px;
  padding-right: 22px;
}
.import-wiki-modal .import-wiki-title-content-text .fond-bond {
  color: #222A35;
  font-weight: 500;
  margin: 12px 0 8px;
}
.import-wiki-modal .import-wiki-title-content-text .wiki-help {
  position: relative;
  height: 136px;
  width: 100%;
  background-color: #F5F6F8;
  margin-bottom: 24px;
  border-radius: 6px;
}
.import-wiki-modal .import-wiki-title-content-text .wiki-help .wiki-help-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 254px;
}
.import-wiki-modal .import-wiki-title-content-text .wiki-help .wiki-help-button {
  position: absolute;
  left: 272px;
  top: 50%;
  transform: translateY(-50%);
}
.import-wiki-modal .import-wiki-title-content-text .wiki-help .wiki-help-button:hover {
  color: #222A35;
  background-color: #F2F3F3;
  border: 1px solid #E8E9EA;
}
.import-wiki-modal .import-wiki-title-content-text a {
  color: #047FFE;
  font-weight: 500;
}
.import-wiki-modal .import-wiki-footer {
  color: #222A35;
  margin-top: 16px;
  text-align: right;
}
.import-wiki-modal .import-wiki-footer .import-wiki-btn {
  margin-left: 8px;
  padding: 0 16px;
  font-size: 14px;
}
.import-wiki-modal .import-wiki-footer .import-wiki-btn.ant-btn-primary {
  background-color: #047FFE;
}
.import-wiki-modal .import-wiki-footer .import-wiki-btn-gray:hover {
  color: #222A35;
  background-color: #F2F3F3;
  border: 1px solid #E8E9EA;
}
