/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.main-layout-dk {
  height: 100%;
  flex: 1;
  border-radius: 8px 0 0 0;
}
.main-layout-dk .header-dk {
  position: relative;
  z-index: 1;
  width: 100%;
  background: #FFFFFF;
}
.main-layout-dk .main-dk {
  display: flex;
  position: relative;
  height: 100%;
  overflow-y: hidden;
}
.main-layout-dk .main-dk .body-content-wrap {
  flex: 1;
  padding: 0;
  overflow-x: auto;
  position: relative;
  text-align: left;
}
.main-layout-dk .main-dk .body-content-wrap .body-content {
  background-color: #FFFFFF;
  height: 100%;
  overflow: auto;
  margin: 0;
}
.main-layout-dk .main-dk .body-content-wrap .body-content-inphone {
  padding-top: 30px;
}
.main-layout-dk .main-dk-dc {
  overflow: unset;
}
.main-layout-dk .main-height-has-header {
  height: calc(100% - 56px);
}
.main-width-in-phone {
  min-width: 0 !important;
}
.main-height-in-tips {
  height: calc(100% - 30px) !important;
}
.main-layout-dk-dc-menhu {
  overflow-y: hidden;
}
.main-layout-dk-dc-menhu .body-content-wrap-dc {
  margin-left: 230px;
}
