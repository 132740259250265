/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.file-ellipsis {
  padding-right: 4px;
  display: flex;
  color: #2F343C;
  align-items: center;
  overflow: hidden;
  height: 22px;
  padding-left: 4px;
  width: 100%;
  cursor: pointer;
}
.file-ellipsis .emphasis {
  color: red;
}
.file-ellipsis .department-src {
  width: 42px;
  margin-left: 10px;
}
.file-ellipsis .file-ellipsis-inner-span {
  white-space: nowrap;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 0 2px;
  height: 22px;
  line-height: 22px;
  color: #222A35;
}
.file-ellipsis .file-ellipsis-inner-span:hover {
  border-radius: 4px;
}
.file-ellipsis .filename-side-star {
  margin-left: 2px;
  height: 24px;
  line-height: 24px;
  width: 24px;
  text-align: center;
  border-radius: 4px;
}
.file-ellipsis .filename-side-star :global(.dk-iconfont) {
  line-height: inherit;
}
.file-ellipsis .filename-side-star:hover {
  background-color: rgba(34, 42, 53, 0.1);
}
.file-ellipsis .icon-selected {
  color: #F6C746;
  width: 22px;
  height: 22px;
  border-radius: 4px;
}
.file-ellipsis:hover .filename-side-star {
  display: block !important;
}
.file-ellipsis .link-tag {
  font-family: PingFangSC-Medium;
  display: inline-block;
  background: rgba(4, 127, 254, 0.1);
  border-radius: 4px;
  text-align: center;
  padding: 0 8px;
  color: #047FFE;
  font-size: 22px;
  transform: scale(0.5);
  box-sizing: content-box;
  height: 36px;
  line-height: 36px;
  margin-left: -22px;
  margin-right: -26px;
}
.not-click {
  cursor: default;
}
