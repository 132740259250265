/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.transfer-search-wrap {
  height: 36px;
  border-radius: 4px;
  color: #222A35;
  font-size: 14px;
  width: 100%;
  position: relative;
}
.transfer-search-wrap .search-before {
  display: flex;
  align-items: center;
  background: none;
  height: 24px;
  line-height: 24px;
  color: #333333;
  border: none;
  font-size: 14px;
}
.transfer-search-wrap .search-tag {
  margin-left: 4px;
  padding: 0 4px 0 8px;
  border-radius: 3px;
  background: rgba(34, 42, 53, 0.08);
  display: flex;
  align-items: center;
}
.transfer-search-wrap .search-tag__text {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transfer-search-wrap .search-tag .search-close-icon {
  line-height: 24px;
}
.transfer-search-wrap :global .ant-input {
  font-size: 14px;
}
.transfer-search-wrap :global .ant-input-group-addon {
  background: none;
  border: none;
  padding: 0 0 0 20px;
}
.transfer-search-wrap :global .ant-input-affix-wrapper-lg {
  padding-left: 12px !important;
  font-size: 14px !important;
}
