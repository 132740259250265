// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.search-wrap {
  background-color: #ffffff;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  color: #222A35;
  font-size: 14px;
  transition: background-color 0.2;
  width: 100%;
  position: relative;

  &:hover {
    background-color: #ffffff;
  }

  .search-before {
    display: flex;
    align-items: center;
    background: none;
    height: 24px;
    line-height: 24px;
    color: #333333;
    border: none;
    font-size: 14px;
  }

  .search-tag {
    margin-left: 8px;
    padding: 0 4px 0 8px;
    border-radius: 3px;
    background: rgba(34, 42, 53, 0.08);
    display: flex;
    align-items: center;

    :global {
      .dk-icon-guanbi {
        vertical-align: text-bottom;
      }
    }
  }

  .search-icon-btn {
    display: block;
    align-items: center;
    line-height: 14px;
    font-size: 12px;
    border: 1px solid fade(#222A35, 20%);
    padding: 2px 6px;
    border-radius: 4px;
    margin-right: 4px;
    color: @black-6;
    cursor: pointer;

    &:hover {
      background: #D8DEE3;
      border-color: transparent;
    }

    .search-text {
      margin-right: 5px;
    }
  }


  :global {
    .ant-input {
      line-height: 1.66667;
      font-size: 14px;
    }

    .ant-input-group-addon {
      background: none !important;
      border: none;
      padding: 0 7px;
    }

    .ant-input-clear-icon {
      svg {
        display: none;
      }

      &::after {
        font-family: "dk-iconfont" !important;
        content: '\e607';
        font-size: 16px;
        z-index: 10;
      }
    }

    .ant-input-affix-wrapper-lg {
      padding: 2px 11px 2px 0 !important;
      font-size: 14px !important;
    }
  }

  :global {
    .ant-input-affix-wrapper {
      padding-left: 2px;
    }
  }
}

.click-tip {
  padding-right: 12px;
}

:global {
  .global-search-popover {
    padding-top: 8px !important;

    .ant-tooltip-inner {
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      background: #fff !important;
      padding: 8px;
      color: #222A35;
      font-size: 14px;
    }

    .ant-tooltip-arrow {
      top: 18px
    }

    .ant-tooltip-arrow-content {
      width: 8px;
      height: 8px;
      background: #fff;
    }

  }

  .global-search-popover {
    width: 540px !important;
    max-width: 540px !important;
    z-index: 1069 !important;

    &.share-search-popover {

      /*  width: 100%;
      max-width: 100%; */
      .search-more-wrap {
        text-align: right;

        span {
          font-size: 13px;
          color: rgba(34, 42, 53, 0.2);
        }
      }
    }

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      border: 1px solid #EFF0F2;
      padding: 0;
    }

    .search-popover-content {
      overflow: auto;
      padding-bottom: 54px;
      padding-top: 10px;

      .search-history-title {
        font-size: 13px;
        color: rgba(34, 42, 53, 0.5);
        line-height: 18px;
        padding: 2px 0px 8px 20px;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          margin: 0 8px 2px;

          &:first-child {
            margin-top: 0;
          }

          &.global-search-dk-title {
            font-size: 13px;
            line-height: 18px;
            margin: 12px 16px;
            margin-left: 19px;
            color: rgba(34, 42, 53, 0.5);

            &:hover {
              background-color: #fff;
            }
          }
        }
      }

      .search-metis-ul {
        width: 100%;
        padding: 0 10px;
        display: grid;
        grid-gap: 26px;
        grid-template-columns: repeat(auto-fill, 179px);
      }
    }

    .search-more-wrap {
      position: absolute;
      bottom: 1px;
      left: 0px;
      width: calc(100% - 1px);
      background-color: #fff;
      border-radius: 0 0 8px 8px;
      padding: 8px;
    }

    .search-more {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      color: rgba(34, 42, 53, 0.5);
      border-radius: 8px;
      line-height: 28px;
      padding: 4px 12px;

      .search-more-icon {
        font-size: 16px;
      }

      span {
        display: flex;
        align-items: center;
      }

      span:last-child {
        font-size: 13px;
        color: rgba(34, 42, 53, 0.3);
      }

      &:hover {
        background: #F7F8F9;
      }
    }
  }

}