// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.dropdown-checkbox {
  position: relative;
  display: inline-block;
  min-width: 140px;
  height: 32px;
  border-radius: 4px;
  width: 140px;
  cursor: pointer;
  vertical-align: middle;
  overflow: hidden;

  &.dropdown-smcheckbox {
    height: 28px;
    min-width: 120px;
    width: 120px;
    box-sizing: content-box;
    .dropdown-checkbox__value {
      .dropdown-checkbox__caret {
        height: 26px;
      }
      span {
        font-size: 12px;
      }
    }
  }

  &.active {
    box-shadow: none !important;
  }

  .dropdown-checkbox__value {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #17233E;
    background: #FFF;
    border: 1px solid #D3D4D7;
    border-radius: 4px;
    .dropdown-checkbox__caret {
      position: absolute;
      display: inline-block;
      width: 12px;
      height: 30px;
      right: 10px;
      top: 0;
      background-image: url('./ico-caret.png');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      transform: rotate(180deg);
      transition: transform .3s ease;
    }
    span {
      height: 32px;
      padding: 5px 28px 5px 8px;
      font-size: 12px;
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      vertical-align: top;
    }
  }
  &.dropdown-smcheckbox {
    .dropdown-checkbox__value {
      span {
        height: 28px;
        line-height: 18px;
      }
    }
  }
}

.dropdown-checkbox-abled {
  &:hover {
    .dropdown-checkbox__value {
      border-color: #0066FF;
    }
  }
  &.active {
    .dropdown-checkbox__value {
      border-color: #0066FF;
    }
    box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
    .dropdown-checkbox__caret {
      transform: rotate(0);
    }
  }
}

.dropdown-checkbox-disabled{
  cursor: not-allowed;
  .dropdown-checkbox__value{
    color: #bbbbbb !important;
    .dropdown-checkbox__caret{
      display: none;
    }
  }
}
.tether-element {
  z-index: 2999;
  .dropdown-checkbox__wrapper {
    padding: 4px 0;
  }
  .dropdown-checkbox__items {
    background: #fff;
    box-shadow: 0 2px 8px 0 rgba(23,35,62,0.20);
    border-radius: 4px;
    overflow: hidden;
  }
  .dropdown-checkbox__item {
    position: relative;
    padding: 10px 28px 10px 10px;
    transition: background .3s ease;
    line-height: 20px;
    height: 40px;
    cursor: pointer;
    &:hover{
      background: #F4F4F4;
    }
    &.dropdown-checkbox__itemaction {
      border-top: 1px solid #e5e6ec;
    }
    &.dropdown-checkbox__smitem {
      padding: 7px 28px 7px 10px;
      transition: background .3s ease;
      line-height: 16px;
      height: 32px;
    }
    &:hover {
      .dropdown-checkbox__ico {
        display: inline-block;
      }
    }
    &.dropdown-checkbox__chkitem {
      .dropdown-checkbox__ico {
        display: inline-block;
        background-image: url('./ico-checked.png');
      }
    }
    &.dropdown-checkbox__disableitem {
      cursor: not-allowed;
      color: #bec5d2;
      .dropdown-checkbox__ico {
        display: none;
        background-image: url('./ico-hover.png');
      }
    }
    &.dropdown-checkbox__disableitem_global.dropdown-checkbox__chkitem {
      cursor: not-allowed;
      color: #bec5d2;
      .dropdown-checkbox__ico {
        display: inline-block;
        background-image: url('./ico-hover.png');
      }
    }
    &.dropdown-checkbox__disableitem_global {
      cursor: not-allowed;
      color: #bec5d2;
    }
    span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      font-size: 14px;
    }

    .desc {
      font-size: 12px;
      color: #8a93a8;
      margin-left: 8px;
    }

    .dropdown-checkbox__ico {
      display: none;
      position: absolute;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-size: 12px 12px;
      background-repeat: no-repeat;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .dropdown-checkbox__hover_old{
    &:hover {
      .dropdown-checkbox__ico {
        background-image: url('./ico-hover.png');
      }
    }
  }
}

// 新版ui样式
.dropdown-checkbox_cooper{
  .dropdown-checkbox__items{
    width: 142px;
    background: #FFFFFF;
    box-shadow: 0px 6px 20px 0px rgba(18,19,21,0.08);
    border-radius: 8px;
    padding: 7px;
    .dropdown-checkbox__item{
      border-radius: 4px;
      height: 36px;
      padding: 8px 28px 8px 13px;
      &:hover{
        background: #F4F4F4;
      }
    }
    .dropdown-checkbox__point{
      color: #F34C42;
    }
    .border-line{
      width: 100%;
      height: 1px;
      background-color: #eeeeee;
      margin-top: 7px;
      margin-bottom: 7px;
    }
    .dropdown-checkbox__lonely{
      border-top: none;
    }
    .dropdown-checkbox__itemaction{
      border:none;
    }
  }
   // 本期新增
   .dropdowm-checkbox__hover_recovery{
    background:rgba(4, 127, 254, 0.08);
    padding:7px 8px !important;
    line-height: 17px;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    height: auto !important;
    margin-bottom: 1px;
    &:hover{
      background: rgba(4, 127, 254, 0.14) !important;
    }
    .recovery_label{
        font-size: 12px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 17px;
        text-align: justify;
      }
      .recovery_tip{
        position: absolute;
        right: 8px;
        font-size: 12px;
        margin-left: 11px;
        font-weight: 400;
        color: #047FFE;
        vertical-align: text-top;
      }
  }
 

}

// tooltip公共覆盖样式类
.cooper-ant-tooltip__reset {
  .ant-tooltip-arrow {
    display: none;
  }
  &.hide {
    display: none;
  }
  .ant-tooltip-inner {
    max-width: 1200px;
    padding: 2px 8px;
    min-height: auto;
    background-color: #2A2D31;
    font-weight: 500;
    font-size: 12px;
  }
}


