/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-customer-service {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-left: 20px;
}
.cooper-customer-service .customer-service-bar {
  line-height: 18px;
  height: 18px;
  margin-right: 10px;
  border-left: 1px solid #E8E9EA;
}
