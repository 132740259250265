/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.lite-error-notice :global .ant-modal-content {
  background: url(https://img-ys011.didistatic.com/static/cooper_cn/error-notice.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
}
.lite-error-notice :global .ant-modal-body {
  padding: 24px !important;
}
.lite-error-notice .lite-notice-logo {
  text-align: center;
  margin-top: 18px;
  margin-bottom: 25px;
}
.lite-error-notice .lite-notice-logo img {
  width: 56px;
}
.lite-error-notice .lite-notice-title {
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 8px;
  color: #222A35;
}
.lite-error-notice .lite-notice-content {
  font-family: PingFang SC;
  font-size: 14px;
  color: rgba(34, 42, 53, 0.8);
  line-height: 22px;
  margin-bottom: 48px;
}
.lite-error-notice .lite-notice-footer {
  text-align: center;
  margin-bottom: 12px;
}
.lite-error-notice .lite-notice-btn {
  padding: 6px 24px;
  border-radius: 4px;
  font-size: 14px;
}
