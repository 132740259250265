/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.suiteEnterMenu {
  padding: 7px;
}
.suiteEnterMenu .suiteEnterRow {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 18px 0 0px !important;
  min-width: 235px !important;
  height: 44px !important;
  font-size: 14px !important;
  color: #1C2A3D;
  border-radius: 6px;
  white-space: nowrap;
}
.suiteEnterMenu .suiteEnterRow.withDesc {
  height: 56px !important;
  font-weight: 500;
}
.suiteEnterMenu .suiteEnterRow.suiteManager {
  height: 46px;
}
.suiteEnterMenu .suiteEnterRow.disabled {
  cursor: not-allowed;
}
.suiteEnterMenu .suiteEnterRow:hover {
  background: #F4F4F4;
}
.suiteEnterMenu .suiteEnterRow:first-child {
  border-top: none;
}
.suiteEnterMenu .suiteEnterRow .openArea {
  line-height: 17px;
  margin-bottom: 1px;
}
.suiteEnterMenu .suiteEnterRow .openArea .icon {
  font-size: 12px;
}
.suiteEnterMenu .suiteEnterRow .openArea .btn {
  font-size: 12px;
  font-weight: 400;
  float: right;
}
.suiteEnterMenu .suiteEnterRow .openArea .btn.notOpen {
  color: #999999;
}
.suiteEnterMenu .suiteEnterRow .openArea .btn.open {
  color: #047FFE;
}
.suiteEnterMenu .suiteEnterRow .nameArea {
  width: 100%;
}
.suiteEnterMenu .suiteEnterRow .out-yellow {
  color: #FFB800;
}
.suiteEnterMenu .suiteEnterRow .suite-tip {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
.suiteEnterMenu .suite-list-item-icon {
  width: 22px;
  height: 22px;
  margin-right: 16px;
  margin-left: 14px;
  line-height: 22px;
}
.suiteEnterMenu .divider {
  height: 1px !important;
  background-color: #f0f0f0;
  margin: 6px 0;
}
