/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.feedback .content {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
}
.feedback .content .icon {
  width: 20px;
  height: 20px;
  text-align: center;
}
.feedback .content:hover {
  background: rgba(34, 42, 53, 0.08);
  cursor: pointer;
}
.feedback :global .dk-icon-lianxikefu {
  font-size: 18px;
}
