/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.view-page-tree {
  height: 100%;
}
.view-page-tree .catalog-title-li {
  margin-bottom: 0;
}
.view-page-tree .operate-wrap {
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
  height: 24px;
}
:global .import-choose-global .ant-popover-inner-content {
  padding: 12px;
}
