/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.search-page-list {
  position: absolute;
  width: 100%;
  height: 426px;
  margin-top: 20px;
  background: #fff;
  border-radius: 4px;
  padding: 6px;
  z-index: 2;
}
.search-page-list .search-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1890ff;
}
.search-page-list .search-loading .search-list-noData {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.search-page-list .search-loading .search-list-noData .img {
  width: 170px;
  height: 150px;
}
.search-page-list .search-loading .search-list-noData .text {
  margin-top: 14px;
  font-weight: 400;
  color: #505050;
  line-height: 14px;
}
.search-page-list .search-item {
  padding: 12px 14px 12px 11px;
  border-radius: 8px;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.search-page-list .search-item:hover {
  background: #F7F8F9;
}
.search-page-list .search-item > img {
  width: auto;
  height: 35px;
  line-height: 35px;
}
.search-page-list .show-allData {
  display: block;
  text-align: center;
  font-weight: 400;
  color: rgba(34, 42, 53, 0.5);
  line-height: 20px;
  padding: 24px 0px;
}
.search-page-list .right {
  padding-left: 14px;
  position: relative;
  max-width: calc(100% - 35px);
  flex: 1;
}
.search-page-list .title {
  font-size: 14px;
  margin-bottom: 1px;
  color: #111;
  line-height: 20px;
  height: 20px;
  font-weight: 500;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-page-list .desc {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-page-list .desc,
.search-page-list .other {
  font-size: 12px;
  color: rgba(34, 42, 53, 0.5);
  margin-bottom: 0;
}
.search-page-list .other {
  max-width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.search-page-list .other i {
  font-size: 12px;
}
.search-page-list .other .knowledge-name {
  max-width: 140px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
