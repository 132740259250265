/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.tab-top-title {
  flex: 1;
}
.tab-top-title :global .ant-tabs-content-top {
  height: 100%;
}
.tab-top-title :global .ant-tabs-nav {
  margin-bottom: 15px;
}
.tab-top-title :global .ant-tabs-ink-bar {
  display: none !important;
}
.tab-top-title :global .ant-tabs-tab-active {
  position: relative;
}
.tab-top-title :global .ant-tabs-tab-active::before {
  content: '';
  width: 24px;
  height: 2px;
  background-color: #047FFE;
  left: calc(50% - 12px);
  bottom: 0px;
  z-index: 100;
  position: absolute;
}
.tab-top-large :global .ant-tabs-tab {
  font-size: 16px !important;
  line-height: 24px !important;
  padding-top: 4px !important;
  padding-bottom: 6px !important;
}
.tab-top-small :global .ant-tabs-tab {
  font-size: 14px !important;
  line-height: 18px !important;
  padding-top: 4px !important;
  padding-bottom: 6px !important;
}
