/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.link {
  color: #047FFE;
  cursor: pointer;
}
.publish-text {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.close-icon {
  margin-left: 8px;
  font-size: 24px;
  line-height: 28px;
  cursor: pointer;
}
.notification-modal {
  padding-bottom: 0;
}
.notification-modal :global .ant-modal-content {
  border-radius: 6px;
}
.notification-modal :global .ant-modal-content .ant-modal-body {
  padding: 32px 28px 24px;
}
.notification-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-title {
  font-size: 24px;
}
.notification-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-content {
  margin-top: 10px;
  font-size: 16px;
  color: #444B4F;
  line-height: 24px;
}
.notification-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns {
  margin-top: 36px;
}
.notification-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  height: 44px;
  background: #F6F6F6;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  color: #444B4F;
}
.notification-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn::after {
  display: none;
}
.notification-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  margin-left: 16px;
  color: #FFFFFF;
  background: #047FFE;
}
