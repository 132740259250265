/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.header-icon-wrap {
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
  border-radius: 4px;
}
.header-icon-wrap:hover {
  background: #ECEDF1;
}
.header-icon-wrap.icon-wrap-hover .header-img-wrap {
  border-radius: 4px;
  transition: all 0.2s;
}
.header-icon-wrap.icon-wrap-hover .header-img-wrap:hover {
  background: #ECEDF1;
}
.header-icon-wrap .suiteEnterChoose.hide {
  display: none;
}
.header-img-wrap {
  position: relative;
  width: 26px;
  height: 26px;
}
.header-img-wrap img {
  position: absolute;
  width: 22px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
}
.header-img-wrap .header-img-icon,
.header-img-wrap .header-img-icon-visible {
  opacity: 1;
}
.header-img-wrap .header-img-icon-active,
.header-img-wrap .header-img-icon-unvisible,
.header-img-wrap .has-visible {
  opacity: 0;
}
.header-img-wrap:hover .header-img-icon.has-active {
  opacity: 0;
}
.header-img-wrap:hover .header-img-icon-active {
  opacity: 1;
}
.header-icon-text {
  margin-left: 10px;
}
.header-icon-list-wrap {
  padding: 8px 12px;
}
.header-icon-list-wrap li {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  line-height: 20px;
  font-size: 14px;
  color: inherit;
  cursor: pointer;
  border-radius: 4px;
}
.header-icon-list-wrap li:hover {
  background-color: #F7F8F9;
}
.header-icon-list-wrap img {
  width: 24px;
  margin-right: 8px;
}
.header-dc-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 20px;
  border-radius: 18px;
  border: 1px solid #eee;
  cursor: pointer;
}
.header-dc-icon img {
  width: 20px;
  background-color: #eee;
}
.header-help-center {
  display: block;
  padding: 4px 7px;
  margin-right: 20px;
  border-radius: 18px;
  border: 1px solid #eee;
  cursor: pointer;
}
.helpcenter-wrap li {
  line-height: 25px;
  width: 100px;
  border-radius: 4px;
  padding: 0 10px;
}
.helpcenter-wrap li:hover {
  background-color: #eee;
}
.recent {
  padding-right: 10px !important;
}
