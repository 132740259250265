/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.item-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  box-sizing: border-box;
  cursor: pointer;
  overflow-y: auto;
}
.item-container .title-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 58px;
}
.item-container .title-container img {
  width: 26px;
}
.item-container .title-container .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 19px;
  width: 1px;
  flex: 1;
}
.item-container .title-container .text-container .name {
  font-size: 14px;
  color: #2F343C;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item-container .title-container .text-container .hint-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item-container .title-container .text-container .hint-container .size {
  font-size: 12px;
  color: #94A0B0;
}
.item-container .title-container .text-container .hint-container .hint {
  font-size: 12px;
  color: #ff3f5a;
}
.item-container .operation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.item-container .operation .hint {
  font-size: 12px;
  color: #94A0B0;
}
.item-container .operation .pause {
  background-size: 20px 20px;
  cursor: pointer;
}
.item-container .operation .continue {
  background-size: 20px 20px;
  cursor: pointer;
}
.item-container .operation .cancel {
  background-size: 20px 20px;
  cursor: pointer;
}
.item-container .operation .retry {
  background-size: 20px 20px;
  cursor: pointer;
}
.item-container .operation .jumpto {
  background-size: 20px 20px;
  cursor: pointer;
}
.item-container .operation .failed {
  background-size: 20px 20px;
  cursor: pointer;
}
.item-container .operation .success {
  background-size: 20px 20px;
  cursor: pointer;
}
.item-container .operation .icon {
  width: 20px;
  height: 20px;
}
.item-container .operation .one {
  margin-right: 8px;
}
.item-container .operation .two {
  margin-right: 20px;
}
.item-container .progress {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
