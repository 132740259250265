/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.file-content-wrap {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-content-wrap:hover {
  background-color: rgba(34, 42, 53, 0.06);
}
.file-content-wrap .file-icon {
  width: 28px;
  height: 28px;
}
.file-content-wrap .file-text-main {
  margin-left: 12px;
  width: calc(100% - 64px);
}
.file-content-wrap .file-text-main .title {
  color: #222A35;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
}
.file-content-wrap .file-text-main .title .title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-content-wrap .file-text-main .title .tag-list {
  margin-right: -12px;
  margin-left: -4px;
}
.file-content-wrap .file-text-main .title .tag-list :global .cooper-tag {
  margin-right: 0 !important;
}
.file-content-wrap .file-text-main .description {
  margin-top: 2px;
  color: rgba(34, 42, 53, 0.7);
  font-size: 12px;
  line-height: 20px;
}
.file-content-wrap .file-text-main .addition {
  margin-top: 2px;
  color: rgba(34, 42, 53, 0.5);
  display: flex;
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
}
.file-content-wrap .file-text-main .addition .metis-name {
  flex: 7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(34, 42, 53, 0.5);
  display: flex;
  align-items: center;
}
.file-content-wrap .file-text-main .addition .metis-name .metis-span {
  border-radius: 4px;
  padding: 0 6px;
  display: inline-block;
  color: rgba(34, 42, 53, 0.5);
}
.file-content-wrap .file-text-main .addition .metis-name .metis-span .dk-book {
  font-size: 12px;
  margin-right: 2px;
  color: rgba(34, 42, 53, 0.5);
}
.file-content-wrap .file-text-main .addition .metis-name .metis-span:hover {
  background: rgba(34, 42, 53, 0.06);
}
.file-content-wrap .file-text-main .addition .owner {
  flex: 5;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-content-wrap .file-text-main .addition .publish {
  flex: 8;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-content-wrap .file-text-main .addition div:first-child {
  margin-left: 0;
}
.file-content-wrap .file-text-main .addition .metis-name {
  margin-left: -6px !important;
}
.file-content-wrap-large .file-text-main {
  width: calc(100% - 80px);
}
.no-padding-left {
  padding-left: 0px;
  padding-right: 0px;
}
.no-padding-left:hover {
  background: none;
}
.icon-star {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  color: #f6c746;
}
