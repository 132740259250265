/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.notification:global(.ant-message-notice) .icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.notification:global(.ant-message-notice) :global .ant-message-notice-content {
  padding: 18px 20px;
  border-radius: 6px;
}
.notification:global(.ant-message-notice) :global .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  align-items: center;
  min-height: 22px;
  font-size: 16px;
  color: #252525;
}
.notification:global(.ant-message-notice) :global .loading-msg img {
  width: 20px;
  margin-right: 6px;
}
.close-icon {
  font-size: 24px;
  line-height: 28px;
}
