/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-list-content {
  margin: 0 auto;
  min-width: 667px;
  margin: -8px auto 0;
}
.cooper-list-content .folder-tree {
  height: 100%;
  width: 100%;
  position: relative;
}
.cooper-list-content .tb-header {
  font-size: 14px;
  font-weight: 500;
  color: #656A72;
  position: sticky;
  top: 36px;
  background-color: #fff;
  padding-left: 29px;
  padding-right: 32px;
  z-index: 4;
}
.cooper-list-content .tb-header .tb-header-div {
  height: 36px !important;
  line-height: 36px !important;
  border-bottom: 1px solid rgba(34, 42, 53, 0.08);
  position: relative;
}
.cooper-list-content .tb-header .tb-header-div .file-name {
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center;
}
.cooper-list-content .tb-header .tb-header-div .file-name .file-resizer {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
}
.cooper-list-content .dc-tb-header {
  padding-left: 21px;
}
.cooper-list-content .tb-header > .tb-header-div,
.cooper-list-content .tb-body > li {
  height: 46px;
  line-height: 46px;
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: rgba(34, 42, 53, 0.7);
}
.cooper-list-content .tb-header > .tb-header-div > span,
.cooper-list-content .tb-body > li > span {
  display: inline-block;
}
.cooper-list-content .tb-header > .tb-header-div > .file-name,
.cooper-list-content .tb-body > li > .file-name {
  max-width: initial;
  min-width: 250px;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 3;
  cursor: pointer;
  padding-right: 12px;
}
.cooper-list-content .tb-header > .tb-header-div .file-resizer,
.cooper-list-content .tb-body > li .file-resizer {
  width: 20px;
  margin-right: 8px;
  margin-left: 52px;
  position: relative;
  text-align: center;
}
.cooper-list-content .tb-header > .tb-header-div .file-resizer .resizer-line,
.cooper-list-content .tb-body > li .file-resizer .resizer-line {
  width: 1px;
  height: 50px;
  background: #076BFF;
  position: absolute;
  top: 0;
  left: 50%;
}
.cooper-list-content .tb-header > .tb-header-div .file-content-box,
.cooper-list-content .tb-body > li .file-content-box {
  margin-right: 12px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cooper-list-content .tb-header > .tb-header-div .file-owner-content-box,
.cooper-list-content .tb-body > li .file-owner-content-box {
  padding-left: 4px;
}
.cooper-list-content .tb-header > .tb-header-div > .file-address,
.cooper-list-content .tb-body > li > .file-address {
  height: 100%;
  width: 300px;
  min-width: 200px;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cooper-list-content .tb-header > .tb-header-div > .file-address .file-content-box,
.cooper-list-content .tb-body > li > .file-address .file-content-box {
  padding: 0 2px;
  height: 22px;
  border-radius: 4px;
  line-height: 22px;
  cursor: pointer;
  width: auto;
}
.cooper-list-content .tb-header > .tb-header-div > .file-address .file-content-box:hover,
.cooper-list-content .tb-body > li > .file-address .file-content-box:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.cooper-list-content .tb-header > .tb-header-div > .file-owner,
.cooper-list-content .tb-body > li > .file-owner,
.cooper-list-content .tb-header > .tb-header-div > .file-time,
.cooper-list-content .tb-body > li > .file-time {
  height: 100%;
  width: 130px;
  min-width: 130px;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 12px;
}
.cooper-list-content .tb-header > .tb-header-div > .file-owner .file-content-box,
.cooper-list-content .tb-body > li > .file-owner .file-content-box,
.cooper-list-content .tb-header > .tb-header-div > .file-time .file-content-box,
.cooper-list-content .tb-body > li > .file-time .file-content-box {
  cursor: default;
}
.cooper-list-content .tb-header > .tb-header-div .file-owner,
.cooper-list-content .tb-body > li .file-owner {
  width: 150px;
  min-width: 150px;
}
.cooper-list-content .tb-header > .tb-header-div > .file-operate,
.cooper-list-content .tb-body > li > .file-operate {
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}
@media screen and (max-width: 1050px) {
  .cooper-list-content .tb-header > .tb-header-div > .file-name,
  .cooper-list-content .tb-body > li > .file-name {
    min-width: 230px;
  }
  .cooper-list-content .tb-header > .tb-header-div > .file-owner,
  .cooper-list-content .tb-body > li > .file-owner {
    min-width: 150px;
    overflow: hidden;
  }
  .cooper-list-content .tb-header > .tb-header-div > .file-address,
  .cooper-list-content .tb-body > li > .file-address {
    min-width: 130px;
  }
}
.cooper-list-content .tb-body {
  padding-left: 27px;
  padding-right: 28px;
  padding-top: 8px;
}
.cooper-list-content .tb-body > li {
  padding-left: 4px;
  padding-right: 4px;
}
.cooper-list-content .tb-body > li .file-name {
  color: #222A35;
}
.cooper-list-content .dc-tb-body {
  padding-left: 19px;
}
.cooper-list-content .tb-body-row:hover {
  border-radius: 4px;
  background: rgba(34, 42, 53, 0.06);
}
.file-time-sort {
  cursor: pointer;
}
.cooper-recent-table-loading {
  position: relative;
  margin-top: 16px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: rgba(34, 42, 53, 0.7);
  height: 100%;
}
.loading-tip {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(34, 42, 53, 0.7);
}
.loading-tip .text {
  margin-left: 4px;
}
