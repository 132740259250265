/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.help-network :global .ant-modal-close .ant-modal-close-x {
  width: 24px;
  height: 24px;
  margin-top: 24px;
  margin-right: 24px;
}
.help-network :global .ant-modal-close .ant-modal-close-x .ant-modal-close-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.help-network :global .ant-modal-close .ant-modal-close-x .ant-modal-close-icon:hover {
  background-color: #F2F3F3;
  border-radius: 4px;
}
.help-network :global .ant-modal-content {
  border-radius: 8px;
}
.help-network .help-net-title {
  width: 392px;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #222A35;
  margin-bottom: 16px;
}
.help-network .lite-notice-title-content-text {
  color: #4E555D;
  font-weight: 400;
}
.help-network .lite-notice-title-content-text .fond-bond {
  color: #222A35;
  font-weight: 500;
}
.help-network .lite-notice-title-content-text .green-light {
  color: #2ECDA4;
  font-weight: 500;
}
.help-network .lite-notice-title-content-text .red-light {
  color: #FF563B;
  font-weight: 500;
}
.help-network .lite-notice-title-content-text a {
  color: #047FFE;
  font-weight: 500;
}
.help-network .lite-notice-footer {
  color: #222A35;
  margin-top: 16px;
  text-align: right;
}
.help-network .lite-notice-footer .lite-notice-btn {
  margin-left: 8px;
  padding: 0 16px;
  font-size: 14px;
}
.help-network .lite-notice-footer .lite-notice-btn.ant-btn-primary {
  background-color: #047FFE;
}
.help-network .lite-notice-footer .lite-notice-btn-gray:hover {
  color: #222A35;
  background-color: #F2F3F3;
  border: 1px solid #E8E9EA;
}
