/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.my-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.66667;
  list-style: none;
  font-feature-settings: tnum;
  position: absolute;
  display: none;
  color: #047FFE;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  position: static;
  display: inline-block;
  opacity: 1;
}
.my-spin .my-spin-dot-span {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
  transform: rotate(0deg);
  animation: antRotate 1.2s infinite linear;
}
.my-spin .my-spin-dot-span .my-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #047FFE;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.my-spin .my-text-container {
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
  color: #222A35;
  font-variant: tabular-nums;
  font-feature-settings: 'tnum';
  font-size: 14px;
}
.my-spin .my-spin-dot-span:nth-child(1) {
  top: 0;
  left: 0;
}
.my-spin .my-spin-dot-span :nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.my-spin .my-spin-dot-span :nth-child(3) {
  bottom: 0;
  right: 0;
  animation-delay: 0.8s;
}
.my-spin .my-spin-dot-span :nth-child(4) {
  left: 0;
  bottom: 0;
  animation-delay: 1.2s;
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}
