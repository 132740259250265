/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.feedback-with-popup {
  margin-left: 10px;
}
.feedback-with-popup .popup-main {
  font-size: 14px;
  color: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  background-color: #385782;
  background-image: url(./popup-background.png);
  background-repeat: no-repeat;
  background-size: 50%;
  border-radius: 8px;
  padding: 12px 16px;
}
.feedback-with-popup .popup-main .popup-main-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 22px;
  max-height: 44px;
  font-size: 14px;
  font-weight: 500;
}
.feedback-with-popup .popup-main .popup-main-iknow {
  border: none;
  width: 72px;
  height: 28px;
  padding: 4px 10px;
  font-size: 13px;
}
