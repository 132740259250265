/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.text-only-modal :global .ant-modal-content {
  padding: 32px 24px 24px 28px;
}
.text-only-modal :global .ant-modal-content .ant-modal-footer {
  padding-top: 30px !important;
}
.text-only-modal :global .ant-modal-content .ant-modal-footer .ant-btn:last-child {
  background: #047FFE;
  color: #FFFFFF;
  font-weight: 500;
}
.text-only-modal .title {
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 500;
  color: #222A35;
  line-height: 33px;
}
.text-only-modal .tip {
  font-size: 14px;
}
.custom-modal-confirm {
  padding-bottom: 0;
}
.custom-modal-confirm :global .ant-modal-content {
  border-radius: 6px;
}
.custom-modal-confirm :global .ant-modal-content .ant-modal-body {
  padding: 32px 28px 24px;
}
.custom-modal-confirm :global .ant-modal-content .ant-modal-body .ant-modal-confirm-title {
  font-size: 18px;
}
.custom-modal-confirm :global .ant-modal-content .ant-modal-body .ant-modal-confirm-content {
  margin-top: 10px;
  font-size: 16px;
  color: #444B4F;
  line-height: 24px;
  margin-bottom: 40px;
  word-break: break-all;
}
.custom-modal-confirm :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns {
  margin-top: 12px;
  margin-right: -4px;
}
.custom-modal-confirm :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  text-align: center;
  background: #F6F6F6;
  border-radius: 4px;
  border: none;
  color: #444B4F;
  margin-left: 16px;
}
.custom-modal-confirm :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn::after {
  display: none;
}
.custom-modal-confirm :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  color: #FFFFFF;
  background: #047FFE;
  font-weight: 500;
}
