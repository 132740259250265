/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.team-dk {
  cursor: pointer;
  transform: translateY(0);
  box-shadow: 0px 6px 20px 0px rgba(18, 19, 21, 0.02);
  transition: all 0.2s ease-out;
}
.team-dk:hover {
  transform: translateY(-10px);
  box-shadow: 0px 6px 20px 0px rgba(18, 19, 21, 0.08);
}
.team-dk .team-dk-pic-wrap {
  width: 100%;
  height: 104px;
  border: none;
  position: relative;
}
.team-dk .team-dk-pic-wrap .dk-tags {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 17px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}
.team-dk .team-dk-pic {
  width: 100%;
  height: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center -14px;
}
.team-dk .team-dk-info {
  position: relative;
  height: 139px;
  padding: 21px 16px 16px 16px;
  border: 1px solid #EFF0F2;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.team-dk .team-dk-departmentTag {
  width: 54px;
  height: 34px;
}
.team-dk .department {
  background: #F7DA9C;
  color: #6D4800;
  font-size: 12px;
  border-radius: 2px;
  display: inline-block;
  height: 18px;
  line-height: 18px;
  font-weight: 700;
  padding: 0 4px;
  box-sizing: content-box;
  margin-right: 8px;
  transform: scale(0.916);
  position: absolute;
  top: -20px;
  left: -8px;
}
.team-dk .team-dk-groupTag-wrap {
  height: 24px;
  padding-bottom: 8px;
}
.team-dk .team-dk-groupTag-wrap .team-dk-groupTag {
  border-radius: 2px;
  border: 1px solid rgba(4, 127, 254, 0.3);
  font-size: 11px;
  color: #047FFE;
  line-height: 14px;
  text-align: center;
  padding: 0 5px;
  display: inline-block;
}
.team-dk .team-dk-title {
  font-size: 16px;
  font-weight: 500;
  color: #2F343C;
  line-height: 22px;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.team-dk .team-dk-desc {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.team-dk-dc .team-dk-pic-wrap {
  height: 94px;
}
.team-dk-dc .team-dk-info {
  height: 129px;
  padding-top: 17px;
}
