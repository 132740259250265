/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.m-progress {
  width: 220px;
  box-shadow: 0 2px 8px 0 rgba(47, 52, 60, 0.2);
  padding: 10px 16px;
  border-radius: 4px;
  position: fixed;
  left: calc(50% - 110px);
  top: 13px;
  z-index: 2999;
  line-height: 20px;
  color: #17233e;
  background-color: #fff;
}
.m-progress img {
  width: 20px;
  margin-right: 8px;
  vertical-align: top;
}
.m-progress i {
  display: inline-block;
  margin-right: 8px;
  color: #0066FF;
}
.m-progress i.loading {
  animation: mv-rotate 2s linear infinite;
}
.m-progress a {
  float: right;
  color: #06f;
}
@keyframes mv-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
