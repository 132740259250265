// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.menu-wrap {
  :global {

    .ant-menu-light .ant-menu-item:hover,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-title:hover {
      color: @text-color;
    }

    .ant-menu-vertical {
      border: none !important;
    }

    .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
      color: #999999;
    }

    .ant-menu-vertical .ant-menu-item:not(:last-child),
    .ant-menu-vertical-left .ant-menu-item:not(:last-child),
    .ant-menu-vertical-right .ant-menu-item:not(:last-child),
    .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin: 0;
    }

    .ant-menu-vertical .ant-menu-item,
    .ant-menu-vertical-left .ant-menu-item,
    .ant-menu-vertical-right .ant-menu-item,
    .ant-menu-vertical .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-item {
      margin: 0;
      padding: 0 8px;
    }

    li {
      height: 40px !important;
      line-height: 40px !important;
      padding: 0 8px !important;
      display: block !important;
    }
  }

  .operate-ul {
    border-radius: 6px;
    width: 142px;
    font-size: 14px;
    line-height: 20px;
    color: @text-color;
  }

  .operate-li {
    border-radius: 4px;
    cursor: pointer;
    height: auto;
    line-height: 20px;
    color: @text-color;
    padding: 8px 12px;

    :global(.ant-menu-submenu-arrow) {
      color: #999999;
      right: 0;
    }

    &:hover {
      :global(.ant-menu-submenu-arrow) {
        color: #999999;
      }

      background: #F4F4F4;
      color: @text-color;
    }

  }

  .operate-li-sub {
    padding: 0 8px;

    :global {
      .ant-menu-submenu-title {
        padding-left: 0;
        padding-right: 0;
        line-height: 40px;
        height: auto;

        &:hover {
          color: @text-color;
        }
      }
    }
  }

}

:global {

  .ant-menu-item-disabled.ant-menu-item,
  .ant-menu-submenu-vertical.ant-menu-submenu-disabled {
    cursor: not-allowed !important;
    color: rgba(0, 0, 0, 0.25) !important;

    &:hover,
    .ant-menu-item-icon {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}




.operate-li {

  .operate-icon-pop {
    font-size: 14px;
    color: #494F55;

  }

  .hasStar {
    color: #f6c746;
  }

  .star-btn {
    font-size: 18px;
  }
}


.operate-li-dangerous {
  color: #FE0B19 !important;

  &:hover {
    color: #FE0B19 !important;
  }

  .operate-icon-pop {
    color: #FE0B19;
  }
}

.operate-li-sub-wrap>ul {
  padding: 7px !important;
  border-radius: 6px !important;
}

.operate-ul-add {
  width: 162px !important;
}

.catalog-add-container,
.catalog-operate-container {
  // 在黑色tooltip上
  z-index: 8888 !important;
  border-radius: 6px;
}

.link {
  color: @primary-color;
  cursor: pointer;
}