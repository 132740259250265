/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.dk-people-select-label-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 8px;
}
.dk-people-select-label-item .dk-people-select-label-item-left {
  padding-right: 12px;
}
.dk-people-select-label-item .dk-people-select-label-item-right {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dk-people-select-label-item .dk-people-select-label-item-right .dk-people-select-label-item-right-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dk-people-select-label-item .dk-people-select-deptPath {
  color: #AEAEAE;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 17px;
}
.dk-people-select-label-item .dk-people-select-label-detail {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
  line-height: 20px;
}
