/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.operation-common-icon {
  color: #AEAFB2;
  font-size: 24px;
  line-height: 24px !important;
  border-radius: 2px;
  font-weight: 400;
}
.operation-common-icon:hover {
  background: #DBDBDB;
  color: #505050;
}
.operation-common-icon .icon-left {
  margin-right: 4px;
}
.aside-wrap-common {
  height: 100%;
  position: relative;
  background: #FaFaFa;
}
.aside-container-common {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  text-align: left;
  padding-top: 16px;
  font-size: 14px;
  line-height: 20px;
  overflow-y: hidden;
}
.aside-container-common .aside-menu-li-common {
  list-style: none;
  margin-bottom: 2px;
  border-radius: 3px;
  margin-right: 8px;
  margin-left: 8px;
}
.aside-container-common .aside-menu-li-common .aside-li-item {
  display: flex;
  align-items: center;
  padding: 8px;
  color: #222A35;
  border-radius: 4px;
  justify-content: center;
}
.aside-container-common .aside-menu-li-common .aside-li-item:hover {
  background: rgba(34, 42, 53, 0.06);
}
.aside-container-common .aside-menu-li-common .aside-li-itemdiv {
  display: flex;
  align-items: center;
}
.aside-container-common .aside-menu-li-common .aside-li-item .menu-root-icon {
  margin-right: 8px;
  margin-left: 2px;
  font-size: 15px;
  color: #656A72;
}
.aside-container-common .aside-menu-li-common .aside-li-item .aside-li-text {
  flex: 1;
}
.aside-container-common .aside-menu-li-common .aside-li-item-active {
  font-weight: 700;
  background-color: rgba(4, 127, 254, 0.1);
}
.aside-container-common .aside-menu-li-common .aside-li-item-active .menu-root-icon {
  color: #047FFE !important;
}
.aside-container-common .aside-menu-li-common .aside-li-item-active:hover {
  background-color: rgba(4, 127, 254, 0.1);
}
.aside-container-common .aside-menu-li-common .aside-li-item-active .aside-li-text {
  color: #047FFE;
}
.aside-container-common .aside-menu-li-common-out .aside-li-item {
  padding: 10px 8px;
}
.catalog-up-icon {
  font-size: 14px;
  color: rgba(34, 42, 53, 0.06);
  margin-left: -15px;
}
.is-tree-hidden {
  display: none;
}
.catalog-page-wrap-virtual .aside-catalog-loading {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 270px;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.catalog-page-wrap-virtual :global .ant-tree {
  font-size: 14px;
  line-height: 20px;
  color: #222A35;
  height: 100%;
  background: transparent;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-list {
  padding: 0 8px;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode {
  border-radius: 4px;
  padding: 0px 8px 0px 9px;
  color: #222A35;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode:hover {
  background-color: rgba(34, 42, 53, 0.06);
  border-bottom-color: #f7f9fa;
  border-top-color: #f7f9fa;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode .ant-tree-indent .ant-tree-indent-unit {
  width: 20px;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode .ant-tree-switcher {
  align-self: center;
  font-size: 10px;
  color: rgba(34, 42, 53, 0.8);
  margin-right: 0;
  display: inline-block;
  line-height: 14px;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode .ant-tree-node-content-wrapper {
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: none;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode .dk-icon-dian {
  vertical-align: middle;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode .ant-tree-switcher-icon {
  vertical-align: text-bottom;
}
.catalog-page-wrap-virtual :global .ant-tree .node-dragging {
  opacity: 0.4;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-list-holder-inner:first-child {
  padding-top: 5px;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-list-holder-inner:last-child {
  padding-bottom: 5px;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode-selected {
  background: rgba(4, 127, 254, 0.1);
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode-selected:hover {
  background: rgba(4, 127, 254, 0.1);
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode-selected .icon-no-children {
  color: #047FFE;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-treenode-selected .ant-tree-switcher {
  color: #047FFE;
}
.catalog-page-wrap-virtual :global .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: none;
}
.catalog-page-wrap-virtual :global .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
  border: none;
}
.catalog-page-wrap-virtual :global .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging:hover {
  background: none;
}
.catalog-page-wrap-virtual .empty-tree {
  margin-top: 60px;
  color: #666666;
  text-align: center;
}
.catalog-page-wrap {
  height: calc(100% - 40px);
  position: relative;
  overflow-y: auto;
}
.catalog-page-wrap .aside-catalog-loading {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 270px;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.catalog-page-wrap :global .ant-tree {
  font-size: 14px;
  line-height: 20px;
  color: #222A35;
  height: 100%;
  background: transparent;
}
.catalog-page-wrap :global .ant-tree .ant-tree-list {
  padding: 0 8px;
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode {
  border-radius: 4px;
  padding: 0 8px 0 9px;
  color: #222A35;
  margin-bottom: 2px;
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode:hover {
  background-color: rgba(34, 42, 53, 0.06);
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode .ant-tree-indent .ant-tree-indent-unit {
  width: 20px;
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode .ant-tree-switcher {
  align-self: center;
  font-size: 10px;
  color: rgba(34, 42, 53, 0.8);
  margin-right: 0;
  display: inline-block;
  line-height: 14px;
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode .ant-tree-node-content-wrapper {
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: none;
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode .dk-icon-dian {
  vertical-align: middle;
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode .ant-tree-switcher-icon {
  vertical-align: text-bottom;
}
.catalog-page-wrap :global .ant-tree .node-dragging {
  opacity: 0.4;
}
.catalog-page-wrap :global .ant-tree .ant-tree-list-holder-inner:first-child {
  padding-top: 5px;
}
.catalog-page-wrap :global .ant-tree .ant-tree-list-holder-inner:last-child {
  padding-bottom: 5px;
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode-selected {
  background: rgba(4, 127, 254, 0.1);
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode-selected:hover {
  background: rgba(4, 127, 254, 0.1);
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode-selected .icon-no-children {
  color: #047FFE;
}
.catalog-page-wrap :global .ant-tree .ant-tree-treenode-selected .ant-tree-switcher {
  color: #047FFE;
}
.catalog-page-wrap :global .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: none;
}
.catalog-page-wrap :global .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
  border: none;
}
.catalog-page-wrap :global .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging:hover {
  background: none;
}
.catalog-page-wrap .empty-tree {
  margin-top: 60px;
  color: #666666;
  text-align: center;
}
