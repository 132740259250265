/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.aside-container .aside-li-fixed {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.aside-container .data-board {
  display: flex;
  padding: 2px 0;
  border-top: 1px solid #EEEEEF;
  background-color: #f7f9fa;
}
.aside-container .data-board__btn {
  flex: 1;
}
.aside-container .data-board__btn .aside-menu-li-common-reset {
  padding: 0;
  margin: 0 4px;
}
.aside-container .data-board__btn .aside-menu-li-common-reset .aside-li-item-reset {
  padding: 8px 8px;
}
.aside-container .data-board__btn .aside-menu-li-common-reset .aside-li-item-reset .menu-root-icon-reset {
  font-size: 16px;
  margin: 0 6px 0 0;
  line-height: 17px;
}
.aside-container .data-board__btn .aside-menu-li-common-reset .aside-li-item-reset .aside-li-text-reset {
  word-break: keep-all;
  font-size: 12px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: unset;
}
.aside-container .data-board__btn:not(:first-child)::after {
  content: ' ';
  width: 1px;
  height: 16px;
  position: absolute;
  top: 12px;
  background-color: #EDF0F3;
}
.aside-container .data-board__btn-hidden {
  overflow: hidden;
  flex: 1.1;
}
.aside-container .view-wrap {
  height: calc(100% - 80px);
}
.aside-title {
  margin: 6px 8px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #222A35;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
