/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.import-li {
  height: auto !important;
  padding: 0 !important;
  border-radius: 8px;
  cursor: pointer;
}
.import-li .import-li-content {
  display: flex;
  padding: 14px !important;
  height: auto;
}
.import-li .import-li-content:hover {
  background: rgba(34, 42, 53, 0.06);
}
.import-li .import-li-content .li-image {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border: 0.5px solid #EFF0F2;
  border-radius: 10px;
}
.import-li .import-li-content .li-text .li-title {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  margin-bottom: 4px;
}
.import-li .import-li-content .li-text .import-help {
  font-size: 14px;
  margin-left: 12px;
  color: #999999;
  padding-top: 2px;
}
.import-li .import-li-content .li-text .li-desc {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}
:global .ant-menu-item-group-title {
  display: none;
}
