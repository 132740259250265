.operate-icon-active() {
  background: #DBDBDB;
  color: #505050;
}

.operation-common-icon {
  color: #AEAFB2;
  font-size: 24px;
  line-height: 24px !important;
  border-radius: 2px;
  font-weight: 400;

  &:hover {
    .operate-icon-active();
  }

  .icon-left {
    margin-right: 4px;
  }
}

.aside-wrap-common {
  height: 100%;
  position: relative;
  background: #FaFaFa;
}


.aside-container-common {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  text-align: left;
  padding-top: 16px;
  font-size: 14px;
  line-height: 20px;
  overflow-y: hidden;

  .aside-menu-li-common {
    list-style: none;
    margin-bottom: 2px;
    border-radius: 3px;
    // font-weight: bold;
    margin-right: 8px;
    margin-left: 8px;

    .aside-li-item {
      display: flex;
      align-items: center;
      padding: 8px;
      color: @text-color;
      border-radius: 4px;
      justify-content: center;

      &:hover {
        background: @blueGray-12;
      }

      &div {
        display: flex;
        align-items: center;
      }

      .menu-root-icon {
        margin-right: 8px;
        margin-left:2px;
        font-size:15px;
        color:#656A72;
      }

      .aside-li-text {
        flex: 1;
        // font-weight: 400;
      }
    }

    .aside-li-item-active {
      font-weight: 700;
      // background: @blueGray-11;
      background-color: rgba(4, 127, 254, .1);

      .menu-root-icon {
        color: @primary-color !important;
      }

      &:hover {
        // background: @blueGray-11;
        background-color: rgba(4, 127, 254, .1);
      }

      .aside-li-text {
        color: @primary-color;
      }
    }

  }

  .aside-menu-li-common-out {
    .aside-li-item {
      padding: 10px 8px;
    }
  }
}

.catalog-up-icon {
  font-size: 14px;
  color: @blueGray-12;
  margin-left: -15px;
}

.is-tree-hidden {
  display: none;
}


.catalog-page-wrap-virtual {

  .aside-catalog-loading {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 270px;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global {
    .ant-tree {
      font-size: 14px;
      line-height: 20px;
      color: @text-color;
      height: 100%;
      background: transparent;

      .ant-tree-list {
        padding: 0 8px;

      }

      .ant-tree-treenode {
        border-radius: 4px;
        padding: 0px 8px 0px 9px;
        color: @text-color;
        // margin-bottom: 2px;
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;

        &:hover {
          background-color: rgba(34, 42, 53, 0.06);
          border-bottom-color: #f7f9fa;
          border-top-color: #f7f9fa;
        }

        .ant-tree-indent {
          .ant-tree-indent-unit {
            width: 20px;
          }
        }

        .ant-tree-switcher {
          align-self: center;
          font-size: 10px;
          color: @blueGray-3;
          margin-right: 0;
          display: inline-block;
          line-height: 14px;
        }

        .ant-tree-node-content-wrapper {
          min-width: 0;
          padding-left: 0;
          padding-right: 0;

          &:hover {
            background: none;
          }
        }

        .dk-icon-dian {
          vertical-align: middle;
        }

        .ant-tree-switcher-icon {
          vertical-align: text-bottom;
        }

      }

      .node-dragging {
        opacity: 0.4;
      }

      .ant-tree-list-holder-inner {
        &:first-child {
          padding-top: 5px;
        }

        &:last-child {
          padding-bottom: 5px;
        }
      }

      .ant-tree-treenode-selected {
        background: rgba(4, 127, 254, .1);
        // border-bottom: none;
        // border-top: none;

        &:hover {
          background: rgba(4, 127, 254, .1);
        }

        .icon-no-children {
          color: @primary-color;
        }

        .ant-tree-switcher {
          color: @primary-color;
        }
      }
    }

    // 拖拽后选中的
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background: none;
    }

    .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
      &::after {
        border: none;
      }

      &:hover {
        background: none;
      }
    }

  }

  .empty-tree {
    margin-top: 60px;
    color: #666666;
    text-align: center;
  }
}

.catalog-page-wrap {
  height: calc(100% - 40px);
  position: relative;
  overflow-y: auto;

  .aside-catalog-loading {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 270px;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global {
    .ant-tree {
      font-size: 14px;
      line-height: 20px;
      color: @text-color;
      height: 100%;
      background: transparent;

      .ant-tree-list {
        padding: 0 8px;

      }

      .ant-tree-treenode {
        border-radius: 4px;
        padding: 0 8px 0 9px;
        color: @text-color;
        margin-bottom: 2px;

        &:hover {
          background-color: rgba(34, 42, 53, 0.06);
        }

        .ant-tree-indent {
          .ant-tree-indent-unit {
            width: 20px;
          }
        }

        .ant-tree-switcher {
          align-self: center;
          font-size: 10px;
          color: @blueGray-3;
          margin-right: 0;
          display: inline-block;
          // margin-left: 4px;
          line-height: 14px;
        }

        .ant-tree-node-content-wrapper {
          min-width: 0;
          padding-left: 0;
          padding-right: 0;

          &:hover {
            background: none;
          }
        }

        .dk-icon-dian {
          vertical-align: middle;
        }

        .ant-tree-switcher-icon {
          vertical-align: text-bottom;
        }

      }

      .node-dragging {
        opacity: 0.4;
      }

      .ant-tree-list-holder-inner {
        &:first-child {
          padding-top: 5px;
        }

        &:last-child {
          padding-bottom: 5px;
        }
      }

      .ant-tree-treenode-selected {
        // background: @blueGray-11;
        background: rgba(4, 127, 254, .1);

        &:hover {
          // background: @blueGray-11;
          background: rgba(4, 127, 254, .1);
        }

        .icon-no-children {
          color: @primary-color;
        }

        .ant-tree-switcher {
          color: @primary-color;
        }
      }
    }

    // 拖拽后选中的
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background: none;
    }

    .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
      &::after {
        border: none;
      }

      &:hover {
        background: none;
      }
    }


  }

  .empty-tree {
    margin-top: 60px;
    color: #666666;
    text-align: center;
  }

}