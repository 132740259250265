/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.dynamic-popover :global .ant-popover-inner {
  border: none !important;
  border-radius: 8px!important;
  background: #385782 !important;
}
.dynamic-popover :global .ant-popover-inner .ant-popover-inner-content {
  padding: 0 !important;
}
.dynamic-popover :global .ant-popover-arrow {
  display: inherit !important;
}
.dynamic-popover :global .ant-popover-arrow .ant-popover-arrow-content::before {
  background: #385782 !important;
}
