/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.name-input-content {
  padding: 4px 8px;
  width: 294px;
}
.name-input-content .name-input {
  background: rgba(34, 42, 53, 0.06);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #222A35;
  padding-right: 20px;
  caret-color: #047FFE;
}
.name-input-content .name-input:hover {
  background: rgba(34, 42, 53, 0.06);
}
