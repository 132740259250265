/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.aside-share-wrap {
  background-size: 280px, 160px !important;
  padding: 0;
}
.aside-share-wrap .dk-overview {
  display: flex;
  align-items: center;
  padding: 20px 16px;
  cursor: initial;
}
.aside-share-wrap .dk-overview .dk-picture {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 4px;
}
.aside-share-wrap .dk-overview .dk-name {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  color: #222A35;
  height: 22px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.aside-share-wrap .dk-overview .dk-name .hidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.catalog-title-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.catalog-title-li .catalog-li-item {
  padding-left: 0;
  padding-right: 4px;
  flex: 1;
}
.catalog-title-li .catalog-li-item .catalog-up-icon {
  margin-right: 8px;
}
.catalog-title-li :global(.dk-icon-tianjia1) {
  margin-right: 6px;
}
