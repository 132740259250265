/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.v3-quick-access {
  margin: 0 auto;
  min-width: 667px;
  margin-bottom: 8px;
  padding: 0 32px;
}
.v3-quick-access-title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  color: #222A35;
  top: 0px;
  position: sticky;
  z-index: 1;
  height: 24px;
  background-color: #fff;
  display: block;
  margin-bottom: 0;
  padding: 0 0 12px 0;
  box-sizing: content-box;
}
.is-dc {
  padding: 0 24px;
}
.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
