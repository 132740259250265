/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
:global .image-with-loader {
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
}
:global .image-with-loader .image-with-loader__spinner {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
:global .image-with-loader .image-with-loader__image {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin-top: 0;
  cursor: pointer;
  border: none;
  -o-object-fit: cover;
  object-fit: cover;
}
