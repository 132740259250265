/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-announce {
  position: relative;
  line-height: 30px;
  z-index: 999;
  background: #FFFBE6;
  border-radius: 4px;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 900px;
}
.cooper-announce + .v3-cooper-aside {
  top: 30px;
}
.cooper-announce .warnIcon {
  margin-right: 6px;
  width: 14px;
  height: 14px;
}
.cooper-announce > button {
  position: absolute;
  right: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.cooper-announce > button .closeIcon {
  width: 24px;
  height: 24px;
}
.cooper-announce-dc {
  width: auto !important;
  min-width: auto !important;
  padding: 5px 10px;
}
.cooper-announce-dc > button {
  margin-right: 6px;
}
