/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.switch-view-icon {
  font-size: 24px;
  color: #AEAFB2;
  border-radius: 2px;
  line-height: 24px !important;
  cursor: pointer;
}
.switch-view-icon:hover {
  background: #DBDBDB;
  color: #505050;
}
