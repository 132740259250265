/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.search-page-tab {
  padding: 0 11px;
  font-weight: 500;
  position: relative;
  z-index: 2;
  margin-bottom: -10px;
}
.item-wrap p:last-child span:nth-child(2) {
  min-width: 96px;
  white-space: nowrap;
}
.item-wrap p:last-child span:first-child {
  max-width: 80%;
}
.search-page-total {
  padding: 9px 11px 6px 11px;
  font-size: 13px;
  color: rgba(34, 42, 53, 0.5);
  position: relative;
  z-index: 2;
}
.search-page-result {
  width: 100%;
  padding: 2px 15px 0 15px;
  margin: 0 0 24px 0;
  position: relative;
}
.search-item-wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 11px 0 11px;
  border-radius: 8px;
  cursor: pointer;
}
.search-item-wrap:hover:not(:first-child) {
  background: #F7F8F9;
}
.search-item-wrap:last-child .search-item {
  border-bottom: 1px solid transparent;
}
.search-page-empty {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 14px;
  color: #505050;
  height: calc(100% - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.search-page-empty img {
  width: 240px;
  display: block;
  margin-bottom: 12px;
}
.search-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(34, 42, 53, 0.5);
  font-size: 14px;
  padding-bottom: 12px;
}
.search-item.search-item-title {
  border-bottom: 1px solid #EBECF0;
  cursor: auto;
}
.search-item > *:first-child {
  flex: 1;
  min-width: 200px;
}
.search-item > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  flex-shrink: 0;
  padding-right: 20px;
}
.search-item > :nth-child(2) {
  width: 160px;
}
.search-item .search-left > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-item .search-left p:first-child {
  font-weight: 500;
  color: #222A35;
}
.search-metis {
  margin-bottom: 12px;
}
.search-metis-wrap {
  width: 100%;
  padding: 0 12px 0 11px;
  display: grid;
  grid-gap: 26px;
  grid-template-columns: repeat(auto-fill, 179px);
}
.search-metis-title {
  margin: 10px 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: rgba(34, 42, 53, 0.5);
}
.search-metis-title span:first-child {
  font-size: 16px;
  font-weight: 500;
  color: #222A35;
}
.search-empty {
  font-size: 13px;
  color: #aaa;
  text-align: center;
  margin-top: 12px;
}
@media (max-width: 1016px) {
  .search-item > *:nth-child(2) {
    display: none;
  }
}
@media (max-width: 840px) {
  .search-item > *:last-child {
    display: none;
  }
}
@media (max-width: 680px) {
  .search-item > *:nth-child(3) {
    display: none;
  }
}
