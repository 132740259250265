/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.delete-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-modal-content .delete-confirm-icon {
  width: 132px;
  height: 132px;
  margin-right: 24px;
  margin-left: 8px;
}
.delete-modal-content .delete-text {
  flex: 1;
  position: relative;
  top: 8px;
}
.delete-modal-content .delete-text .text-h1 {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #222A35;
  display: flex;
  white-space: nowrap;
}
.delete-modal-content .delete-text .text-h1 .page-name {
  max-width: 212px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.delete-modal-content .delete-text .text-p {
  font-size: 16px;
  line-height: 24px;
  color: #444B4F;
}
.delete-confirm-container :global .ant-modal-content .ant-modal-footer {
  margin-top: 34px;
}
.delete-confirm-container :global .ant-modal-footer .ant-btn:last-child {
  background: #F5411F;
  color: #FFFFFF;
  font-weight: 500;
}
