/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.v3-card {
  flex: 1;
  height: 46px;
  border-radius: 4px;
  margin: 0 12px 10px 0;
  border: 1px solid #F2F3F3;
  background-color: #FFFFFF;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  width: calc((100% - 36px) / 4);
  min-width: calc((100% - 36px) / 4);
  max-width: calc((100% - 36px) / 4);
}
.v3-card:nth-child(4n) {
  margin-right: 0;
}
.v3-card .v3-card-img {
  width: 24px !important;
  height: 24px !important;
  overflow: hidden;
  display: inline-block;
  margin: 0 8px 0 4px;
}
.v3-card .v3-card-img svg {
  max-width: 100%;
  max-height: 100%;
}
.v3-card > img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin: 0 8px 0 4px;
}
.v3-card .v3-card-text {
  display: inline-block;
  vertical-align: middle;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #222A35;
}
.v3-card .disable {
  color: #909499;
}
.v3-card .team-tag {
  height: 16px;
  text-align: center;
  background: linear-gradient(121deg, rgba(250, 219, 155, 0.8) 0%, rgba(244, 205, 121, 0.8) 100%), #FFFFFF;
  border: 0.45px solid rgba(255, 255, 255, 0.3);
  color: #6D4800;
  transform: scale(0.9);
  font-size: 12px;
  box-sizing: content-box;
  line-height: 16px;
  margin-right: 2px;
}
.v3-card .deleted-tag {
  width: auto;
  text-overflow: clip;
  font-size: 12px;
  text-align: center;
  color: #909499;
  padding: 1px 3.6px;
  line-height: 15px;
  border-radius: 1.2px;
  border: 0.9px solid #E8E9EA;
  transform: scale(0.9);
}
.v3-card .user-name {
  display: inline-block;
  width: 48px;
  text-align: right;
  font-size: 12px;
  color: #909499;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v3-card .operate-tag {
  width: 18px;
  text-align: center;
  display: none;
}
.v3-card::before {
  display: none;
  font-family: "dk-iconfont" !important;
  content: '\e6af';
  color: #999;
  position: absolute;
  left: 3px;
  top: center;
  font-size: 12px;
}
.v3-card:hover {
  border: 1px solid #047FFE;
}
.v3-card:hover .operate-tag {
  display: inline-block;
}
.v3-card:hover .user-name {
  display: none;
}
.v3-card:hover::before {
  display: block;
}
