/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.ns-modal {
  text-align: center;
}
.ns-modal .ant-modal-content {
  border-radius: 8px;
}
.ns-modal p {
  font-size: 14px;
  line-height: 20px;
}
.ns-modal .icon-close {
  position: absolute;
  top: 27px;
  right: 27px;
}
.ns-modal .btn-link {
  display: block;
  margin: 8px auto 0;
  border: none;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  color: #06f;
  cursor: pointer;
}
.ns-modal img {
  height: 113px;
  margin-top: -4px;
}
.ns-modal .title {
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  color: #2f343c;
  margin: 12px 0 8px;
  text-align: center;
}
.ns-modal .ant-btn {
  margin: 32px auto 8px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  background-color: #06f;
  border-color: #06f;
}
.ns-modal .foot-tip {
  margin: 0 auto 8px !important;
  text-align: center !important;
  color: #828695;
  font-size: 12px;
}
.ns-modal .foot-tip > a {
  color: #06f;
}
.ns-modal .step-1 {
  margin-top: 52px;
}
.ns-modal .step-1 p {
  width: 285px;
  margin: 4px auto;
  text-align: left;
  color: #828695;
}
.ns-modal .step-1.is-en p {
  width: 410px;
}
.ns-modal .step-1 .title {
  color: #2f343c;
  text-align: center;
  margin-bottom: 16px;
}
.ns-modal .step-1 .icon-back {
  display: block;
  height: 18px;
  width: 18px;
  background-image: url(../images/icon_jiantou_zuo.svg);
  background-size: 100% 100%;
  position: absolute;
  top: 24px;
  left: 24px;
  cursor: pointer;
}
.ns-modal .step-1 .ant-btn {
  margin-top: 45px;
}
