/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.switch-search-input {
  background-color: #F4f4f4 !important;
  height: 36px !important;
  line-height: 36px !important;
  border-radius: 4px !important;
  color: #222A35 !important;
  padding-top: 4px !important;
  font-size: 14px !important;
  padding-left: 40px !important;
  border: none !important;
  margin-bottom: 8px !important;
}
.switch-search-input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.departmentTag {
  margin: 0 10px 0 0;
  width: 42px;
  height: 22px;
}
.department {
  background: #F7DA9C;
  color: #6D4800;
  font-size: 12px;
  border-radius: 2px;
  display: inline-block;
  height: 18px;
  line-height: 18px;
  font-weight: 700;
  padding: 0 4px;
  box-sizing: content-box;
  margin-right: 8px;
  transform: scale(0.916);
}
.switch-search-icon {
  position: absolute;
  z-index: 20;
  top: 10px;
  left: 13px;
  color: #bbb;
}
:global .version-search-popover {
  background: chartreuse;
}
:global .version-search-popover .ant-tooltip-inner {
  padding: 0 !important;
}
:global .switch-search-popover {
  background-color: #fff !important;
  width: 300px !important;
  max-width: 300px !important;
}
:global .switch-search-popover .ant-tooltip-inner {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background: #fff;
  padding: 8px;
  color: #222A35;
  font-size: 14px;
  background-color: #fff !important;
}
:global .switch-search-popover .recent-list {
  font-size: 13px;
  color: rgba(34, 42, 53, 0.5);
  padding-bottom: 6px;
  display: block;
}
:global .switch-search-popover .ant-tooltip-arrow {
  display: none;
}
:global .switch-search-popover .search-popover-content {
  background-color: #fff !important;
  position: relative;
}
:global .switch-search-popover .search-popover-content ul {
  margin: 0;
  padding: 0;
  max-height: 500px;
  overflow: auto;
}
:global .switch-search-popover .search-popover-content ul li {
  padding: 8px 12px;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: inherit;
  cursor: pointer;
  border-radius: 4px;
  color: rgba(34, 42, 53);
}
:global .switch-search-popover .search-popover-content ul li > span {
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:global .switch-search-popover .search-popover-content ul li.active {
  background-color: rgba(11, 131, 255, 0.08);
  color: #0B83FF;
}
:global .switch-search-popover .search-popover-content ul li:hover {
  background: rgba(34, 42, 53, 0.06);
}
