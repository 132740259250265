/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.position-content :global .ant-tabs-nav {
  padding-left: 12px;
  margin-bottom: 10px;
}
.position-content .options-list-wrap {
  height: 270px;
  position: relative;
  overflow-y: auto;
}
.position-content .options-list-wrap .scope-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
}
.position-content .options-list-wrap .scope-item .check-icon {
  font-size: 16px;
  margin-right: 4px;
  color: #047FFE;
  margin-left: 4px;
}
.position-content .noMore {
  margin-top: 0px !important;
}
.active {
  background-color: rgba(11, 131, 255, 0.08);
  color: #0b83ff;
}
