/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.ant-select-dropdown-menu-item {
  font-size: 14px;
  color: #17233e;
}
.cooper-share-modal {
  z-index: 2001 !important;
}
.cooper-share-modal .hidden {
  display: none;
}
.cooper-share-modal .batch-add-title {
  line-height: 28px;
  display: flex;
  align-items: center;
}
.cooper-share-modal .batch-add-title .back {
  cursor: pointer;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  background: url('@/assets/icon/createKnowledgeBack.png') no-repeat;
  background-size: contain;
}
.cooper-share-modal .ant-modal-title > div {
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cooper-share-modal .ant-modal-content {
  min-height: 364px;
  border-radius: 12px;
  background-color: #fefefe;
  box-shadow: 0 2px 8px 0 rgba(23, 35, 62, 0.2);
}
.cooper-share-modal .ant-modal-close {
  top: 6px;
  right: 8px;
}
.cooper-share-modal .ant-modal-close-x {
  font-size: 18px;
  font-weight: 100;
  color: #bec5d2;
}
.cooper-share-modal .ant-modal-close-x:hover {
  color: #0066FF;
}
.cooper-share-modal .ant-modal-header {
  border-bottom: 0;
  padding: 32px 32px 16px;
  border-radius: 8px;
}
.cooper-share-modal .ant-modal-header .ant-modal-title {
  font-size: 20px;
  color: #17233e;
}
.cooper-share-modal .ant-modal-header .ant-modal-title span {
  margin-right: 12px;
}
.cooper-share-modal .ant-modal-body {
  padding: 0;
}
.cooper-share-modal .ant-modal-body .category {
  padding: 1px 32px 8px;
}
.cooper-share-modal .ant-modal-body .category a {
  font-size: 15px;
  color: #999999;
  position: relative;
}
.cooper-share-modal .ant-modal-body .category a:first-child {
  margin-right: 40px;
}
.cooper-share-modal .ant-modal-body .category a.active {
  color: #333333;
}
.cooper-share-modal .ant-modal-body .category a.active::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 24px;
  height: 2px;
  background: #0066FF;
  left: 17px;
  bottom: -5px;
}
.cooper-share-modal .ant-modal-body .direct-share {
  padding: 15px 0 32px 0;
}
.cooper-share-modal .ant-modal-body .addMember-tabs .ant-tabs-nav-wrap {
  padding: 0 32px;
}
.cooper-share-modal.show-batch-add .ant-modal-body .direct-share {
  padding: 0;
}
.cooper-share-modal .ant-radio-wrapper,
.cooper-share-modal .ant-checkbox-wrapper {
  position: relative;
  margin-right: 8px;
}
.cooper-share-modal .ant-radio-inner {
  border-color: #bec5d2;
}
.cooper-share-modal .ant-select-selection-selected-value {
  font-size: 14px;
  color: #17233e;
}
.cooper-share-modal .ant-select-selection {
  margin-bottom: 0;
  border-color: #e4e9f3;
}
.cooper-share-modal .ant-checkbox-inner {
  border-radius: 12px;
  border-color: #bdc5d2 !important;
}
.cooper-share-modal .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0066FF !important;
  border-color: #0066FF !important;
}
.cooper-share-modal .ant-checkbox-checked .ant-checkbox-inner::after {
  left: 2px;
  top: 2px;
  border: 0;
  height: 10px;
  width: 10px;
  background-image: url(./icon/icon_gouxuan.svg);
  background-size: 10px 10px;
  transform: rotate(0deg) scale(1);
}
.cooper-share-modal .ant-select-selection:hover,
.cooper-share-modal .ant-select-selection:focus {
  border-color: #0066FF;
}
.show-batch-add .ant-modal,
.cooper-share-modal .ant-modal {
  width: 640px !important;
}
