/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.search-page-filter :global .ant-radio-wrapper {
  display: flex;
  align-items: center;
  line-height: 34px;
  height: 34px;
  border-radius: 6px;
  color: #222A35;
  margin: 0;
  padding: 0 12px;
}
.search-page-filter :global .ant-radio-wrapper:not(.hide-icon):hover {
  background: #F7F7F7;
}
.search-page-filter :global .ant-radio-group {
  width: 100%;
}
.search-page-filter :global .ant-select-single .ant-select-selection-item {
  color: #047FFE;
}
.search-page-filter :global .ant-radio {
  top: 0;
}
.search-page-filter .hide-icon :global .ant-radio {
  display: none;
}
.search-page-filter .filter-item {
  padding: 12px 10px 20px 8px;
}
.search-page-filter .filter-item > p {
  font-weight: 400;
  font-size: 13px;
  color: rgba(34, 42, 53, 0.5);
  line-height: 18px;
  padding: 0px 12px 8px;
}
.search-page-filter .filter-item:last-child {
  border-bottom: none;
}
.search-page-filter .knowledge-name {
  width: 160px;
  height: 22px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.search-page-filter .knowledge-name span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
.search-page-filter .knowledge-name .shaixuanIcon {
  font-size: 12px;
  margin: 0 3px;
  color: #212223;
}
.search-page-filter .moreDk {
  transition: all 0.2s;
  display: block;
}
.search-page-filter .moreDk.isOpen {
  transform: rotate(180deg);
}
.dk-switch-search-popver :global .ant-tooltip-content {
  transform: translate(50px, 0);
}
