/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.recentKnowledge {
  width: 390px;
  position: relative;
}
.recentKnowledge :global .ant-tabs-nav {
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
.recentKnowledge .switch {
  position: absolute;
  top: 14px;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
}
.recentKnowledge .switch-text {
  margin-left: 6px;
  font-size: 12px;
  font-weight: 400;
  color: #7C7C7C;
}
.recentKnowledge .more {
  position: absolute;
  width: calc(100% + 12px);
  height: 32px;
  line-height: 32px;
  background: #F7F8F9;
  padding-left: 19px;
  font-size: 12px;
  font-weight: 400;
  color: #7D7D7D;
  border-radius: 0px 0px 6px 6px;
  bottom: -6px;
  left: -6px;
}
.recentKnowledge .more-click-area {
  cursor: pointer;
}
.recentKnowledge .more-icon {
  margin-left: 6px;
  font-size: 12px;
  color: #9E9F9F;
}
