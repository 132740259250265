// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.cooper-preview {

  .viewer-prev,
  .viewer-next {
    &::before {
      background-image: url('./images/icons.png');
      background-repeat: no-repeat;
      background-size: 280px;
      color: transparent;
      display: block;
      font-size: 0;
      height: 20px;
      line-height: 0;
      width: 20px;
    }
  }

  .viewer-zoom-in {
    background-image: url('./images/big.png');
    position: relative;

    &::before {
      content: '放大';
      display: block;
      opacity: 0;
      position: absolute;
      white-space: nowrap;
      left: 50%;
      top: -20px;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      font-size: 12px;
      color: #fff;
      text-align: center;
      height: 25px;
      line-height: 25px;
      padding: 0 8px;
      background: rgba(255, 255, 255, 0.9);
      color: #222A35;
      border-radius: 4px;
      width: auto !important;
    }
  }

  .viewer-zoom-in-en::before {
    content: 'Zoom in';
  }


  .viewer-zoom-out {
    background-image: url('./images/small.png');
    margin-right: 40px;
    position: relative;

    &::before {
      content: '缩小';
      display: block;
      opacity: 0;
      position: absolute;
      white-space: nowrap;
      left: 50%;
      top: -20px;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      font-size: 12px;
      color: #fff;
      text-align: center;
      height: 25px;
      line-height: 25px;
      padding: 0 8px;
      background: rgba(255, 255, 255, 0.9);
      color: #222A35;
      border-radius: 4px;
      width: auto !important;
    }
  }

  .viewer-zoom-out-en::before {
    content: 'Zoom out';
  }

  .viewer-one-to-one {
    background-image: url('./images/default.png');
    position: relative;

    &::before {
      content: '原始尺寸';
      display: block;
      opacity: 0;
      position: absolute;
      white-space: nowrap;
      left: 50%;
      top: -22px;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      font-size: 12px;
      color: #fff;
      text-align: center;
      height: 25px;
      line-height: 25px;
      padding: 0 8px;
      background: rgba(255, 255, 255, 0.9);
      color: #222A35;
      border-radius: 4px;
      width: auto !important;
    }
  }

  .viewer-one-to-one-en::before {
    content: 'Original size';
  }
 
  .viewer-one-to-one-auto {
    background-image: url('./images/auto.png');
  }

  .viewer-one-to-one-auto::before {
    content: '适应页面';
  }

  .viewer-one-to-one-en.viewer-one-to-one-auto::before {
    content: 'Fit page';
  }

  .viewer-rotate-left {
    background-image: url('./images/roate.png');
    margin-left: 40px !important;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 18px;
      left: -19px;
      top: 4px;
      background-color: #fff;
      opacity: 0.2;
    }

    &::before {
      content: '旋转';
      display: block;
      opacity: 0;
      position: absolute;
      white-space: nowrap;
      left: 50%;
      top: -20px;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      font-size: 12px;
      color: #fff;
      text-align: center;
      height: 25px;
      line-height: 25px;
      padding: 0 8px;
      background: rgba(255, 255, 255, 0.9);
      color: #222A35;
      border-radius: 4px;
      width: auto !important;
    }
  }

  .viewer-rotate-left-en {
    &::before {
      content: 'Rotate';
    }
  }

  .viewer-prev::before {
    background-position: -80px 0;
    content: 'Previous';
  }

  .viewer-next::before {
    background-position: -120px 0;
    content: 'Next';
  }



  .viewer-container {
    bottom: 0;
    direction: ltr;
    font-size: 0;
    left: 0;
    line-height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    touch-action: none;
    -webkit-touch-callout: none;
    user-select: none;

    &::selection,
    & *::selection {
      background-color: transparent;
    }

    &:focus {
      outline: 0;
    }

    & img {
      display: block;
      height: auto;
      // max-height: none !important;
      // max-width: none !important;
      // min-height: 0 !important;
      // min-width: 0 !important;
      width: 100%;
    }
  }

  .viewer-canvas {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;

    &>img {
      height: auto;
      margin: 15px auto;
      max-width: 90% !important;
      width: auto;
    }
  }

  .viewer-footer {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    overflow: visible !important;
  }

  .viewer-navbar {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .viewer-list {
    box-sizing: content-box;
    height: 56px;
    margin: 0;
    overflow: hidden;
    padding: 12px 0;

    &>li {
      color: transparent;
      cursor: pointer;
      float: left;
      font-size: 0;
      height: 56px;
      line-height: 0;
      opacity: 0.75;
      overflow: hidden;
      transition: opacity 0.15s;
      width: 70px;
      display: flex;
      outline: none;
      border-radius: 2px;
      border: 2px solid transparent;


      &:focus,
      &:hover {
        opacity: 1;
      }


      &+li {
        margin-left: 8px;
      }
    }

    &>.viewer-loading {
      position: relative;

      &::after {
        border-width: 2px;
        height: 20px;
        margin-left: -10px;
        margin-top: -10px;
        width: 20px;
      }
    }

    &>.viewer-active,
    &>.viewer-active:focus,
    &>.viewer-active:hover {
      border: 2px solid #1A6EFF;
    }
  }


  .viewer-toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border-radius: 6px;
    background: #141414;
    width: fit-content;
    margin: 0 auto 12px;
    padding: 8px;
    overflow: visible !important;

    .viewer-title {
      display: inline-block;
      color: #fff;
      font-size: 14px;
      letter-spacing: 1px;
      position: relative;
      margin: 0 !important;
      margin-right: 8px !important;
      min-width: 60px !important;
      height: 44px;
      line-height: 44px;
      overflow: visible !important;
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 18px;
        right: -9px;
        top: 12px;
        background-color: #fff;
        opacity: 0.2;
      }
    }

    .viewer-download {
      background-image: url('./images/download.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 18px 18px;
      width: 28px;
      height: 28px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.15s;
      position: relative;
      margin-left: 12px;
      
      &::before {
        content: '下载';
        display: block;
        opacity: 0;
        position: absolute;
        white-space: nowrap;
        left: 50%;
        top: -22px;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        font-size: 12px;
        color: #fff;
        text-align: center;
        height: 25px;
        line-height: 25px;
        padding: 0 8px;
        background: rgba(255, 255, 255, 0.9);
        color: #222A35;
        border-radius: 4px;
        width: auto !important;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);

        &::before {
          opacity: 1;
        }
      }
    }
    .viewer-download-en {
      &::before{
        content: 'Download';
      }
    }
    .viewer-download-no {
      display: none;
    }
    .viewer-original-img {
      background-image: url('./images/original.png');
      background-repeat: no-repeat;
      background-position: 4px center;
      background-size: 18px 18px;
      height: 28px;
      right: 10px;
      top: 8px;
      cursor: pointer;
      border-radius: 4px;
      padding: 0 4px 0 26px;
      font-size: 14px;
      line-height: 28px;
      color: #fff;
      cursor: pointer;
      border-radius: 4px;
      padding: 0 4px 0 26px;
      word-break: keep-all;
      margin-left: 12px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
    .viewer-original-img-has {
      opacity: 0.4;
      color: rgba(255, 255, 255, 1);
      cursor: not-allowed;
    }

    &>ul {
      box-sizing: border-box;
      height: 44px;
      display: flex !important;
      align-items: center;
      margin: 0 !important;
      margin-left: 8px !important;
      padding: 0 !important;
      padding-left: 12px !important;
      position: relative;
      overflow: visible !important;
      

      &>li {
        width: 28px;
        height: 28px;
        border-radius: 4px;
        float: left;
        transition: background-color 0.15s;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 18px 18px;
        cursor: pointer;
        overflow: visible !important;
        &:hover {
          background-color: rgba(255, 255, 255, 0.15);

          &::before {
            opacity: 1;
          }
        }

        &:focus {
          background-color: rgba(255, 255, 255, 0.15);
          outline: none;
          position: relative;
          z-index: 1;
        }

        &+li {
          margin-left: 10px;
        }
      }

      &>li[data-viewer-action="prev"] {
        position: fixed;
        left: 20px;
        top: 48%;
        transform: translateY(-50%);
        width: 42px;
        height: 42px;
        background: #141414;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          position: absolute;
        }

        &:hover {
          background: #1A6EFF;
        }
      }

      &>li[data-viewer-action="next"] {
        position: fixed;
        right: 20px;
        top: 48%;
        transform: translateY(-50%);
        width: 42px;
        height: 42px;
        background: #141414;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;


        &::before {
          position: absolute;
        }

        &:hover {
          background: #1A6EFF;
        }
      }
      &>.viewer-fullscreen-button {
        width: 28px;
        height: 28px;
        border-radius: 4px;
        background-image: url('./images/full.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 18px 18px;
        cursor: pointer;
        position: absolute;
        left: 78px;
        transition: background-color 0.15s;
        -webkit-app-region: no-drag;
  
        &::before {
          content: '全屏';
          display: block;
          opacity: 0;
          position: absolute;
          white-space: nowrap;
          left: 50%;
          top: -20px;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          font-size: 12px;
          color: #fff;
          text-align: center;
          height: 25px;
          line-height: 25px;
          padding: 0 8px;
          background: rgba(255, 255, 255, 0.9);
          color: #222A35;
          border-radius: 4px;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.15);
  
          &::before {
            opacity: 1;
          }
        }
      }
      &>.viewer-fullscreen-button-en{
        &::before {
          content: 'Full screen';
        }
      }
    }
  }


  .viewer-tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    color: #fff;
    display: none;
    font-size: 12px;
    height: 20px;
    left: 50%;
    line-height: 20px;
    margin-left: -25px;
    margin-top: -10px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 50px;
  }



  .viewer-fixed {
    position: fixed;
  }

  .viewer-open {
    overflow: hidden;
  }

  .viewer-show {
    display: block;
  }

  .viewer-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .viewer-invisible {
    visibility: hidden;
  }

  .viewer-move {
    cursor: move;
    cursor: grab;
  }

  .viewer-fade {
    opacity: 0;
  }

  .viewer-in {
    opacity: 1;
  }

  .viewer-transition {
    transition: all 0.3s;
  }

  @keyframes viewer-spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .viewer-loading {
    &::after {
      animation: viewer-spinner 1s linear infinite;
      border: 4px solid rgba(255, 255, 255, 0.1);
      border-left-color: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 40px;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px;
      position: absolute;
      top: 50%;
      width: 40px;
      z-index: 1;
    }
  }

  @media (max-width: 767px) {
    .viewer-hide-xs-down {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .viewer-hide-sm-down {
      display: none;
    }
  }

  @media (max-width: 1199px) {
    .viewer-hide-md-down {
      display: none;
    }
  }
}