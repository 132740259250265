/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.no-perm-modal-content {
  overflow: hidden;
}
.no-perm-modal-content .no-perm-modal-img {
  width: 100%;
}
.no-perm-modal-content .no-perm-modal-text {
  font-size: 14px;
  line-height: 22px;
  color: #4E555D;
  margin-bottom: 16px;
}
.no-perm-modal-content .no-perm-modal-link {
  font-size: 12px;
}
.no-perm-modal-content .no-perm-modal-link a {
  color: #047FFE !important;
}
.no-perm-modal-content .no-perm-modal-link a:hover {
  text-decoration: underline;
}
.no-perm-modal-content .no-perm-modal-link:last-child {
  margin-top: 6px;
}
.no-perm-modal :global .ant-modal-content {
  border-radius: 8px !important;
}
.no-perm-modal :global .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0;
}
.no-perm-modal :global .ant-modal-body {
  padding: 24px !important;
}
.no-perm-modal :global .ant-modal-confirm-content {
  margin-top: 0 !important;
}
