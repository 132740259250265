/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.view-tag-tree {
  height: 100%;
}
.view-tag-tree .operate-wrap {
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  height: 24px;
}
