/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.recent-activity {
  margin: 0 auto;
  min-width: 667px;
}
.recent-activity :global .ant-tabs-nav {
  padding: 0 32px 2px;
  position: sticky !important;
  top: 0px;
  z-index: 2;
}
.is-dc :global .ant-tabs-nav {
  padding: 0 24px 2px;
  position: sticky;
  top: 0px;
  z-index: 2;
}
