/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-preview .viewer-prev::before,
.cooper-preview .viewer-next::before {
  background-image: url('./images/icons.png');
  background-repeat: no-repeat;
  background-size: 280px;
  color: transparent;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  width: 20px;
}
.cooper-preview .viewer-zoom-in {
  background-image: url('./images/big.png');
  position: relative;
}
.cooper-preview .viewer-zoom-in::before {
  content: '放大';
  display: block;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  top: -20px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  text-align: center;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  background: rgba(255, 255, 255, 0.9);
  color: #222A35;
  border-radius: 4px;
  width: auto !important;
}
.cooper-preview .viewer-zoom-in-en::before {
  content: 'Zoom in';
}
.cooper-preview .viewer-zoom-out {
  background-image: url('./images/small.png');
  margin-right: 40px;
  position: relative;
}
.cooper-preview .viewer-zoom-out::before {
  content: '缩小';
  display: block;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  top: -20px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  text-align: center;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  background: rgba(255, 255, 255, 0.9);
  color: #222A35;
  border-radius: 4px;
  width: auto !important;
}
.cooper-preview .viewer-zoom-out-en::before {
  content: 'Zoom out';
}
.cooper-preview .viewer-one-to-one {
  background-image: url('./images/default.png');
  position: relative;
}
.cooper-preview .viewer-one-to-one::before {
  content: '原始尺寸';
  display: block;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  top: -22px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  text-align: center;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  background: rgba(255, 255, 255, 0.9);
  color: #222A35;
  border-radius: 4px;
  width: auto !important;
}
.cooper-preview .viewer-one-to-one-en::before {
  content: 'Original size';
}
.cooper-preview .viewer-one-to-one-auto {
  background-image: url('./images/auto.png');
}
.cooper-preview .viewer-one-to-one-auto::before {
  content: '适应页面';
}
.cooper-preview .viewer-one-to-one-en.viewer-one-to-one-auto::before {
  content: 'Fit page';
}
.cooper-preview .viewer-rotate-left {
  background-image: url('./images/roate.png');
  margin-left: 40px !important;
  position: relative;
}
.cooper-preview .viewer-rotate-left::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 18px;
  left: -19px;
  top: 4px;
  background-color: #fff;
  opacity: 0.2;
}
.cooper-preview .viewer-rotate-left::before {
  content: '旋转';
  display: block;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  top: -20px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  text-align: center;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  background: rgba(255, 255, 255, 0.9);
  color: #222A35;
  border-radius: 4px;
  width: auto !important;
}
.cooper-preview .viewer-rotate-left-en::before {
  content: 'Rotate';
}
.cooper-preview .viewer-prev::before {
  background-position: -80px 0;
  content: 'Previous';
}
.cooper-preview .viewer-next::before {
  background-position: -120px 0;
  content: 'Next';
}
.cooper-preview .viewer-container {
  bottom: 0;
  direction: ltr;
  font-size: 0;
  left: 0;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  touch-action: none;
  -webkit-touch-callout: none;
  user-select: none;
}
.cooper-preview .viewer-container::selection,
.cooper-preview .viewer-container *::selection {
  background-color: transparent;
}
.cooper-preview .viewer-container:focus {
  outline: 0;
}
.cooper-preview .viewer-container img {
  display: block;
  height: auto;
  width: 100%;
}
.cooper-preview .viewer-canvas {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.cooper-preview .viewer-canvas > img {
  height: auto;
  margin: 15px auto;
  max-width: 90% !important;
  width: auto;
}
.cooper-preview .viewer-footer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  overflow: visible !important;
}
.cooper-preview .viewer-navbar {
  background-color: rgba(0, 0, 0, 0.5);
}
.cooper-preview .viewer-list {
  box-sizing: content-box;
  height: 56px;
  margin: 0;
  overflow: hidden;
  padding: 12px 0;
}
.cooper-preview .viewer-list > li {
  color: transparent;
  cursor: pointer;
  float: left;
  font-size: 0;
  height: 56px;
  line-height: 0;
  opacity: 0.75;
  overflow: hidden;
  transition: opacity 0.15s;
  width: 70px;
  display: flex;
  outline: none;
  border-radius: 2px;
  border: 2px solid transparent;
}
.cooper-preview .viewer-list > li:focus,
.cooper-preview .viewer-list > li:hover {
  opacity: 1;
}
.cooper-preview .viewer-list > li + li {
  margin-left: 8px;
}
.cooper-preview .viewer-list > .viewer-loading {
  position: relative;
}
.cooper-preview .viewer-list > .viewer-loading::after {
  border-width: 2px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  width: 20px;
}
.cooper-preview .viewer-list > .viewer-active,
.cooper-preview .viewer-list > .viewer-active:focus,
.cooper-preview .viewer-list > .viewer-active:hover {
  border: 2px solid #1A6EFF;
}
.cooper-preview .viewer-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 6px;
  background: #141414;
  width: fit-content;
  margin: 0 auto 12px;
  padding: 8px;
  overflow: visible !important;
}
.cooper-preview .viewer-toolbar .viewer-title {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  position: relative;
  margin: 0 !important;
  margin-right: 8px !important;
  min-width: 60px !important;
  height: 44px;
  line-height: 44px;
  overflow: visible !important;
}
.cooper-preview .viewer-toolbar .viewer-title:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 18px;
  right: -9px;
  top: 12px;
  background-color: #fff;
  opacity: 0.2;
}
.cooper-preview .viewer-toolbar .viewer-download {
  background-image: url('./images/download.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 18px 18px;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.15s;
  position: relative;
  margin-left: 12px;
}
.cooper-preview .viewer-toolbar .viewer-download::before {
  content: '下载';
  display: block;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  top: -22px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  text-align: center;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  background: rgba(255, 255, 255, 0.9);
  color: #222A35;
  border-radius: 4px;
  width: auto !important;
}
.cooper-preview .viewer-toolbar .viewer-download:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.cooper-preview .viewer-toolbar .viewer-download:hover::before {
  opacity: 1;
}
.cooper-preview .viewer-toolbar .viewer-download-en::before {
  content: 'Download';
}
.cooper-preview .viewer-toolbar .viewer-download-no {
  display: none;
}
.cooper-preview .viewer-toolbar .viewer-original-img {
  background-image: url('./images/original.png');
  background-repeat: no-repeat;
  background-position: 4px center;
  background-size: 18px 18px;
  height: 28px;
  right: 10px;
  top: 8px;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 4px 0 26px;
  word-break: keep-all;
  margin-left: 12px;
}
.cooper-preview .viewer-toolbar .viewer-original-img:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.cooper-preview .viewer-toolbar .viewer-original-img-has {
  opacity: 0.4;
  color: #ffffff;
  cursor: not-allowed;
}
.cooper-preview .viewer-toolbar > ul {
  box-sizing: border-box;
  height: 44px;
  display: flex !important;
  align-items: center;
  margin: 0 !important;
  margin-left: 8px !important;
  padding: 0 !important;
  padding-left: 12px !important;
  position: relative;
  overflow: visible !important;
}
.cooper-preview .viewer-toolbar > ul > li {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  float: left;
  transition: background-color 0.15s;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 18px 18px;
  cursor: pointer;
  overflow: visible !important;
}
.cooper-preview .viewer-toolbar > ul > li:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.cooper-preview .viewer-toolbar > ul > li:hover::before {
  opacity: 1;
}
.cooper-preview .viewer-toolbar > ul > li:focus {
  background-color: rgba(255, 255, 255, 0.15);
  outline: none;
  position: relative;
  z-index: 1;
}
.cooper-preview .viewer-toolbar > ul > li + li {
  margin-left: 10px;
}
.cooper-preview .viewer-toolbar > ul > li[data-viewer-action="prev"] {
  position: fixed;
  left: 20px;
  top: 48%;
  transform: translateY(-50%);
  width: 42px;
  height: 42px;
  background: #141414;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cooper-preview .viewer-toolbar > ul > li[data-viewer-action="prev"]::before {
  position: absolute;
}
.cooper-preview .viewer-toolbar > ul > li[data-viewer-action="prev"]:hover {
  background: #1A6EFF;
}
.cooper-preview .viewer-toolbar > ul > li[data-viewer-action="next"] {
  position: fixed;
  right: 20px;
  top: 48%;
  transform: translateY(-50%);
  width: 42px;
  height: 42px;
  background: #141414;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cooper-preview .viewer-toolbar > ul > li[data-viewer-action="next"]::before {
  position: absolute;
}
.cooper-preview .viewer-toolbar > ul > li[data-viewer-action="next"]:hover {
  background: #1A6EFF;
}
.cooper-preview .viewer-toolbar > ul > .viewer-fullscreen-button {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-image: url('./images/full.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 18px 18px;
  cursor: pointer;
  position: absolute;
  left: 78px;
  transition: background-color 0.15s;
  -webkit-app-region: no-drag;
}
.cooper-preview .viewer-toolbar > ul > .viewer-fullscreen-button::before {
  content: '全屏';
  display: block;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  top: -20px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  text-align: center;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  background: rgba(255, 255, 255, 0.9);
  color: #222A35;
  border-radius: 4px;
}
.cooper-preview .viewer-toolbar > ul > .viewer-fullscreen-button:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.cooper-preview .viewer-toolbar > ul > .viewer-fullscreen-button:hover::before {
  opacity: 1;
}
.cooper-preview .viewer-toolbar > ul > .viewer-fullscreen-button-en::before {
  content: 'Full screen';
}
.cooper-preview .viewer-tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  color: #fff;
  display: none;
  font-size: 12px;
  height: 20px;
  left: 50%;
  line-height: 20px;
  margin-left: -25px;
  margin-top: -10px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 50px;
}
.cooper-preview .viewer-fixed {
  position: fixed;
}
.cooper-preview .viewer-open {
  overflow: hidden;
}
.cooper-preview .viewer-show {
  display: block;
}
.cooper-preview .viewer-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
.cooper-preview .viewer-invisible {
  visibility: hidden;
}
.cooper-preview .viewer-move {
  cursor: move;
  cursor: grab;
}
.cooper-preview .viewer-fade {
  opacity: 0;
}
.cooper-preview .viewer-in {
  opacity: 1;
}
.cooper-preview .viewer-transition {
  transition: all 0.3s;
}
@keyframes viewer-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cooper-preview .viewer-loading::after {
  animation: viewer-spinner 1s linear infinite;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 40px;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 40px;
  z-index: 1;
}
@media (max-width: 767px) {
  .cooper-preview .viewer-hide-xs-down {
    display: none;
  }
}
@media (max-width: 991px) {
  .cooper-preview .viewer-hide-sm-down {
    display: none;
  }
}
@media (max-width: 1199px) {
  .cooper-preview .viewer-hide-md-down {
    display: none;
  }
}
