/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-window__sharecoop .ant-modal-body {
  padding: 16px 0;
}
.cooper-window__sharecoop .ant-modal-header {
  padding-top: 20px;
  padding-bottom: 20px;
}
.cooper-window__sharecoop .ant-modal-title {
  font-size: 16px;
  color: #333;
  line-height: 22px;
  font-weight: normal;
}
.cooper-window__sharecoop .ant-modal-close-x {
  font-size: 20px;
  line-height: 62px;
}
.cooper-window__sharecoop .ant-select-selection--single {
  height: 40px;
}
.cooper-window__sharecoop .ant-select-selection__placeholder,
.cooper-window__sharecoop .ant-select-search__field__placeholder {
  height: 36px;
  line-height: 36px;
}
.cooper-window__sharecoop .ant-select-combobox .ant-select-search__field {
  height: 40px;
}
.cooper-window__sharecoop .ant-table-tbody > tr > td {
  border-bottom: none;
}
.cooper-window__sharecoop .ant-table-wrapper {
  max-height: 150px;
  overflow: scroll;
}
.cooper-window__sharecoop .ant-table-placeholder {
  border: none;
}
.share-modal__form {
  padding: 0 0 24px 0;
  border-bottom: 1px solid #F1F1F4;
}
.share-modal__form .modal-field {
  margin-bottom: 24px;
  display: flex;
}
.share-modal__form .modal-field:last-child {
  margin-bottom: 0;
}
.share-modal__form .modal-field.modal-field__radio {
  display: flex;
  align-items: flex-start;
  margin-bottom: 14px;
}
.share-modal__form .modal-field.modal-field__radio .ant-radio-wrapper,
.share-modal__form .modal-field.modal-field__radio .ant-checkbox-wrapper {
  position: relative;
  margin-right: 8px;
  bottom: 2px;
}
.share-modal__form .modal-field.modal-field__radio .ant-select-selection-selected-value {
  font-size: 14px;
  color: #17233e;
}
.share-modal__form .modal-field.modal-field__radio .ant-select-selection {
  margin-bottom: 0;
  border-color: #e4e9f3;
}
.share-modal__form .modal-field.modal-field__radio .ant-checkbox-inner {
  border-radius: 7px;
  border-color: #bdc5d2 !important;
}
.share-modal__form .modal-field.modal-field__radio .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3f81ff !important;
  border-color: #3f81ff !important;
}
.share-modal__form .modal-field.modal-field__radio .ant-select-selection:hover,
.share-modal__form .modal-field.modal-field__radio .ant-select-selection:focus {
  border-color: #3f81ff;
}
.share-modal__form .field-name {
  display: inline-block;
  width: 80px;
  line-height: 26px;
  margin-right: 20px;
  text-align: right;
  font-size: 14px;
  color: #17233E;
  vertical-align: middle;
}
.share-modal__form .field-name.field-sharename {
  vertical-align: top;
}
.share-modal__form .ant-radio-group small {
  font-size: 14px;
  color: #8a93a8;
  margin-left: 8px;
}
.share-modal__form span.ant-radio + * {
  font-size: 14px;
}
.share-modal__form .ant-radio-button-wrapper-checked {
  color: #06f;
  border-color: #06f;
  box-shadow: -1px 0 0 0 #06f;
}
.share-modal__form .ant-radio-button-wrapper-checked:hover {
  border-color: #06f;
  box-shadow: -1px 0 0 0 #06f;
}
.share-modal__form .ant-radio-button-wrapper:hover,
.share-modal__form .ant-radio-button-wrapper-focused {
  color: #06f;
}
.share-modal__shareform {
  margin-top: 24px;
  border: none;
}
.share-modal__shareform .share-input__wrapper {
  position: relative;
  display: inline-block;
  flex: 1;
  border-radius: 4px;
  border: 1px solid #e4e9f3;
}
.share-modal__shareform .share-input__wrapper input {
  display: inline-block;
  padding: 7px;
  line-height: 30px;
  margin-right: 0;
  border-radius: 4px;
  width: 100%;
  height: 30px;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  font-size: 14px;
  color: #17233E;
}
.share-modal__shareform .share-input__wrapper.active {
  padding-right: 147px;
}
.share-modal__shareform .share-input__wrapper .share-pwd {
  position: absolute;
  right: 0;
  top: 0;
  width: 140px;
  bottom: 0;
  border-left: 1px solid #e4e9f3;
  text-align: center;
  line-height: 32px;
  color: #8a93a8;
  font-size: 14px;
}
.share-modal__actions {
  margin-top: 16px;
  text-align: right;
}
.share-modal__actions .btn-share__cancel {
  background: #FFF;
  color: #17233e;
  padding: 5px 19px;
  line-height: 20px;
  font-size: 16px;
  outline: none;
  font-weight: normal;
  cursor: pointer;
  border: 1px solid #e4e9f3;
  margin-right: 10px;
  border-radius: 4px;
  transition: color 0.3s ease;
  font-size: 14px;
}
.share-modal__actions .btn-share__cancel:hover {
  color: #06f;
  border: 1px solid #06f;
}
.share-modal__actions .btn-share__ok {
  background: none;
  background: #06f;
  color: #FFF;
  padding: 5px 15px;
  line-height: 20px;
  font-size: 14px;
  outline: none;
  font-weight: normal;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s ease;
  border: 1px solid transparent;
}
.share-modal__actions .btn-share__ok:hover {
  background: #06f;
}
.share-modal__tip {
  margin-top: 8px;
  text-align: center;
  color: #ff3f5a;
  font-size: 14px;
  padding-bottom: 20px;
}
.share-perm .perm-label {
  color: #666;
  font-size: 14px;
}
.share-perm .ant-checkbox-disabled + span .perm-label {
  color: #999;
}
.share-perm .ant-checkbox-group {
  display: inline-block;
}
