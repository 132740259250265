// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.header-wrap {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 106;
  border-bottom: 1px solid @border-color-split;
  background-color: #fff;

  // min-width: 1185px;
  &>div {
    flex-shrink: 1;
    flex-grow: 1;
    width: 300px;
  }

  .dk-logo {
    width: 92px;
    height: 28px;
    margin-left: 14px;
  }

  .betaIcon {
    margin-left: 4px;
    width: 34px;
    height: 18px;
  }

  .left {
    margin: 16px;
    display: flex;
    align-items: center;
    // width: 180px;
  }

  .middle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 540px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 24px;

    @media screen and (max-width: 1260px) {
      & {
        width: 420px;
      }
    }
  }

  .header-middle-dc {
    margin-left: 256px;
    justify-content: flex-start;
    width: 340px;
  }

  .right {
    margin: 18px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 330px;

    .avatar {
      cursor: pointer;
      display: block;
      width: 26px;
      height: 26px;
      border: 1px solid #F4F4F4;
      flex-shrink: 0;
    }
  }

  .cooperEnter {
    cursor: pointer;
    font-size: 24px;
    color: #676D71;
    background: #ffffff;
    line-height: 22px;

    &:hover {
      background: #EEEEEE;
    }
  }

  .cooperEnter-active {
    background: #EEEEEE;
  }

  .title {
    margin-left: 12px;
    height: 28px;
    font-size: 20px;
    font-weight: 600;
    color: #222A35;
    line-height: 28px;
    white-space: nowrap;
  }

  .createDk {
    flex-shrink: 0;
    min-width: 130px;
    word-break: keep-all;
    margin: 0 28px;
    background: @primary-color;
    color: #ffffff;
    padding: 6px;
    text-align: center;
    border-radius: 4px;
    // border-radius: 18px;
    border: 1px solid rgba(11, 131, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    .create-icon {
      font-size: 12px;
      margin-right: 8px;
      font-weight: bold;
    }

    .create-text {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
    }
  }

  .switchLocale {
    padding-left: 4px;
    margin-right: 20px;
    cursor: pointer;
    height: 24px;
    border-radius: 2px;
    color: #505050;
    display: flex;
    align-items: center;

    &:hover {
      background: #EEEEEE;
    }

    .switchLocaleIcon {
      margin-left: 4px;
      width: 14px;
      height: 14px;
    }
  }

  .knowledge-name {
    height: 22px;
    font-size: 15x;
    color: #222A35;
    line-height: 22px;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;
    font-weight: 500px;

    span {
      cursor: pointer;
      .ellipsis();
      max-width: 400px;
      font-weight: 500;
      font-size: 15px;
    }

    i {
      cursor: pointer;
    }
  }

  .moreDk {
    transition: all 0.2s;
    display: block;

    &.isOpen {
      transform: rotate(180deg);
    }
  }

  .divider {
    margin: 0 14px;
    width: 1px;
    height: 16px;
    background-color: #E9E9EB;
  }

  .localeIcon {
    margin-right: 24px;
    width: 14px;
    height: 14px;
    transform: rotate(90deg);
  }
}

.cooperEnterChoose {
  .cooperEnterMenu {
    .cooperEnterRow {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 206px;
      height: 56px;
      font-size: 16px;
      font-weight: 500;
      color: #1C2A3D;
      line-height: 19px;
      border-radius: 4px;

      &:hover {
        background: #F4F4F4;
      }
    }

    .cooperEnterIcon {
      width: 36px;
      height: 36px;
      margin: 0 10px 0 13px;
      background: #ffffff;
      border: 1px solid #E8EAED;
      border-radius: 50%;
    }
  }
}

.switchLocaleChoose,
.loginOutChoose {
  .menu {
    .row {
      padding-left: 14px;
      color: #111111;
      width: 99px;
      height: 36px;
      line-height: 36px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: #F4F4F4;
      }
    }
  }
}

.home-list-icon {
  width: 6px;
  height: 6px;
  border-radius: 2px;
  margin-right: 14px;
  margin-left: 30px;
  opacity: 0.7;

  &.home-list-icon-cooper {
    background-color: #0066FF;
  }

  &.home-list-icon-dk {
    background-color: #047FFE;
  }

  &.home-list-icon-team {
    background-color: #FD7D48;
  }
}

:global {
  .knowLedge_cooperEnter {
    .ant-popover-inner {
      box-shadow: 0px 6px 20px 0px rgba(18, 19, 21, 0.12);

      .ant-popover-inner-content {
        padding: 7px;
      }
    }

    &.ant-popover-placement-bottomLeft {
      padding-top: 6px !important;
    }
  }

  .knowLedge_switchLocale,
  .knowLedge_loginOut {
    .ant-popover-inner {
      box-shadow: 0px 6px 20px 0px rgba(18, 19, 21, 0.08);
      border: none !important;

      .ant-popover-inner-content {
        padding: 7px;
      }
    }
  }
}

.header-wrap-dc {
  z-index: 20;
}

.header-ai-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  color: #644bfc;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 12px;
  white-space: nowrap;

  &:hover {
    background: #ECEDF1;
  }

  &.header-ai-button-disabled {
    cursor: not-allowed;
    color: rgba(100, 75, 252, 0.6);

    .header-ai-button-icon {
      opacity: 0.6;
    }

    .header-ai-button-beta {
      opacity: 0.6;
    }

    .header-ai-button-disabled-tip {
      display: none;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 8px);
      padding: 16px;
      background: #fff;
      border: 0.5px solid #F1F1F1;
      border-radius: 6px;
      font-size: 14px;
      color: #222A35;
      width: 249px;
      white-space: normal;
      cursor: default;
      filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.1));

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%) rotate(-45deg);
        background: #FFFFFF;
        box-sizing: border-box;
        border-width: 0.5px 0.5px 0px 0px;
        border-style: solid;
        border-color: #F1F1F1;
        width: 10px;
        height: 10px;
      }
    }

    &:hover {
      background: rgba(236, 237, 241, 0.6);

      .header-ai-button-disabled-tip {
        display: block;
      }
    }
  }

  .header-ai-button-beta {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 13px;
    color: #fff;
    border-radius: 20px;
    background: #7D96F9;
    font-size: 10px;
    line-height: 13px;
    font-weight: 500;
    margin-left: 4px;
    font-style: normal;
  }
}

.header-ai-button-icon {
  width: 18px;
  height: 18px;
}
