/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.paget-tree-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  content-visibility: auto;
  color: #333;
}
.paget-tree-wrap :global .ant-spin-nested-loading {
  height: 100%;
}
.paget-tree-wrap :global .ant-tree-indent-unit {
  width: 23px;
}
.paget-tree-wrap :global .ant-tree-list-holder {
  padding: 0 8px;
  min-height: 394px;
}
.paget-tree-wrap :global .ant-tree-list-holder > div {
  width: max-content;
  min-width: 100%;
}
.paget-tree-wrap :global .ant-tree-list-holder-inner {
  overflow-y: scroll;
}
.paget-tree-wrap :global .ant-tree-list-holder-inner:first-child .ant-tree-title {
  position: static;
}
.paget-tree-wrap :global .ant-tree-treenode {
  padding: 0 11px !important;
  border-radius: 4px;
  height: 36px;
  align-items: center !important;
  width: 100%;
  transition: all 0.3s;
}
.paget-tree-wrap :global .ant-tree-treenode:hover {
  background-color: rgba(235, 236, 239, 0.6);
}
.paget-tree-wrap :global .ant-tree-node-content-wrapper {
  padding-left: 0 !important;
  flex: 1;
}
.paget-tree-wrap :global .ant-tree-node-content-wrapper:hover {
  background: none !important;
}
.paget-tree-wrap :global .ant-tree-checkbox {
  width: 23px;
  box-sizing: border-box;
  margin: 0;
}
.paget-tree-wrap :global .ant-tree-checkbox > span {
  display: none;
}
.paget-tree-wrap :global .ant-tree-checkbox::before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  background-size: cover;
  background-image: url('@/assets/icon/checkable-empty.png');
}
.paget-tree-wrap :global .ant-tree-checkbox.ant-tree-checkbox-checked::before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  background-size: cover;
  background-image: url('@/assets/icon/checkable-checked.png');
}
.paget-tree-wrap :global .ant-tree-checkbox.ant-tree-checkbox-checked::after {
  width: 14px;
  height: 14px;
}
.paget-tree-wrap :global .ant-tree-switcher {
  line-height: 36px;
  width: 23px;
  color: rgba(34, 42, 53, 0.8);
  text-align: left;
}
.paget-tree-wrap :global .ant-tree-switcher > span {
  vertical-align: middle;
}
.paget-tree-wrap :global .ant-tree-treenode-selected {
  background: rgba(235, 236, 239, 0.6);
  color: #047FFE;
}
.paget-tree-wrap :global .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: none !important;
}
.tree-content {
  height: 394px;
  overflow: auto;
}
.tree-content:not(.tree-content-single) :global .ant-tree-checkbox {
  transform: translate3d(-21px, 0, 0);
}
.tree-content:not(.tree-content-single) :global .ant-tree-switcher {
  transform: translate3d(23px, -1px, 0);
}
.transfer-node-title {
  display: block;
  max-width: 460px;
  line-height: 36px;
  height: 36px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.transfer-node-title > i {
  color: #047FFE;
  position: absolute;
  margin-left: -23px;
  font-size: 14px;
  line-height: 36px !important;
}
.tree-content-single {
  padding-top: 8px;
  height: 424px;
}
.tree-content-single :global .ant-tree-list-holder {
  min-height: 424px;
}
.multi-checked-total {
  color: #999;
  font-size: 12px;
  line-height: 16px;
  padding: 12px 20px 4px;
}
.checkable-icon {
  width: 14px;
  height: 14px;
}
.no-perm {
  color: #999;
}
