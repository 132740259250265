/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.file-tree-modal {
  width: 580px !important;
}
.file-tree-modal .import-modal-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
.file-tree-modal .import-modal-btns > * {
  margin-left: 10px;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  padding: 0 20px;
  border-radius: 3px;
}
.file-tree-modal .import-modal-btns > *:disabled {
  opacity: 0.5;
}
.file-tree-modal .import-modal-btns > *:hover {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
}
.file-tree-modal .import-modal-btns > *:last-child,
.file-tree-modal .import-modal-btns > *:last-child:hover {
  color: #fff;
  background: #047FFE;
  border-color: #047FFE;
}
.file-tree-modal .import-tip {
  font-size: 14px;
  color: #2F343C;
  line-height: 16px;
  margin-bottom: 16px;
}
.file-tree-modal .file-modal-content {
  height: 282px;
  margin-top: 16px;
  border-radius: 4px;
  color: #2F343C;
  overflow: auto;
  padding: 0 8px;
}
.file-tree-modal .file-modal-bread {
  font-size: 14px;
  color: #94a0b0;
  width: 100%;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.file-tree-modal .file-modal-bread > span {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
.file-tree-modal .file-modal-bread > span:last-child {
  color: #2f343c;
  cursor: default;
}
.file-tree-modal .file-modal-bread > img {
  width: 12px;
  margin: 0 4px;
}
.file-tree-modal .file-cooper-wrap li,
.file-tree-modal .file-space-wrap li {
  display: flex;
  align-items: center;
  line-height: 36px;
  height: 36px;
  font-size: 14px;
  cursor: pointer;
}
.file-tree-modal .file-cooper-wrap span,
.file-tree-modal .file-space-wrap span {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-tree-modal .file-cooper-wrap img,
.file-tree-modal .file-space-wrap img {
  width: 18px;
  margin: 0 8px 0 0;
}
.file-tree-modal .file-cooper-wrap {
  margin-top: 10px;
}
.file-tree-modal .file-cooper-wrap span {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-tree-modal .space-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-tree-modal .checked-number {
  position: absolute;
  bottom: 26px;
  left: 24px;
  font-size: 12px;
  color: #2F343C;
}
.file-tree-modal :global .ant-modal-content {
  border-radius: 6px;
}
.file-tree-modal :global .ant-modal-close-x::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAACJUlEQVRYR+2XPU8CQRCGZ8TK78bef3Gl2EqiiSYkil+J3yQ2hP64loCNBowx6mlhvMJKrWn5AfTaQoWIiYkw5ojocdm9vd210ATa3bl99p13ZwaEP/bDP8YDfSBRRv6XQul0ejiXyzVFt5JZ38lkhk4zmTdeDFehjf2UA4iLhGDHpo3teDzekjnYv9c0zYGnavMYob1FCPd24XCB9T0mkHuL92qjDkSDnSDEm1jUWFWF6sDUGjYQrHxBUGR0cPw8m234ofgKJVMnRLD7HaAIxYABBLy+LObXQivkbnQcJ/JQKl8D0ZIqFAsGEJ2pyZGEZVkfUkC6UCowHXeIjKqiFA8mFjWWRT4UAskqpQMTSqGugmGU0oWRAhIpValUyPe0ARFvZ6NGQpQmr21CpcwbwFMKgFqeOqMEI61QYPo81CrKdMOlFRJB6cAoK+QGugZ+rr1eEVGip3QoVnQthZivqceZ6r1POmXM3oR4SwBtnTajpBAPxn3a7gd1e5+Uh4JgunUmTPEUtapQKQsDI1PRg6CEQDIwvwEVCKQCowvFBfqNRqniqaAR9owINj3TohNmnvH7gwkFeGoX8z/jsbftsAzm/v2pNdt1AIh01hGVYALS1xrG0YlCwXr1n89VaD2ZugOCOQC8iM0YezIjBOuSrlKPpfIZAKwRwb1dzM+z9gWaOpk0R1i3ENWSoPWDA3Ps6Mh64e0RPnudw1Vi+0Ai1foKiRT6BJbxwDREQEZzAAAAAElFTkSuQmCC);
  background-size: 18px 18px;
  background-position: center center;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
}
.file-tree-modal :global .ant-modal-close-x .ant-modal-close-icon {
  display: none;
}
.file-tree-modal :global .ant-modal-body {
  padding: 0 24px 24px;
  font-size: 14px;
  line-height: 26px;
}
.file-tree-modal :global .ant-modal-header {
  border-bottom: none;
  border-radius: 6px 6px 0 0;
  padding: 16px 24px;
}
.file-tree-modal :global .ant-modal-header .ant-modal-title {
  font-size: 18px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-tree-modal :global .ant-modal-header .ant-modal-title > span {
  color: #2F343C;
}
