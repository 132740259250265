/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.resizer {
  position: absolute;
  right: 0px;
  width: 1px;
  background: #EBEEF1;
  flex-grow: 0;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  height: 100%;
}
.resizer:hover {
  width: 2px;
  background: #3370ff;
}
.resizer .resizer-container {
  cursor: col-resize;
  height: 100%;
  width: 12px;
  margin-left: -6px;
}
