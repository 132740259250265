/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
:global .img-search-popover {
  margin-left: 10px;
  max-width: 400px !important;
  width: 360px !important;
}
:global .img-search-popover .ant-tooltip-inner {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background: #fff;
  padding: 8px;
  color: #222A35;
  font-size: 14px;
}
:global .img-search-popover.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 18px;
}
:global .img-search-popover.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 18px;
}
:global .img-search-popover .ant-tooltip-arrow-content {
  width: 8px;
  height: 8px;
  background: #fff;
}
