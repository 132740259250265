/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.search-wrap {
  background-color: #ffffff;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  color: #222A35;
  font-size: 14px;
  transition: background-color 0.2;
  width: 100%;
  position: relative;
}
.search-wrap:hover {
  background-color: #ffffff;
}
.search-wrap .search-before {
  display: flex;
  align-items: center;
  background: none;
  height: 24px;
  line-height: 24px;
  color: #333333;
  border: none;
  font-size: 14px;
}
.search-wrap .search-tag {
  margin-left: 8px;
  padding: 0 4px 0 8px;
  border-radius: 3px;
  background: rgba(34, 42, 53, 0.08);
  display: flex;
  align-items: center;
}
.search-wrap .search-tag :global .dk-icon-guanbi {
  vertical-align: text-bottom;
}
.search-wrap .search-icon-btn {
  display: block;
  align-items: center;
  line-height: 14px;
  font-size: 12px;
  border: 1px solid rgba(34, 42, 53, 0.2);
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 4px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.search-wrap .search-icon-btn:hover {
  background: #D8DEE3;
  border-color: transparent;
}
.search-wrap .search-icon-btn .search-text {
  margin-right: 5px;
}
.search-wrap :global .ant-input {
  line-height: 1.66667;
  font-size: 14px;
}
.search-wrap :global .ant-input-group-addon {
  background: none !important;
  border: none;
  padding: 0 7px;
}
.search-wrap :global .ant-input-clear-icon svg {
  display: none;
}
.search-wrap :global .ant-input-clear-icon::after {
  font-family: "dk-iconfont" !important;
  content: '\e607';
  font-size: 16px;
  z-index: 10;
}
.search-wrap :global .ant-input-affix-wrapper-lg {
  padding: 2px 11px 2px 0 !important;
  font-size: 14px !important;
}
.search-wrap :global .ant-input-affix-wrapper {
  padding-left: 2px;
}
.click-tip {
  padding-right: 12px;
}
:global .global-search-popover {
  padding-top: 8px !important;
}
:global .global-search-popover .ant-tooltip-inner {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background: #fff !important;
  padding: 8px;
  color: #222A35;
  font-size: 14px;
}
:global .global-search-popover .ant-tooltip-arrow {
  top: 18px;
}
:global .global-search-popover .ant-tooltip-arrow-content {
  width: 8px;
  height: 8px;
  background: #fff;
}
:global .global-search-popover {
  width: 540px !important;
  max-width: 540px !important;
  z-index: 1069 !important;
}
:global .global-search-popover.share-search-popover {
  /*  width: 100%;
      max-width: 100%; */
}
:global .global-search-popover.share-search-popover .search-more-wrap {
  text-align: right;
}
:global .global-search-popover.share-search-popover .search-more-wrap span {
  font-size: 13px;
  color: rgba(34, 42, 53, 0.2);
}
:global .global-search-popover .ant-tooltip-arrow {
  display: none;
}
:global .global-search-popover .ant-tooltip-inner {
  border: 1px solid #EFF0F2;
  padding: 0;
}
:global .global-search-popover .search-popover-content {
  overflow: auto;
  padding-bottom: 54px;
  padding-top: 10px;
}
:global .global-search-popover .search-popover-content .search-history-title {
  font-size: 13px;
  color: rgba(34, 42, 53, 0.5);
  line-height: 18px;
  padding: 2px 0px 8px 20px;
}
:global .global-search-popover .search-popover-content ul {
  margin: 0;
  padding: 0;
}
:global .global-search-popover .search-popover-content ul li {
  margin: 0 8px 2px;
}
:global .global-search-popover .search-popover-content ul li:first-child {
  margin-top: 0;
}
:global .global-search-popover .search-popover-content ul li.global-search-dk-title {
  font-size: 13px;
  line-height: 18px;
  margin: 12px 16px;
  margin-left: 19px;
  color: rgba(34, 42, 53, 0.5);
}
:global .global-search-popover .search-popover-content ul li.global-search-dk-title:hover {
  background-color: #fff;
}
:global .global-search-popover .search-popover-content .search-metis-ul {
  width: 100%;
  padding: 0 10px;
  display: grid;
  grid-gap: 26px;
  grid-template-columns: repeat(auto-fill, 179px);
}
:global .global-search-popover .search-more-wrap {
  position: absolute;
  bottom: 1px;
  left: 0px;
  width: calc(100% - 1px);
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  padding: 8px;
}
:global .global-search-popover .search-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: rgba(34, 42, 53, 0.5);
  border-radius: 8px;
  line-height: 28px;
  padding: 4px 12px;
}
:global .global-search-popover .search-more .search-more-icon {
  font-size: 16px;
}
:global .global-search-popover .search-more span {
  display: flex;
  align-items: center;
}
:global .global-search-popover .search-more span:last-child {
  font-size: 13px;
  color: rgba(34, 42, 53, 0.3);
}
:global .global-search-popover .search-more:hover {
  background: #F7F8F9;
}
