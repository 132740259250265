@import 'normalize.css/normalize.css';

html {
  body {
    // user-select: none;
    height: 100%;
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    overflow: auto;
    color: @text-color;
    font-family: @font-family;
    -webkit-font-smoothing: antialiased;

    > iframe {
      pointer-events: none; // 出错时允许点击
    }

    p,
    ul {
      padding: 0;
      margin: 0;
    }

    li {
      list-style: none;
    }

    a {
      text-decoration: none;
      color: @text-color;

      &:hover {
        color: @text-color;
      }
    }

    ul {
      padding-inline-start: 0px;
    }

    .dk-iconfont {
      font-weight: 400;
      line-height: 16px;
    }

    ::selection {
      background-color: rgba(0, 102, 255, 0.2);
      color: #505050;
    }

    input {
      caret-color: @primary-color;
    }

    .ant-input:focus,
    .ant-input-focused {
      border: none;
      box-shadow: none;
    }

    .ant-input {
      border-radius: 0px;
    }

    #root {
      height: 100%;
      // display: flex;
      flex-direction: column;
    }

    #root-skeleton {
      height: 100%;
    }

    .ant-drawer__reset {
      .ant-drawer-body {
        padding: 0;
      }
    }

    .page-detail-loading {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // 手机展示时，覆盖编辑器的样式
    .dk-editor-in-phone_reset {
      #knowledge_editor_box {
        .editor-title {
          padding: 24px 24px 18px 24px !important;
        }
      }

      #didoc-editor-slot-sub-title {
        padding-left: 24px !important;
        padding-right: 24px !important;
      }

      .ProseMirror {
        padding: 0 24px 15px !important;
      }
    }


    // popover公共覆盖样式类
    .dk-ant-popover__reset {

      &.ant-popover-placement-bottom,
      &.ant-popover-placement-bottomLeft,
      &.ant-popover-placement-bottomRight,
      &.ant-popover-placement-top,
      &.ant-popover-placement-topLeft,
      &.ant-popover-placement-topRight {
        padding: 0;
      }

      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-inner {
        background: #FFFFFF;
        box-shadow: 0 4px 22px 0 rgba(18, 19, 21, 0.16);
        border-radius: 6px;
        border: 1px solid #EFF0F1;
      }

      .ant-popover-inner-content {
        padding: 0;
      }
    }


    // tooltip公共覆盖样式类
    .dk-ant-tooltip__reset {
      max-width: 320px !important;
      border-radius: 4px !important;

      .ant-tooltip-arrow {
        display: none;
      }

      &.hide {
        display: none;
      }

      .ant-tooltip-inner {
        padding: 2px 8px;
        min-height: auto;
        background-color: #2A2D31;
        font-weight: 500;
        font-size: 12px;
      }
    }

    // 设计师更新了tooltip基础样式
    .dk-ant-tooltip__reset_v2 {
      border: 0.5px solid #DCDCDC;
      box-shadow: 0px 6px 24px -3px rgba(0, 0, 0, 0.12);
      .ant-tooltip-arrow {
        display: block !important;
        width: 14px !important;
        height: 14px !important;
      }
      .ant-tooltip-inner {
        padding: 16px !important;
        background-color: #FFF !important;
        font-weight: 400;
        font-size: 14px !important;
        color: #222A35 !important;
        a {
          color: #1A6EFF;
        }
      }
    }

    .dk-ant-form-item {
      .ant-form-item {
        margin-bottom: 0;
      }
    }

    // modal公共覆盖样式类
    .dk-ant-modal_reset {
      &.ant-modal-wrap {
        z-index: 1040;
      }

      .ant-modal-content {
        padding: 24px 24px;
        border-radius: 8px;

        .ant-modal-body {
          padding: 0;
        }

        .ant-modal-footer {
          padding: 0;
          border-top: none;

          .ant-btn {
            font-size: 16px;
            line-height: 22px;
            border-radius: 4px;
            border: none;
            height: auto;
            padding: 8px 28px;
          }

          .ant-btn:first-child {
            color: #444B4F;
            background: #F6F6F6;
            margin-right: 8px;
          }
        }
      }
    }

    .dk-ant-form_reset {
      .ant-form-item {
        margin: 0;
      }
    }

    // 少包一层，优先级低于AntD自身，所以要加important
    .dk-ant-switch_reset {
      box-shadow: none;
      transform: scale(0.7) !important;

      &.ant-switch:focus {
        box-shadow: none;
      }

      .ant-click-animating-node {
        display: none;
      }
    }

    .dk-ant-checkbox_reset {
      .ant-checkbox .ant-checkbox-inner {
        border-radius: 3px;
        width: 16px;
        height: 16px;
        border: 1px solid #C9CFD7;

        &:after {
          display: none;
        }

        transition:none;
      }

      .ant-checkbox-checked .ant-checkbox-inner,
      .ant-checkbox-indeterminate .ant-checkbox-inner {
        border-color: #0066FF;
        background: none no-repeat center/10px, center/10px #0066FF;
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-image: url('../icon/indeterminate.png');
      }

      .ant-checkbox-checked {
        &:after {
          display: none;
        }

        .ant-checkbox-inner {
          background-image: url('../icon/check.png');
        }
      }
    }

    [class^=dk-tag] {
      font-size: 12px;
      line-height: 15px;
      padding: 2px 4px;
    }

    // 选择权限公用样式
    .dk-changePower-wrap_reset.ant-popover {
      min-width: 162px;
      box-shadow: 0px 6px 20px 0px rgba(18, 19, 21, 0.08);
      border-radius: 6px;
      border: 1px solid #f9f9f9;
      font-size: 14px;
      font-weight: 400;
      color: @text-color;
      z-index: 1080;

      &.hide {
        display: none;
      }

      .changePower {
        padding: 6px 7px;

        &-item {
          cursor: pointer;
          height: 36px;
          line-height: 36px;
          padding-left: 13px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.disabled {
            color: #999;
          }

          &:hover:not(.disabled) {
            color: #0B83FF;
            background-color: #ECF6FF;
          }

          &.cancelConnect {
            color: #FE0B19;
          }

          .checkIcon {
            margin-right: 11px;
            width: 12px;
            height: 12px;
          }
        }
      }

      .ant-popover-inner {
        padding: 0;
      }

      .ant-popover-content {
        width: auto;
      }
    }

    // 全局radio样式
    .dk-ant-radio_reset {
      .ant-radio-inner {
        width: 16px;
        height: 16px;
        border: 1px solid #BFBFBF;

        &::after {
          display: none;
        }
      }

      .ant-radio-checked .ant-radio-inner {
        border: 4px solid #047FFE;
      }

      .ant-radio-wrapper {
        font-size: 14px;
      }
    }

    .nameCheckMsg {
      margin-left: 13px;
      position: absolute;
      bottom: -4px;
      width: 325px;
      font-weight: 400;
      color: #FF563B;
    }

    .ant-message {
      z-index: 100000 !important; // 预览 -> 用户反馈弹窗 -> message
    }

    #js-watermark-iframe {
      position: fixed;
    }

    .ReactModal__Overlay {
      z-index: 1000;
    }
  }
}

// .didoc-editor-container{
//   padding: 0 56px 15px !important;
// }
// #didoc-editor-slot-sub-title, #didoc-editor-slot-after, #didoc-editor-slot-menu {
//   padding: 0 60px !important;
// }
// .didoc-editor-app .editor .didoc-editor-input.editor-title{
//   padding-left: 60px !important;
//   padding-right: 60px !important;
// }

.page-style-root {
  .editor {

    .didoc-editor-container,
    .didoc-editor-input.editor-title,
    #didoc-editor-slot-sub-title {
      transition: none;
    }
  }

  #didoc-editor-slot-after,
  #didoc-editor-slot-menu {
    transition: none;
  }
}

/*窄屏模式适配*/
.page-style-root {

  // 屏幕宽窄设置
  &.screen-1 {
    .editor {
      .didoc-editor-container {
        padding-left: calc(50% - 374px);
        padding-right: calc(50% - 374px);
      }

      .didoc-editor-input.editor-title {
        padding-left: calc(50% - 374px);
        padding-right: calc(50% - 374px);
      }

      #didoc-editor-slot-sub-title {
        padding-left: calc(50% - 374px);
        padding-right: calc(50% - 374px);
      }
    }

    #didoc-editor-slot-after,
    #didoc-editor-slot-menu {
      padding-left: calc(50% - 374px);
      padding-right: calc(50% - 374px);
    }
  }

  // 字号设置
  &.font-size-14 {
    .editor {
      .didoc-editor-container {
        font-size: 14px;
      }
    }
  }
}

.page-style-root {
  .didoc-editor-content-container:not(.tab-intergation-open) {
    padding-right: 22px;
  }

  .dk-editor-in-phone_reset {
    .didoc-editor-content-container:not(.tab-intergation-open) {
      padding-right: 0;
    }
  }
}

.chatmessage {
  margin-bottom: 8px;
}

.chatmessage-text {
  margin-bottom: 8px;
}

.chatmessage-quick-link {
  border: none;
  background: #fff;
  padding: 6px 10px;
  color: rgb(83, 135, 180);
  cursor: pointer;
  display: inline-block;
  margin-right: 4px;
  text-decoration-line: none;
  border-radius: 4px;
  margin-bottom: 8px;
}

// .mac {
//   overflow: hidden;
// }

/** scrollbar for windows,mac and others**/
.mac,
.linux,
.generic {
  .os-scrollbar {
    overflow-y: overlay;
    overflow-y: auto !important;
  }
}

.windows .os-scrollbar {
  overflow-y: hidden !important;

  &:hover {
    overflow-y: auto !important;
  }
}

.windows .os-scrollbar-width {
  &:hover {
    width: -moz-calc(100% + 17px) !important;
    width: -o-calc(100% + 17px) !important;
    width: -webkit-calc(100% + 17px) !important;
    width: calc(100% + 17px) !important;
  }
}

@keyframes debounce {
  from {
    pointer-events: none;
  }

  to {
    pointer-events: all;
  }
}

.out-space-double-ok {
  .ant-modal-content {
    border-radius: 8px !important;
  }
}
.link-explain{
  color: @primary-color;
  cursor: pointer;
  &:hover{
    color: @primary-color;
  }
}