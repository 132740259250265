// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.cooper-preview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.8);

  &-header {
    position: fixed;
    z-index: 3015;
    height: 56px;
    width: 100%;
    background: #141414;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
  }

  &-body {
    position: absolute;
    top: 56px;
    bottom: 80px;
    left: 0;
    right: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    >img {
      display: none;
    }

    .cp-video-content {
      width: 80%;
      height: 80%;
      overflow: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cp-unknown {
      color: #fff;
    }

    .cp-pre {
      width: 80%;
      height: 80%;
      overflow: auto;
      padding: 10px;
      background: #fff;
      color: #333;
    }
  }

  &-footer {
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
    z-index: 3015;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cp-content {
    position: relative;
    overflow: auto;
    display: flex;
    width: 80%;
    height: 80%;
    justify-content: center;
    align-items: center;
    &.has-image {
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.9);
    }
    &.has-pdf{
      height: 100%;
    }
  }
  .cp-pre {
    // width: 100%;
    overflow: auto;
    padding: 10px;
    background: #fff;
    color: #333;
  }
  .video-react {
    height: 100% !important;
    width: 100% !important;
  }
  .cp-unknown {
    display:flex;
    font-size: 14px;
    color: #fff;
    align-items:center;
    justify-content:center;
  }
  .cp-foot {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    height: 68px;
    width: 100%;
    background: #000;
    color: #fff;
  }
  .cp-foot-tool {
    width: 100%;
    height: 68px;
    line-height: 68px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .cp-ft-item{
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    .cp-ft-icon {
      display: inline-block;
      height: 20px;
      margin: 0 4px 0 18px;
     
    }
    .cp-ft-text{
      .font-size(12px);
    }
    .cp-image {
      flex-shrink: 0;
    }
  }
  
  .video-iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.viewer-container-fullscreen {
  .cooper-preview-header {
    display: none;
  }

  .viewer-container {
    .viewer-footer {
      height: 0px !important;
      overflow: hidden !important;
    }
    .viewer-navbar {
      height: 0px;
    }
  }
}

.viewer-container-fullscreen-cancel {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 40px;
  top: 40px;
  z-index: 10000;
  background-image: url('./icon/close.png');
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center center;
  cursor: pointer;

  &::before {
    content: attr(title);
    display: block;
    opacity: 0;
    position: absolute;
    white-space: nowrap;
    left: 50%;
    top: -20px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    font-size: 12px;
    color: #fff;
    text-align: center;
    height: 25px;
    line-height: 25px;
    padding: 0 8px;
    background: rgba(255, 255, 255, 0.9);
    color: #222A35;
    border-radius: 4px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);

    &::before {
      opacity: 1;
    }
  }
}