/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.aside-wrap .aside-handle {
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 105;
}
.aside-wrap .aside-handle .aside-content {
  width: 280px;
  height: 100%;
  background: #f7f9fa;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transition-property: left, top;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
}
.aside-wrap .aside-handle .aside-content .icon-shouqi {
  position: absolute;
  top: 19px;
  right: -14px;
  z-index: 91;
  font-size: 26px;
  line-height: 26px;
  background: #ffffff;
  border: 1px solid #E8EAED;
  border-radius: 50%;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  opacity: 0;
}
.aside-wrap .aside-handle .aside-content .icon-shouqi:hover {
  background: #047FFE;
  color: #ffffff;
}
.aside-wrap .aside-handle .aside-content:hover .icon-shouqi {
  opacity: 1;
}
.aside-wrap .aside-handle-hover::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 52px;
}
.aside-wrap .icon-zhankai {
  position: relative;
  top: 12px;
  left: 6px;
  font-size: 26px;
  line-height: 26px;
  background: #ffffff;
  border: 1px solid #E8EAED;
  border-radius: 50%;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}
.aside-wrap .icon-zhankai:hover::before {
  content: '\e707';
}
.aside-wrap .icon-zhankai:hover {
  background: #047FFE;
  color: #ffffff;
}
.aside-wrap-modal .aside-handle .aside-content {
  height: calc(100% - 92px);
  top: 52px;
  margin-bottom: 40px;
  z-index: 999;
  border-radius: 4px;
  box-shadow: 0px 6px 24px 0px rgba(18, 19, 21, 0.16);
  border: 1px solid #EFF0F2;
}
