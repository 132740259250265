/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.direct-share {
  font-size: 14px;
  color: #17233e;
}
.direct-share .share-container {
  position: relative;
  min-height: 159px;
}
.direct-share .share-container .share-list {
  height: 100%;
  overflow: auto;
  padding-top: 10px;
}
.direct-share .share-container .share-list .share-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.direct-share .share-container .place-holder {
  height: 21px;
}
.direct-share .share-container .avatar {
  width: 40px;
  border-radius: 20px;
  margin-right: 16px;
  vertical-align: middle;
}
.direct-share .share-container .info {
  display: inline-block;
  vertical-align: middle;
}
.direct-share .share-container .info > div:first-child {
  max-width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.direct-share .share-container .info .mail {
  font-size: 12px;
  color: #8a93a8;
}
.direct-share .share-container .operation {
  float: right;
  margin-top: 10px;
}
.direct-share .share-container .operation .ant-checkbox-wrapper {
  margin-right: 8px;
}
.direct-share .share-container .operation > span {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #bec5d2;
}
.direct-share .share-container .operation a {
  color: #17233e;
}
.direct-share .share-container .operation a:hover,
.direct-share .share-container .operation a:focus {
  color: #0066FF;
}
.direct-share .foot .direct-add {
  padding: 0 32px;
}
.direct-share .foot .button-container {
  text-align: right;
}
.direct-share .foot .button-container button {
  font-size: 14px;
  height: 32px;
  padding: 0 16px;
}
.direct-share .foot .button-container button.two-word {
  padding: 0 20px;
}
.direct-share .foot .button-container button:first-child {
  border-color: #e4e9f3;
  color: #17233e;
}
.direct-share .foot .button-container button:first-child:hover {
  color: #000;
  border-color: #000;
}
.direct-share .foot .button-container button:last-child {
  background-color: #000;
  margin-left: 10px;
  border: 0;
  color: #fff;
}
.direct-share .foot .button-container button:last-child:hover {
  background-color: #000;
}
.direct-share .foot .warn {
  text-align: left;
  color: #999999;
  line-height: 18px;
  word-break: break-word;
}
