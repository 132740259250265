/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.primary-modal input:focus {
  border: 1px solid #E5F2FE !important;
}
.primary-modal .ant-modal-content {
  border-radius: 8px !important;
}
.primary-modal .ant-modal-body {
  padding: 0 32px;
}
.primary-modal .ant-modal-header {
  border-radius: 8px 8px 0 0 !important;
  padding: 32px 32px 16px;
  border-bottom: 1px solid transparent;
}
.primary-modal .ant-modal-footer {
  border-top: 1px solid transparent;
  padding: 10px 32px 32px;
}
.primary-modal .ant-modal-close {
  top: 22px;
  right: 14px;
}
.primary-modal .dk-iconfont {
  font-size: 28px;
}
.primary-modal .dk-iconfont:hover {
  background-color: #EBEEF1;
  border-radius: 4px;
}
.folderTree-noAuthModal :global .ant-modal-content .ant-modal-confirm-btns > button:first-child {
  display: none;
}
.create-team-space .ant-modal {
  padding: 0;
}
.create-team-space .ant-modal .ant-modal-close {
  display: none;
}
.create-team-space .ant-modal .ant-modal-body {
  padding: 0;
}
.create-team-space .modal-close {
  color: rgba(34, 42, 53, 0.4);
  font-size: 24px;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px !important;
}
.create-team-space .modal-close:hover {
  background-color: #EBEEF1;
}
