// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.apply-detail {
  width: 400px;
  height: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -110px;
  margin-left: -200px;
  text-align: center;
}

.apply-success {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
}

.apply-tips {
  font-size: 14px;
  font-weight: 400;
  color: #505050;
  line-height: 20px;
  margin-top: 3px;
}

.apply-success-icon {
  display: block;
  width: 22px;
  height: 22px;
  background-size: 22px 22px;
  margin-right: 8px;
  background-image: url(../../../uploadTips/icon/success.svg);
}

.apply-box {
  width: 400px;
  background: #F5F6F7;
  border-radius: 7px;
  margin-top: 20px;
  padding: 16px 20px;
  position: relative;

  :global {
    .ant-input {
      border-radius: 4px;
      margin-bottom: 6px;
      font-size: 14px;
      padding: 14px 16px;
    }

    .ant-input:focus {
      border: 1px solid #2e9dff;
    }
  }

  p {
    margin-bottom: 10px;
    display: flex;
  }

  .apply-name {
    margin-top: 4px;
  }

  .apply-value {
    flex: 1;
    text-align: left;
  }

  p span:first-child {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    text-align: right;
    flex-shrink: 0;
  }

  p span:last-child {
    word-break: break-all;
  }

  a,
  a:hover {
    color: #047FFE;
    font-weight: bold;
  }

  .apply-Approver span:first-child {
    text-align: left;
  }

  .apply-Approver {
    margin-bottom: 16px;
  }

  .apply-line {
    border-top: 1px solid rgba(0, 0, 0, 0.0500);
    width: 100%;
    height: 10px;
    display: block;
  }

  .apply-text {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 17px;
    margin-top: 7px;
  }

  button {
    margin-top: 10px;
    width: 100%;
    height: 34px;
    font-weight: bold;
    border-radius: 4px;
  }

  .button-urge {
    margin-bottom: 4px;
    margin-top: 16px;
  }
}

.apply-title {
  display: inline-flex;
  justify-content: center;
}

.apply-title-text {
  text-align: center; /* 将文本水平居中 */
  word-break: break-all;
  .ellipsis2()
}

.apply-select {

  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: -4px;
}

.apply-popover {
  padding: 7px;

  li,
  .item-li {
    line-height: 36px;
    font-size: 14px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 13px;
    justify-content: space-between;
    cursor: pointer;

    span {
      margin-right: 28px;
    }

    &:hover {
      background: #F4F4F4;
    }

    :global {
      .dk-icon-xuanzhong {
        color: #0066FF;
      }
    }
  }
}

.role-name {
  line-height: 20px;
  font-size: 14px;
  color: #047FFE;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-weight: 500;

  justify-content: center;
  border-radius: 4px;
  padding: 4px 8px;

  span {
    cursor: pointer;
    .ellipsis();
  }

  :global {
    .anticon {
      font-size: 12px;
    }
  }

  .role {
    margin-right: 5px;
  }
}

.role-name:hover {
  background: rgba(0, 0, 0, 0.05)
}

.more-role {
  transition: all 0.2s;
  display: block;

  &.isOpen {
    transform: rotate(180deg);
  }
}

.perm-help {
  position: absolute;
  top: 14px;
  right: 12px;
  background: #F5F6F7;
  border-radius: 4px;
  color: #047FFE;
  height: 24px;
  line-height: 16px;
  font-size: 12px;
  padding: 4px 8px;
  font-weight: 400 !important;

  &:hover {
    color: #047FFE;
    background: rgba(0, 0, 0, 0.0500);
  }
}

.err-tip-inPhone-apply {
  width: 90%;

  .apply-box {
    width: 90%;
    margin: 20px auto;
  }
}

.tip-inPhone-apply-detail {
  width: 100%;
  height: auto;
  margin-top: 0px;
  margin-left: 0px;
  transform: translate(-50%, -50%);

  .apply-box {
    width: 90%;
    margin: 20px auto;
  }
}