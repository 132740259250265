/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.position-select-wrap {
  height: 32px;
  line-height: 32px;
  width: 100%;
  position: relative;
}
.position-select-wrap :global .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid #047FFE;
}
.position-select-wrap :global .ant-input-affix-wrapper:focus,
.position-select-wrap :global .ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.position-select-wrap :global .ant-input-affix-wrapper {
  height: 32px;
  align-items: center;
  border: 1px solid rgba(34, 42, 53, 0.2);
  border-radius: 6px;
}
.position-select-wrap :global .ant-input-affix-wrapper:hover {
  border: 1px solid #047FFE;
}
.position-select-wrap :global .ant-input-group-addon {
  border-radius: 4px;
}
.position-select-wrap .input-content {
  position: relative;
}
.position-search-popover {
  width: 268px !important;
  z-index: 1024 !important;
  padding-top: 0 !important;
}
.options {
  width: 100%;
  position: relative;
}
