/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.delete-icon {
  margin-right: 8px;
}
.icon-gengduo1 {
  color: rgba(34, 42, 53, 0.5);
}
.v3-operate-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.v3-operate-menu:hover {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.auth-tips-disabled {
  color: rgba(34, 42, 53, 0.5) !important;
  cursor: not-allowed !important;
}
.v3-operate-menu-operation-menu {
  min-width: 178px;
  background: #FFFFFF;
  border-radius: 8px;
  color: #222A35;
}
.v3-operate-menu-operation-menu.small {
  width: 112px;
}
.v3-operate-menu-operation-menu .divider {
  margin: 0;
}
.v3-operate-menu-operation-menu .safe-li {
  margin-bottom: 8px !important;
  margin-top: 0px !important;
  padding: 0 4px !important;
}
.v3-operate-menu-operation-menu .safe-li:hover {
  background: none !important;
}
.v3-operate-menu-operation-menu > li {
  margin: 2px 0 !important;
}
.v3-operate-menu-operation-menu > li > i {
  margin-right: 8px;
}
.v3-operate-menu-operation-menu > li .more-tip {
  position: absolute !important;
  right: 0 !important;
}
.v3-operate-menu-operation-menu > li.hasDivider {
  margin-bottom: 12px;
}
.v3-operate-menu-operation-menu > li.team-help {
  background: #F7F9FA !important;
  padding-top: 8px !important;
  padding-left: 14px !important;
  font-size: 12px !important;
  color: #4E555D !important;
  height: 34px !important;
}
.v3-operate-menu-operation-menu > li.team-help i.team-help-book {
  font-size: 14px;
}
.v3-operate-menu-operation-menu > li.team-help-bg {
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  background: #F4F7F9 !important;
  width: 100% !important;
  height: 42px !important;
}
.v3-operate-menu-operation-menu > li.team-help-bg:hover {
  background: #F4F7F9 !important;
}
.v3-operate-menu-operation-menu > li.team-help:hover {
  background: #F4F7F9 !important;
}
.v3-operate-menu-operation-menu > li.teamManage {
  padding: 5px 12px !important;
  height: 56px !important;
  display: flex;
  align-items: flex-start !important;
}
.v3-operate-menu-operation-menu > li.teamManage .left {
  margin-right: 8px;
}
.v3-operate-menu-operation-menu > li.teamManage .desc {
  margin-top: 3px;
  padding-bottom: 6px;
  font-size: 12px;
  line-height: 17px;
  color: #999999;
}
.v3-operate-menu-operation-menu :global div.ant-divider:last-of-type:last-child {
  display: none;
}
.v3-operate-menu-operation-menu .delete-li {
  color: #FF563B;
}
.v3-operate-menu-operation-menu .delete-li-team {
  color: #FF563B;
  height: 42px !important;
  padding-bottom: 9px !important;
}
.v3-operate-menu-operation-menu .restore-disabled-li {
  color: rgba(34, 42, 53, 0.5);
  cursor: not-allowed !important;
}
.cancel-star-icon {
  color: #f6c746;
}
.po-download {
  border-right: none !important;
  width: 132px;
}
.po-download-item {
  color: #222A35 !important;
  margin-bottom: 0 !important;
}
.po-download-item :global .ant-menu-title-content {
  display: flex !important;
  align-items: center !important;
  line-height: 32px !important;
}
.po-download-item:hover {
  border-radius: 6px !important;
  background: #F2F3F3 !important;
  color: #222A35 !important;
}
.po-download-item-img {
  width: 16px;
  margin-right: 8px;
}
