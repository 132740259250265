/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.menu-wrap :global .ant-menu-light .ant-menu-item:hover,
.menu-wrap :global .ant-menu-light .ant-menu-item-active,
.menu-wrap :global .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.menu-wrap :global .ant-menu-light .ant-menu-submenu-active,
.menu-wrap :global .ant-menu-light .ant-menu-submenu-title:hover {
  color: #222A35;
}
.menu-wrap :global .ant-menu-vertical {
  border: none !important;
}
.menu-wrap :global .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #999999;
}
.menu-wrap :global .ant-menu-vertical .ant-menu-item:not(:last-child),
.menu-wrap :global .ant-menu-vertical-left .ant-menu-item:not(:last-child),
.menu-wrap :global .ant-menu-vertical-right .ant-menu-item:not(:last-child),
.menu-wrap :global .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0;
}
.menu-wrap :global .ant-menu-vertical .ant-menu-item,
.menu-wrap :global .ant-menu-vertical-left .ant-menu-item,
.menu-wrap :global .ant-menu-vertical-right .ant-menu-item,
.menu-wrap :global .ant-menu-vertical .ant-menu-submenu-title,
.menu-wrap :global .ant-menu-inline .ant-menu-item {
  margin: 0;
  padding: 0 8px;
}
.menu-wrap :global li {
  height: 40px !important;
  line-height: 40px !important;
  padding: 0 8px !important;
  display: block !important;
}
.menu-wrap .operate-ul {
  border-radius: 6px;
  width: 142px;
  font-size: 14px;
  line-height: 20px;
  color: #222A35;
}
.menu-wrap .operate-li {
  border-radius: 4px;
  cursor: pointer;
  height: auto;
  line-height: 20px;
  color: #222A35;
  padding: 8px 12px;
}
.menu-wrap .operate-li :global(.ant-menu-submenu-arrow) {
  color: #999999;
  right: 0;
}
.menu-wrap .operate-li:hover {
  background: #F4F4F4;
  color: #222A35;
}
.menu-wrap .operate-li:hover :global(.ant-menu-submenu-arrow) {
  color: #999999;
}
.menu-wrap .operate-li-sub {
  padding: 0 8px;
}
.menu-wrap .operate-li-sub :global .ant-menu-submenu-title {
  padding-left: 0;
  padding-right: 0;
  line-height: 40px;
  height: auto;
}
.menu-wrap .operate-li-sub :global .ant-menu-submenu-title:hover {
  color: #222A35;
}
:global .ant-menu-item-disabled.ant-menu-item,
:global .ant-menu-submenu-vertical.ant-menu-submenu-disabled {
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
:global .ant-menu-item-disabled.ant-menu-item:hover,
:global .ant-menu-submenu-vertical.ant-menu-submenu-disabled:hover,
:global .ant-menu-item-disabled.ant-menu-item .ant-menu-item-icon,
:global .ant-menu-submenu-vertical.ant-menu-submenu-disabled .ant-menu-item-icon {
  color: rgba(0, 0, 0, 0.25) !important;
}
.operate-li .operate-icon-pop {
  font-size: 14px;
  color: #494F55;
}
.operate-li .hasStar {
  color: #f6c746;
}
.operate-li .star-btn {
  font-size: 18px;
}
.operate-li-dangerous {
  color: #FE0B19 !important;
}
.operate-li-dangerous:hover {
  color: #FE0B19 !important;
}
.operate-li-dangerous .operate-icon-pop {
  color: #FE0B19;
}
.operate-li-sub-wrap > ul {
  padding: 7px !important;
  border-radius: 6px !important;
}
.operate-ul-add {
  width: 162px !important;
}
.catalog-add-container,
.catalog-operate-container {
  z-index: 8888 !important;
  border-radius: 6px;
}
.link {
  color: #047FFE;
  cursor: pointer;
}
