/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.dk-guide {
  top: 0;
  z-index: 3;
}
.dk-guide .mask-btn {
  position: fixed;
}
.dk-guide :global .ant-popover-inner-content {
  padding: 16px;
}
.dk-guide.gudie-active > div {
  height: 100%;
  z-index: 9999;
}
.dk-guide.gudie-active .mask-btn {
  z-index: 999;
}
.guide-pop-content img {
  width: 254px;
  height: 168px;
  background: #EFF0F2;
  border-radius: 6px;
  border: 1px solid #EFF0F2;
}
.guide-pop-content .guide-pop-title {
  font-size: 16px;
  font-weight: 500;
  color: #111111;
  line-height: 32px;
  margin-top: 5px;
  width: 254px;
}
.guide-pop-content .guide-pop-desc {
  font-size: 14px;
  color: #505050;
  line-height: 22px;
  width: 254px;
}
.guide-pop-content .guide-pop-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
}
.guide-pop-content .guide-pop-btns :global .ant-btn {
  border-radius: 4px;
}
.guide-pop-content .guide-pop-btns :global .ant-btn:not(:last-child) {
  margin-right: 10px;
}
.guide-pop-content .guide-pop-btns :global .ant-btn:not(.ant-btn-primary) {
  border: 1px solid transparent;
  background-color: #EFF0F2;
  color: #565C60;
}
.guide-pop-content .guide-pop-process {
  font-size: 14px;
  color: #999999;
  line-height: 20px;
}
.guide-pop-content-img .guide-pop-title,
.guide-pop-content-img .guide-pop-process,
.guide-pop-content-img .guide-pop-desc {
  color: rgba(255, 255, 255, 0.95);
}
.guide-pop-content-img :global .ant-popover-inner,
.guide-pop-content-img :global .ant-popover-arrow-content {
  background-color: #1D8CFE;
}
.guide-pop-content-img :global .ant-popover-inner {
  border-radius: 8px;
}
.guide-pop-content-img :global .ant-btn:not(.ant-btn-primary) {
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
  color: #fff !important;
}
.guide-pop-content-img :global .ant-btn.ant-btn-primary {
  color: #047FFE !important;
  background-color: #fff !important;
  background-color: #fff;
}
:global .guide-welcome-wrap .guide-welcome-desc {
  font-size: 16px;
  margin-top: 24px;
}
:global .guide-welcome-wrap.ant-modal-confirm .ant-modal-body {
  padding: 26px 26px 24px 26px;
}
:global .guide-welcome-wrap.ant-modal-confirm .ant-modal-body .ant-modal-confirm-content {
  margin-top: 0;
}
:global .guide-icon {
  position: relative;
  z-index: 999;
  display: block;
  float: right;
  font-style: normal;
  width: 28px;
  height: 28px;
}
:global .guide-icon::after {
  font-family: "dk-iconfont" !important;
  content: '\e6f8';
  display: block;
  font-size: 32px;
  color: #AEAFB2;
  margin-top: 7px;
}
