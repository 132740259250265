/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.global-auth-tip {
  padding: 10px;
  border-radius: 6px;
  word-break: break-word;
}
.global-auth-tip p {
  font-size: 14px;
  line-height: 22px;
}
.global-auth-tip p .auth-type {
  margin: 0px 3px;
  font-weight: bold;
}
.global-auth-tip a {
  display: block;
  color: #047FFE;
  font-size: 12px;
  margin-top: 6px;
}
.global-auth-tip a:hover {
  text-decoration: underline;
  color: #047FFE;
}
.global-auth-tip .line {
  width: 100%;
  height: 1px;
  background: #F2F3F3;
  margin: 12px 0;
}
.global-auth-tip .name_link {
  display: inline-block;
  font-size: 14px;
  margin: 0px 3px;
}
.global-auth-tip-pop {
  width: 260px !important;
}
.ant-popover.global-auth-tip-pop .ant-popover-arrow {
  display: none !important;
}
