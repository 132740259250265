/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.link-share {
  padding: 15px 32px 24px;
  font-size: 14px;
  color: #17233e;
}
.link-share .foot {
  margin-top: 36px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.link-share .foot .button-container {
  flex-shrink: 0;
  margin-left: 10px;
}
.link-share .foot .button-container button {
  font-size: 14px;
  height: 32px;
  padding: 0 16px;
}
.link-share .foot .button-container button.two-word {
  padding: 0 20px;
}
.link-share .foot .button-container button:first-child {
  border-color: #e4e9f3;
  color: #17233e;
}
.link-share .foot .button-container button:first-child:hover {
  color: #000;
  border-color: #000;
}
.link-share .foot .button-container button:last-child {
  background-color: #000;
  margin-left: 10px;
  border: 0;
  color: #fff;
}
.link-share .foot .button-container button:last-child:hover {
  background-color: #000;
}
.link-share .foot .warn {
  text-align: left;
  color: #999999;
  line-height: 18px;
  word-break: break-word;
}
.link-share .foot .warn.copyLinkTip {
  margin-top: 158px;
}
.share-setting .mode,
.share-setting .expire,
.share-setting .permis {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  padding-left: 20px;
}
.share-setting .mode .label-text,
.share-setting .expire .label-text,
.share-setting .permis .label-text {
  width: 80px;
  text-align: right;
  margin-right: 20px;
}
.share-setting .mode .type-des,
.share-setting .expire .type-des,
.share-setting .permis .type-des {
  color: #8a93a8;
}
.share-setting .mode {
  padding-left: 34px;
}
.share-result .link-pswd {
  line-height: 32px;
}
.share-result .input-container {
  float: right;
  width: 442px;
}
.share-result .input-container input {
  font-size: 14px;
  height: 32px;
  color: #17233e;
  border-color: #e4e9f3;
}
.share-result .input-container input:first-child {
  width: 318px;
}
.share-result .input-container input:nth-child(2) {
  border-left: 0;
  color: #8a93a8;
  width: 123px;
  text-align: center;
}
.share-result .input-container input:hover,
.share-result .input-container input:focus {
  border: 1px solid #e4e9f3 !important;
  box-shadow: none;
}
.share-result .no-pswd {
  width: 485px;
}
.share-result .no-pswd input {
  width: 485px !important;
  border-radius: 4px !important;
}
.share-result .button-container {
  margin-top: 158px;
}
