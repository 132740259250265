// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.transfer-modal{
    min-width: 828px !important;
    padding: 0;
    :global {
        .ant-modal-content {
          border-radius: 8px;
        }
        .ant-modal-close-x {
            width: 20px;
            height: 20px;
            line-height: 60px;
            margin-right: 24px;
            margin-top: 21px;
          &::before {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAACJUlEQVRYR+2XPU8CQRCGZ8TK78bef3Gl2EqiiSYkil+J3yQ2hP64loCNBowx6mlhvMJKrWn5AfTaQoWIiYkw5ojocdm9vd210ATa3bl99p13ZwaEP/bDP8YDfSBRRv6XQul0ejiXyzVFt5JZ38lkhk4zmTdeDFehjf2UA4iLhGDHpo3teDzekjnYv9c0zYGnavMYob1FCPd24XCB9T0mkHuL92qjDkSDnSDEm1jUWFWF6sDUGjYQrHxBUGR0cPw8m234ofgKJVMnRLD7HaAIxYABBLy+LObXQivkbnQcJ/JQKl8D0ZIqFAsGEJ2pyZGEZVkfUkC6UCowHXeIjKqiFA8mFjWWRT4UAskqpQMTSqGugmGU0oWRAhIpValUyPe0ARFvZ6NGQpQmr21CpcwbwFMKgFqeOqMEI61QYPo81CrKdMOlFRJB6cAoK+QGugZ+rr1eEVGip3QoVnQthZivqceZ6r1POmXM3oR4SwBtnTajpBAPxn3a7gd1e5+Uh4JgunUmTPEUtapQKQsDI1PRg6CEQDIwvwEVCKQCowvFBfqNRqniqaAR9owINj3TohNmnvH7gwkFeGoX8z/jsbftsAzm/v2pNdt1AIh01hGVYALS1xrG0YlCwXr1n89VaD2ZugOCOQC8iM0YezIjBOuSrlKPpfIZAKwRwb1dzM+z9gWaOpk0R1i3ENWSoPWDA3Ps6Mh64e0RPnudw1Vi+0Ai1foKiRT6BJbxwDREQEZzAAAAAElFTkSuQmCC);
            background-size: 18px 18px;
            background-position: center center;
            background-repeat: no-repeat;
            content: "";
            display: block;
            height: 100%;
          }
          .ant-modal-close-icon {
            display: none;
          }
        }
        .ant-modal-header {
          border-bottom: none;
          padding: 18px 24px;
          border-radius: 8px 8px 0 0;
          .ant-modal-title {
            width: 736px;
            font-size: 18px;
            line-height: 25px;
            font-weight: 500;
            color: #000000;
            .modal-title{
              display: flex;
              white-space: nowrap;
              &__text{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .ant-modal-body {
          padding: 0 24px;
          .modal-content{
            .tree-drag-wrap{
              margin-top: 18px;
              height: 428px;
              width: 100%;
              border-radius: 4px;
              border: 1px solid #E7EAEB;
              display: flex;
              position: relative;
              .dkListBelongToMe-loading{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .knowledge-list{
                position: relative;
                display: flex;
                flex-direction: column;
                .sticky-top{
                  color: #999;
                  font-size: 12px;
                  line-height: 16px;
                  padding: 12px 18px 4px;
                }
                &__content{
                    flex: 1;
                    overflow: auto;
                    padding: 0 9px;
                    .content-name{
                        padding: 0 11px;
                        border-radius: 4px;
                        line-height: 36px;
                        height: 36px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        color: #262626;
                        white-space: nowrap;
                        .dk-iconfont{
                          font-size: 14px;
                        }
                        .dk-icon-dc-zhishiku-copy{
                          color: #777777;
                        }
                        .text{
                          width: 100%;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          padding-left: 8px;
                          font-size: 14px;
                        }
                        &:hover{
                            background: rgba(235,236,239,0.6);
                            border-radius: 4px;
                        }
                    }
                    .checked{
                      color: @primary-color;
                      font-weight: 500;
                    }
                }
            }
              .knowledge-page{
                  flex: 1;
                  overflow: hidden;
              }
            }
          }
        }
        .ant-modal-footer{
            border: 0;
            padding: 16px 24px;
            .modal-footer{
              display: flex;
              justify-content: space-between;
              align-items: center;
              &__text{
                font-weight: 400;
                color: #999999;
                font-size: 14px;
                display: block;
                max-width: 500px;
                text-align: left;
              }
              &__btn{
                .ant-btn{
                    width: 90px;
                    height: 36px;
                    line-height: 20px;
                    font-size: 14px;
                    &:disabled {
                      opacity: 0.5;
                    }
                    &:first-child,  &:first-child:hover{
                      color: #444B4F;
                      background: #F6F6F6;
                      border: 0;
                    }
                    &:last-child,  &:last-child:hover{
                      margin-left: 12px;
                      color: #fff;
                      background:@primary-color;
                      border-color:@primary-color;
                    }
                }
              }
            }
        }
      }
}
.cancel-edit-modal {
  padding-bottom: 0;

  :global {
    .ant-modal-content {
      border-radius: 6px;

      .ant-modal-body {
        padding: 32px 28px 24px;

        .ant-modal-confirm-title {
          font-size: 24px;
        }

        .ant-modal-confirm-content {
          margin-top: 10px;
          font-size: 16px;
          color: #444B4F;
          line-height: 24px;
        }

        .ant-modal-confirm-btns {
          margin-top: 36px;

          .ant-btn {
            width: 96px;
            height: 44px;
            background: #F6F6F6;
            border-radius: 4px;
            border: none;
            font-size: 16px;
            color: #444B4F;

            &::after {
              display: none;
            }

            &.ant-btn-primary {
              width: 112px;
              margin-left: 16px;
              color: #FFFFFF;
              background: @primary-color;
            }
          }
        }
      }
    }
  }
}
.success-tip {
  .link {
    color: @primary-color;
    cursor: pointer;
  }
}