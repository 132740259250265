/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
:global .safety-level-tooltip {
  max-width: 440px !important;
}
:global .safety-level-tooltip .ant-tooltip-content .ant-tooltip-inner {
  background: #FFFFFF;
  color: #252525;
  padding: 13px 16px;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  font-weight: 400;
  border: 1px solid #EFF0F2;
  margin-top: -3px;
}
:global .safety-level-tooltip .ant-tooltip-content .ant-tooltip-inner .data-safety-standard-link {
  color: #047FFE;
  margin-left: 2px;
}
.safety-level {
  display: flex;
  align-items: center;
  border-radius: 6px;
  color: #f1b44f;
  background-color: rgba(241, 180, 79, 0.12);
}
.safety-level-2 {
  color: #f1b44f;
  background-color: rgba(241, 180, 79, 0.12);
}
.safety-level-3 {
  color: #ff9300;
  background-color: rgba(255, 147, 0, 0.1);
}
.safety-level-4 {
  color: #ff5917;
  background-color: rgba(255, 89, 23, 0.12);
}
.safety-level-5 {
  color: #E73838;
  background-color: #FFE7EA;
}
.none-background {
  background: none;
}
.safety-level-icon {
  font-size: 12px !important;
  margin-right: 4px;
}
:global .safe-tag-large {
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  padding: 8px 10px;
}
:global .safe-tag-large .safety-level-icon {
  font-size: 10px;
}
