/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.hint-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #eaf2ff;
  padding: 4px 16px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
}
.hint-header .hint-icon {
  color: #047FFE;
  margin-right: 6px;
}
.hint-header .hint-body {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.hint-header .hint-body .hint-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}
.hint-header .hint-body .hint-close-icon-wrap {
  height: 100%;
  padding: 0 2px;
}
.hint-header .hint-body .hint-close-icon-wrap .hint-close-icon {
  width: 10px;
  height: 10px;
}
.hidden-hint {
  display: none;
}
