/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.image-enlarger .enlarger {
  width: 200px !important;
  border: 1px solid #f0f0f0;
  overflow: hidden;
  display: inline-block;
}
.image-enlarger .enlarger svg {
  max-width: 100%;
  max-height: 100%;
}
.image-enlarger .enlarger-svg {
  height: 200px !important;
}
.file-name-list {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 4px;
  object-fit: cover;
  object-position: left center;
  overflow: hidden;
  display: inline-block;
  line-height: 1;
}
.file-name-list svg {
  max-width: 100%;
  max-height: 100%;
}
.search-item-icon {
  width: 32px;
  height: 32px;
  margin-right: 0;
}
