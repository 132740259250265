/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.dropdown-radio {
  position: relative;
  display: inline-block;
  border: 1px solid #e4e9f3;
  min-width: 140px;
  height: 32px;
  border-radius: 4px;
  width: 140px;
  cursor: pointer;
  vertical-align: middle;
}
.dropdown-radio .dropdown-radio__value {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #17233E;
}
.dropdown-radio .dropdown-radio__value .dropdown-radio__caret {
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 30px;
  right: 10px;
  top: 0;
  background-image: url('./ico-caret.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.dropdown-radio .dropdown-radio__value span {
  height: 32px;
  line-height: 22px;
  padding: 5px 28px 5px 8px;
  font-size: 12px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  vertical-align: top;
}
.dropdown-radio:hover {
  border-color: #0066FF;
}
.dropdown-radio.active {
  border-color: #0066FF;
  box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
}
.dropdown-radio.active .dropdown-radio__caret {
  transform: rotate(0);
}
.dropdown-radio.active .dropdown-radio__wrapper {
  display: block;
}
.dropdown-radio .dropdown-radio__wrapper {
  padding-top: 5px;
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 2999;
}
.dropdown-radio .dropdown-radio__items {
  background: #FEFEFE;
  box-shadow: 0 2px 8px 0 rgba(23, 35, 62, 0.2);
  border-radius: 4px;
  overflow: hidden;
}
.dropdown-radio .dropdown-radio__item {
  position: relative;
  padding: 10px 28px 10px 10px;
  transition: background 0.3s ease;
  line-height: 20px;
  height: 40px;
}
.dropdown-radio .dropdown-radio__item.dropdown-radio__chkitem {
  background: #F7F7F8;
}
.dropdown-radio .dropdown-radio__item:hover {
  background: #F3F7Fe;
}
.dropdown-radio .dropdown-radio__item.dropdown-radio__disableitem {
  cursor: not-allowed;
}
.dropdown-radio .dropdown-radio__item span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font-size: 14px;
}
