/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.file-search-input {
  width: 100%;
  line-height: 36px !important;
}
.file-search-input :global .ant-select-selector {
  height: 36px !important;
  border-radius: 4px !important;
  padding-left: 50px !important;
  border: 1px solid rgba(148, 160, 176, 0.24) !important;
}
.file-search-input :global .ant-select-selection-search {
  left: 50px !important;
}
.file-search-input :global .ant-select-selection-placeholder,
.file-search-input :global .ant-select-selection-item {
  line-height: 36px !important;
  font-size: 14px !important;
}
.file-search-icon {
  color: #bbb;
  font-size: 20px;
  position: absolute;
  left: 20px;
  top: 10px;
}
.not-found-content {
  height: 26px;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #94a0b0;
}
.file-search-item {
  display: flex;
  align-items: center;
  padding: 6px 24px 6px 12px;
}
.item-left {
  width: 30px;
  flex-shrink: 0;
}
.item-left img {
  width: 100%;
}
.item-right {
  padding-left: 16px;
  box-sizing: border-box;
  flex: 1;
}
.item-right p:first-child {
  @include ellipsis;
  font-size: 14px;
  line-height: 22px;
  color: #2f343c;
  width: 450px;
}
.item-right p:last-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 18px;
  color: #94a0b0;
  width: 450px;
}
