/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.recentList {
  height: 240px;
  overflow: auto;
  padding: 0 8px 3px;
  margin-bottom: 35px;
}
.recentList .recent-item {
  cursor: pointer;
}
.recentList .page-icon {
  width: 32px;
  height: 32px;
  margin-right: 15px;
}
.recentList .pageName {
  font-size: 14px;
  font-weight: 400;
  color: #111111;
  margin-bottom: 3px;
  max-width: 280px;
}
.recentList .dkName {
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(34, 42, 53, 0.5);
  line-height: 17px;
}
.recentList .dkName-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 3px 2px 0;
}
.recentList .dkName-text {
  max-width: 230px;
}
.recentList :global .ant-list-item-meta {
  align-items: center;
}
.recentList :global .ant-empty-normal {
  display: none;
}
.recentList :global .ant-list-item-meta-title {
  margin-bottom: 3px;
  height: 20px;
  line-height: 20px;
}
.recentList :global .ant-list-split .ant-list-item {
  border-bottom: none;
}
.recentList .empty {
  margin-top: 38px;
}
.recentList .empty-pic {
  display: block;
  width: 120px;
  height: 107px;
  margin: 0 auto;
}
.recentList .empty-text {
  text-align: center;
  margin: 14px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.noMore {
  margin: 20px 0 10px;
}
