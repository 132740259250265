/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.delete-confirm {
  min-width: 516px;
}
.delete-confirm .confirm-content-delete {
  margin-top: 15px;
}
.delete-confirm .confirm-content-delete .content-li-one {
  margin-bottom: 10px;
}
.delete-confirm .confirm-content-delete li {
  list-style: disc;
}
