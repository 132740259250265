/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.normal-options-wrap {
  max-height: 280px;
  overflow: auto;
  position: relative;
  min-height: 30px;
}
.normal-options-wrap .option-item {
  height: auto !important;
  padding: 0 !important;
}
.simple-empty {
  margin-top: 0;
}
.simple-empty :global .sub-div {
  height: 0;
}
.simple-empty :global .title {
  margin-top: 0px;
}
