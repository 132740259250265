/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.header-wrap {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 106;
  border-bottom: 1px solid rgba(34, 42, 53, 0.08);
  background-color: #fff;
}
.header-wrap > div {
  flex-shrink: 1;
  flex-grow: 1;
  width: 300px;
}
.header-wrap .dk-logo {
  width: 92px;
  height: 28px;
  margin-left: 14px;
}
.header-wrap .betaIcon {
  margin-left: 4px;
  width: 34px;
  height: 18px;
}
.header-wrap .left {
  margin: 16px;
  display: flex;
  align-items: center;
}
.header-wrap .middle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 540px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 24px;
}
@media screen and (max-width: 1260px) {
  .header-wrap .middle {
    width: 420px;
  }
}
.header-wrap .header-middle-dc {
  margin-left: 256px;
  justify-content: flex-start;
  width: 340px;
}
.header-wrap .right {
  margin: 18px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 330px;
}
.header-wrap .right .avatar {
  cursor: pointer;
  display: block;
  width: 26px;
  height: 26px;
  border: 1px solid #F4F4F4;
  flex-shrink: 0;
}
.header-wrap .cooperEnter {
  cursor: pointer;
  font-size: 24px;
  color: #676D71;
  background: #ffffff;
  line-height: 22px;
}
.header-wrap .cooperEnter:hover {
  background: #EEEEEE;
}
.header-wrap .cooperEnter-active {
  background: #EEEEEE;
}
.header-wrap .title {
  margin-left: 12px;
  height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: #222A35;
  line-height: 28px;
  white-space: nowrap;
}
.header-wrap .createDk {
  flex-shrink: 0;
  min-width: 130px;
  word-break: keep-all;
  margin: 0 28px;
  background: #047FFE;
  color: #ffffff;
  padding: 6px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(11, 131, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header-wrap .createDk:focus {
  outline: none;
}
.header-wrap .createDk .create-icon {
  font-size: 12px;
  margin-right: 8px;
  font-weight: bold;
}
.header-wrap .createDk .create-text {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}
.header-wrap .switchLocale {
  padding-left: 4px;
  margin-right: 20px;
  cursor: pointer;
  height: 24px;
  border-radius: 2px;
  color: #505050;
  display: flex;
  align-items: center;
}
.header-wrap .switchLocale:hover {
  background: #EEEEEE;
}
.header-wrap .switchLocale .switchLocaleIcon {
  margin-left: 4px;
  width: 14px;
  height: 14px;
}
.header-wrap .knowledge-name {
  height: 22px;
  font-size: 15x;
  color: #222A35;
  line-height: 22px;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;
  font-weight: 500px;
}
.header-wrap .knowledge-name span {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  font-weight: 500;
  font-size: 15px;
}
.header-wrap .knowledge-name i {
  cursor: pointer;
}
.header-wrap .moreDk {
  transition: all 0.2s;
  display: block;
}
.header-wrap .moreDk.isOpen {
  transform: rotate(180deg);
}
.header-wrap .divider {
  margin: 0 14px;
  width: 1px;
  height: 16px;
  background-color: #E9E9EB;
}
.header-wrap .localeIcon {
  margin-right: 24px;
  width: 14px;
  height: 14px;
  transform: rotate(90deg);
}
.cooperEnterChoose .cooperEnterMenu .cooperEnterRow {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 206px;
  height: 56px;
  font-size: 16px;
  font-weight: 500;
  color: #1C2A3D;
  line-height: 19px;
  border-radius: 4px;
}
.cooperEnterChoose .cooperEnterMenu .cooperEnterRow:hover {
  background: #F4F4F4;
}
.cooperEnterChoose .cooperEnterMenu .cooperEnterIcon {
  width: 36px;
  height: 36px;
  margin: 0 10px 0 13px;
  background: #ffffff;
  border: 1px solid #E8EAED;
  border-radius: 50%;
}
.switchLocaleChoose .menu .row,
.loginOutChoose .menu .row {
  padding-left: 14px;
  color: #111111;
  width: 99px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.switchLocaleChoose .menu .row:hover,
.loginOutChoose .menu .row:hover {
  background: #F4F4F4;
}
.home-list-icon {
  width: 6px;
  height: 6px;
  border-radius: 2px;
  margin-right: 14px;
  margin-left: 30px;
  opacity: 0.7;
}
.home-list-icon.home-list-icon-cooper {
  background-color: #0066FF;
}
.home-list-icon.home-list-icon-dk {
  background-color: #047FFE;
}
.home-list-icon.home-list-icon-team {
  background-color: #FD7D48;
}
:global .knowLedge_cooperEnter .ant-popover-inner {
  box-shadow: 0px 6px 20px 0px rgba(18, 19, 21, 0.12);
}
:global .knowLedge_cooperEnter .ant-popover-inner .ant-popover-inner-content {
  padding: 7px;
}
:global .knowLedge_cooperEnter.ant-popover-placement-bottomLeft {
  padding-top: 6px !important;
}
:global .knowLedge_switchLocale .ant-popover-inner,
:global .knowLedge_loginOut .ant-popover-inner {
  box-shadow: 0px 6px 20px 0px rgba(18, 19, 21, 0.08);
  border: none !important;
}
:global .knowLedge_switchLocale .ant-popover-inner .ant-popover-inner-content,
:global .knowLedge_loginOut .ant-popover-inner .ant-popover-inner-content {
  padding: 7px;
}
.header-wrap-dc {
  z-index: 20;
}
.header-ai-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  color: #644bfc;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 12px;
  white-space: nowrap;
}
.header-ai-button:hover {
  background: #ECEDF1;
}
.header-ai-button.header-ai-button-disabled {
  cursor: not-allowed;
  color: rgba(100, 75, 252, 0.6);
}
.header-ai-button.header-ai-button-disabled .header-ai-button-icon {
  opacity: 0.6;
}
.header-ai-button.header-ai-button-disabled .header-ai-button-beta {
  opacity: 0.6;
}
.header-ai-button.header-ai-button-disabled .header-ai-button-disabled-tip {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 8px);
  padding: 16px;
  background: #fff;
  border: 0.5px solid #F1F1F1;
  border-radius: 6px;
  font-size: 14px;
  color: #222A35;
  width: 249px;
  white-space: normal;
  cursor: default;
  filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.1));
}
.header-ai-button.header-ai-button-disabled .header-ai-button-disabled-tip::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
  background: #FFFFFF;
  box-sizing: border-box;
  border-width: 0.5px 0.5px 0px 0px;
  border-style: solid;
  border-color: #F1F1F1;
  width: 10px;
  height: 10px;
}
.header-ai-button.header-ai-button-disabled:hover {
  background: rgba(236, 237, 241, 0.6);
}
.header-ai-button.header-ai-button-disabled:hover .header-ai-button-disabled-tip {
  display: block;
}
.header-ai-button .header-ai-button-beta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 13px;
  color: #fff;
  border-radius: 20px;
  background: #7D96F9;
  font-size: 10px;
  line-height: 13px;
  font-weight: 500;
  margin-left: 4px;
  font-style: normal;
}
.header-ai-button-icon {
  width: 18px;
  height: 18px;
}
