/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.rename {
  margin-top: 16px;
  font-size: 14px;
  margin-bottom: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.rename input:hover,
.rename input:focus {
  border: 1px solid #047FFE !important;
}
.rename p {
  width: 45px;
}
.rename .error-text {
  font-size: 12px;
  color: #ff3f5a;
  position: absolute;
  top: 36px;
  left: 44px;
}
