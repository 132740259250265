/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-helper-wrap .cooper-helper,
.cooper-helper-wrap .cooper-helper-disable {
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cooper-helper-wrap .cooper-helper :global .dk-icon-gongnengshangxin,
.cooper-helper-wrap .cooper-helper-disable :global .dk-icon-gongnengshangxin {
  font-size: 18px;
}
.cooper-helper-wrap .cooper-helper {
  cursor: pointer;
}
.cooper-helper-wrap .cooper-helper:hover {
  background: rgba(34, 42, 53, 0.08);
  cursor: pointer;
}
.cooper-helper-popover :global .ant-popover-arrow {
  display: inherit !important;
}
.cooper-helper-popover :global .ant-popover-inner {
  box-shadow: 0px 6px 24px -3px rgba(0, 0, 0, 0.12) !important;
}
.cooper-helper-popover :global .ant-popover-inner-content {
  padding: 8px !important;
}
.cooper-helper-popover :global .help-center-list-item {
  width: 224px;
  height: 34px !important;
}
.cooper-helper-popover :global .help-center-list-item .ml-8 {
  margin-left: 8px;
}
.cooper-helper-popover :global .help-center-list-item .dk-new {
  width: 24px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  margin-left: 6px;
  font-size: 9px;
  border: 0;
  border-radius: 4px;
  padding: 0;
}
.cooper-helper-popover :global .help-center-list-item .dk-iconfont {
  font-size: 16px;
}
.cooper-helper-popover :global .help-conter-split {
  margin-top: 10px;
  border-bottom: 1px solid #EEEEEF;
}
.cooper-helper-popover :global .help-center-list-banner {
  margin-top: 10px;
}
.cooper-helper-popover :global .help-center-list-banner .banner-title {
  width: 224px;
  padding: 6px 12px;
  border-radius: 8px 8px 0px 0px;
  background: #E1EBFF;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
  letter-spacing: 0px;
  color: #1A6EFF;
}
.cooper-helper-popover :global .help-center-list-banner .banner-img {
  display: inline-block;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
  width: 224px;
  height: 110px;
  border: none;
}
