/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.noMore {
  margin-top: 29px;
  text-align: center;
  height: 18px;
  font-size: 13px;
  color: #AAAAAA;
  line-height: 18px;
}
