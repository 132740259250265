/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.white-screen-container {
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  position: relative;
}
.white-screen-container .content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.white-screen-container .content .img {
  width: 300px;
  height: 300px;
}
.white-screen-container .load-error-wrap {
  font-weight: bold;
}
.white-screen-container .load-error-wrap .solution-li {
  list-style-type: decimal;
  font-weight: 400;
}
.white-screen-container .link {
  color: #047FFE;
  margin-left: 4px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2147483648 !important;
}
.white-screen-container .fresh-btn {
  background: none;
  border: none;
  color: #047FFE;
  font-weight: bold;
  line-height: 22px;
  margin-left: 2px;
  padding: 0 4px;
  cursor: pointer;
}
