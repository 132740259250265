/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.dk-upload-card-icon {
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dk-upload-card-icon::after {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-position: center center;
  background-repeat: no-repeat;
}
.dk-upload-header {
  display: flex;
  align-items: center;
}
.dk-upload-uploading {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  margin-right: 8px;
  background-image: url(./icon/async.svg);
  animation: turn 2s linear infinite;
}
.dk-upload-text {
  font-size: 18px;
}
.dk-upload-done {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  margin-right: 8px;
  background-image: url(./icon/success.svg);
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dk-upload-container {
  border-radius: 6px;
  width: 400px;
}
.dk-upload-container :global .ant-card-head {
  padding: 0 15px;
}
.dk-upload-container :global .ant-card {
  border: 1px solid rgba(148, 160, 176, 0.14);
  box-shadow: 0 4px 16px 0 rgba(47, 52, 60, 0.2);
  border-radius: 10px;
}
.dk-upload-container :global .ant-card-body {
  padding: 0 15px;
  height: 242px;
  overflow-y: auto;
}
.dk-upload-minimize :global .ant-card-body {
  display: none;
}
.dk-upload-success {
  background-size: 20px 20px;
  background-image: url(./icon/success.svg);
  cursor: pointer;
}
.dk-upload-close {
  float: right;
  margin-left: 14px;
}
.dk-upload-close::after {
  background-image: url(./icon/close.svg);
}
.dk-upload-close:hover {
  background-color: #F4F4F4;
}
.dk-upload-close:hover::after {
  background-image: url(./icon/close-active.svg);
}
.dk-upload-switch {
  float: left;
}
.dk-upload-switch::after {
  background-image: url(./icon/arror.svg);
}
.dk-upload-switch:hover {
  background-color: #F4F4F4;
}
.dk-upload-switch:hover::after {
  background-image: url(./icon/arror-active.svg);
}
.minimize {
  transform: rotate(180deg);
}
