/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.message-tip {
  color: #047FFE;
  cursor: pointer;
  position: relative;
  padding: 0 5px;
  display: inline-block;
}
