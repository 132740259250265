/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-empty-tip {
  min-height: 300px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: rgba(34, 42, 53, 0.7);
}
.cooper-empty-tip img {
  display: block;
  width: 210px;
  margin: 0 auto 12px;
  -webkit-user-drag: none;
}
.cooper-empty-tip p {
  font-size: 14px;
  color: rgba(34, 42, 53, 0.7);
  text-align: center;
}
.cooper-empty-tip .title {
  font-size: 12px;
  color: #656A72;
}
.cooper-empty-tip a {
  color: #0066FF !important;
}
