/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.menhu-aside-wrap {
  width: 280px;
  padding: 36px 6px 36px 26px;
}
.menhu-aside-wrap .aside-li-item {
  padding: 12px 6px 12px 20px;
  border-radius: 6px !important;
  font-weight: normal;
}
.menhu-aside-wrap.menhu-aside-wrap-dc {
  width: 230px;
  padding: 0 0 18px 6px;
  border-right: 1px solid #eef1f4;
  position: absolute;
  height: 100vh;
  top: -62px;
  left: 0;
  z-index: 23;
  background: #ffffff;
}
.menhu-aside-wrap.menhu-aside-wrap-dc .aside-menu-li {
  margin-bottom: 6px;
}
.menhu-aside-wrap .mh-aside-div {
  width: calc(100% - 6px);
  height: 1px;
  background: #EBECF0;
  border-radius: 6px;
  margin: 20px 6px 20px 0 ;
}
.aside-li-item-dc {
  padding: 9px 15px !important;
  font-weight: normal !important;
}
.aside-li-item-dc > i {
  font-size: 20px !important;
  margin-right: 13px !important;
  color: #333333 !important;
}
.aside-container div:nth-child(4) .aside-li-item > i {
  font-size: 12px !important;
  margin-left: 1.5px;
}
.aside-container div:nth-child(4) .aside-li-item-dc > i {
  font-size: 15px !important;
}
