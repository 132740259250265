/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.apply-detail {
  width: 400px;
  height: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -110px;
  margin-left: -200px;
  text-align: center;
}
.apply-success {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
}
.apply-tips {
  font-size: 14px;
  font-weight: 400;
  color: #505050;
  line-height: 20px;
  margin-top: 3px;
}
.apply-success-icon {
  display: block;
  width: 22px;
  height: 22px;
  background-size: 22px 22px;
  margin-right: 8px;
  background-image: url(../../../uploadTips/icon/success.svg);
}
.apply-box {
  width: 400px;
  background: #F5F6F7;
  border-radius: 7px;
  margin-top: 20px;
  padding: 16px 20px;
  position: relative;
}
.apply-box :global .ant-input {
  border-radius: 4px;
  margin-bottom: 6px;
  font-size: 14px;
  padding: 14px 16px;
}
.apply-box :global .ant-input:focus {
  border: 1px solid #2e9dff;
}
.apply-box p {
  margin-bottom: 10px;
  display: flex;
}
.apply-box .apply-name {
  margin-top: 4px;
}
.apply-box .apply-value {
  flex: 1;
  text-align: left;
}
.apply-box p span:first-child {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  text-align: right;
  flex-shrink: 0;
}
.apply-box p span:last-child {
  word-break: break-all;
}
.apply-box a,
.apply-box a:hover {
  color: #047FFE;
  font-weight: bold;
}
.apply-box .apply-Approver span:first-child {
  text-align: left;
}
.apply-box .apply-Approver {
  margin-bottom: 16px;
}
.apply-box .apply-line {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 10px;
  display: block;
}
.apply-box .apply-text {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 17px;
  margin-top: 7px;
}
.apply-box button {
  margin-top: 10px;
  width: 100%;
  height: 34px;
  font-weight: bold;
  border-radius: 4px;
}
.apply-box .button-urge {
  margin-bottom: 4px;
  margin-top: 16px;
}
.apply-title {
  display: inline-flex;
  justify-content: center;
}
.apply-title-text {
  text-align: center;
  /* 将文本水平居中 */
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.apply-select {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: -4px;
}
.apply-popover {
  padding: 7px;
}
.apply-popover li,
.apply-popover .item-li {
  line-height: 36px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 13px;
  justify-content: space-between;
  cursor: pointer;
}
.apply-popover li span,
.apply-popover .item-li span {
  margin-right: 28px;
}
.apply-popover li:hover,
.apply-popover .item-li:hover {
  background: #F4F4F4;
}
.apply-popover li :global .dk-icon-xuanzhong,
.apply-popover .item-li :global .dk-icon-xuanzhong {
  color: #0066FF;
}
.role-name {
  line-height: 20px;
  font-size: 14px;
  color: #047FFE;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-weight: 500;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 8px;
}
.role-name span {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.role-name :global .anticon {
  font-size: 12px;
}
.role-name .role {
  margin-right: 5px;
}
.role-name:hover {
  background: rgba(0, 0, 0, 0.05);
}
.more-role {
  transition: all 0.2s;
  display: block;
}
.more-role.isOpen {
  transform: rotate(180deg);
}
.perm-help {
  position: absolute;
  top: 14px;
  right: 12px;
  background: #F5F6F7;
  border-radius: 4px;
  color: #047FFE;
  height: 24px;
  line-height: 16px;
  font-size: 12px;
  padding: 4px 8px;
  font-weight: 400 !important;
}
.perm-help:hover {
  color: #047FFE;
  background: rgba(0, 0, 0, 0.05);
}
.err-tip-inPhone-apply {
  width: 90%;
}
.err-tip-inPhone-apply .apply-box {
  width: 90%;
  margin: 20px auto;
}
.tip-inPhone-apply-detail {
  width: 100%;
  height: auto;
  margin-top: 0px;
  margin-left: 0px;
  transform: translate(-50%, -50%);
}
.tip-inPhone-apply-detail .apply-box {
  width: 90%;
  margin: 20px auto;
}
