/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.footer-content {
  background: #141414;
  border-radius: 6px;
  width: fit-content;
  display: flex;
  height: 44px;
  align-items: center;
  padding: 0 8px;
}
.footer-content .footer-content-item {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
}
.footer-content .footer-content-item img {
  width: 16px;
  height: 16px;
}
.footer-content .footer-content-item:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.footer-content .footer-content-item:focus {
  background-color: rgba(255, 255, 255, 0.15);
}
.footer-content .footer-content-item:last-child {
  margin-right: 0;
}
