/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.transfer-modal {
  min-width: 828px !important;
  padding: 0;
}
.transfer-modal :global .ant-modal-content {
  border-radius: 8px;
}
.transfer-modal :global .ant-modal-close-x {
  width: 20px;
  height: 20px;
  line-height: 60px;
  margin-right: 24px;
  margin-top: 21px;
}
.transfer-modal :global .ant-modal-close-x::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAACJUlEQVRYR+2XPU8CQRCGZ8TK78bef3Gl2EqiiSYkil+J3yQ2hP64loCNBowx6mlhvMJKrWn5AfTaQoWIiYkw5ojocdm9vd210ATa3bl99p13ZwaEP/bDP8YDfSBRRv6XQul0ejiXyzVFt5JZ38lkhk4zmTdeDFehjf2UA4iLhGDHpo3teDzekjnYv9c0zYGnavMYob1FCPd24XCB9T0mkHuL92qjDkSDnSDEm1jUWFWF6sDUGjYQrHxBUGR0cPw8m234ofgKJVMnRLD7HaAIxYABBLy+LObXQivkbnQcJ/JQKl8D0ZIqFAsGEJ2pyZGEZVkfUkC6UCowHXeIjKqiFA8mFjWWRT4UAskqpQMTSqGugmGU0oWRAhIpValUyPe0ARFvZ6NGQpQmr21CpcwbwFMKgFqeOqMEI61QYPo81CrKdMOlFRJB6cAoK+QGugZ+rr1eEVGip3QoVnQthZivqceZ6r1POmXM3oR4SwBtnTajpBAPxn3a7gd1e5+Uh4JgunUmTPEUtapQKQsDI1PRg6CEQDIwvwEVCKQCowvFBfqNRqniqaAR9owINj3TohNmnvH7gwkFeGoX8z/jsbftsAzm/v2pNdt1AIh01hGVYALS1xrG0YlCwXr1n89VaD2ZugOCOQC8iM0YezIjBOuSrlKPpfIZAKwRwb1dzM+z9gWaOpk0R1i3ENWSoPWDA3Ps6Mh64e0RPnudw1Vi+0Ai1foKiRT6BJbxwDREQEZzAAAAAElFTkSuQmCC);
  background-size: 18px 18px;
  background-position: center center;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
}
.transfer-modal :global .ant-modal-close-x .ant-modal-close-icon {
  display: none;
}
.transfer-modal :global .ant-modal-header {
  border-bottom: none;
  padding: 18px 24px;
  border-radius: 8px 8px 0 0;
}
.transfer-modal :global .ant-modal-header .ant-modal-title {
  width: 736px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  color: #000000;
}
.transfer-modal :global .ant-modal-header .ant-modal-title .modal-title {
  display: flex;
  white-space: nowrap;
}
.transfer-modal :global .ant-modal-header .ant-modal-title .modal-title__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transfer-modal :global .ant-modal-body {
  padding: 0 24px;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap {
  margin-top: 18px;
  height: 428px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #E7EAEB;
  display: flex;
  position: relative;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .dkListBelongToMe-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .knowledge-list {
  position: relative;
  display: flex;
  flex-direction: column;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .knowledge-list .sticky-top {
  color: #999;
  font-size: 12px;
  line-height: 16px;
  padding: 12px 18px 4px;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .knowledge-list__content {
  flex: 1;
  overflow: auto;
  padding: 0 9px;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .knowledge-list__content .content-name {
  padding: 0 11px;
  border-radius: 4px;
  line-height: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #262626;
  white-space: nowrap;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .knowledge-list__content .content-name .dk-iconfont {
  font-size: 14px;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .knowledge-list__content .content-name .dk-icon-dc-zhishiku-copy {
  color: #777777;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .knowledge-list__content .content-name .text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  font-size: 14px;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .knowledge-list__content .content-name:hover {
  background: rgba(235, 236, 239, 0.6);
  border-radius: 4px;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .knowledge-list__content .checked {
  color: #047FFE;
  font-weight: 500;
}
.transfer-modal :global .ant-modal-body .modal-content .tree-drag-wrap .knowledge-page {
  flex: 1;
  overflow: hidden;
}
.transfer-modal :global .ant-modal-footer {
  border: 0;
  padding: 16px 24px;
}
.transfer-modal :global .ant-modal-footer .modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.transfer-modal :global .ant-modal-footer .modal-footer__text {
  font-weight: 400;
  color: #999999;
  font-size: 14px;
  display: block;
  max-width: 500px;
  text-align: left;
}
.transfer-modal :global .ant-modal-footer .modal-footer__btn .ant-btn {
  width: 90px;
  height: 36px;
  line-height: 20px;
  font-size: 14px;
}
.transfer-modal :global .ant-modal-footer .modal-footer__btn .ant-btn:disabled {
  opacity: 0.5;
}
.transfer-modal :global .ant-modal-footer .modal-footer__btn .ant-btn:first-child,
.transfer-modal :global .ant-modal-footer .modal-footer__btn .ant-btn:first-child:hover {
  color: #444B4F;
  background: #F6F6F6;
  border: 0;
}
.transfer-modal :global .ant-modal-footer .modal-footer__btn .ant-btn:last-child,
.transfer-modal :global .ant-modal-footer .modal-footer__btn .ant-btn:last-child:hover {
  margin-left: 12px;
  color: #fff;
  background: #047FFE;
  border-color: #047FFE;
}
.cancel-edit-modal {
  padding-bottom: 0;
}
.cancel-edit-modal :global .ant-modal-content {
  border-radius: 6px;
}
.cancel-edit-modal :global .ant-modal-content .ant-modal-body {
  padding: 32px 28px 24px;
}
.cancel-edit-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-title {
  font-size: 24px;
}
.cancel-edit-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-content {
  margin-top: 10px;
  font-size: 16px;
  color: #444B4F;
  line-height: 24px;
}
.cancel-edit-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns {
  margin-top: 36px;
}
.cancel-edit-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  width: 96px;
  height: 44px;
  background: #F6F6F6;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  color: #444B4F;
}
.cancel-edit-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn::after {
  display: none;
}
.cancel-edit-modal :global .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  width: 112px;
  margin-left: 16px;
  color: #FFFFFF;
  background: #047FFE;
}
.success-tip .link {
  color: #047FFE;
  cursor: pointer;
}
