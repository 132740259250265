/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.error-wrap {
  width: 396px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -60px;
}
.error-wrap .title {
  margin-top: 12px;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}
.error-wrap .desc {
  user-select: text;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 42, 53, 0.7);
  line-height: 20px;
}
.error-wrap .icon {
  width: 200px;
}
.error-tip-inPhone {
  width: 80%;
}
