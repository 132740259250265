/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-preview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.8);
}
.cooper-preview-header {
  position: fixed;
  z-index: 3015;
  height: 56px;
  width: 100%;
  background: #141414;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
.cooper-preview-body {
  position: absolute;
  top: 56px;
  bottom: 80px;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cooper-preview-body > img {
  display: none;
}
.cooper-preview-body .cp-video-content {
  width: 80%;
  height: 80%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cooper-preview-body .cp-unknown {
  color: #fff;
}
.cooper-preview-body .cp-pre {
  width: 80%;
  height: 80%;
  overflow: auto;
  padding: 10px;
  background: #fff;
  color: #333;
}
.cooper-preview-footer {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  z-index: 3015;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cooper-preview .cp-content {
  position: relative;
  overflow: auto;
  display: flex;
  width: 80%;
  height: 80%;
  justify-content: center;
  align-items: center;
}
.cooper-preview .cp-content.has-image {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}
.cooper-preview .cp-content.has-pdf {
  height: 100%;
}
.cooper-preview .cp-pre {
  overflow: auto;
  padding: 10px;
  background: #fff;
  color: #333;
}
.cooper-preview .video-react {
  height: 100% !important;
  width: 100% !important;
}
.cooper-preview .cp-unknown {
  display: flex;
  font-size: 14px;
  color: #fff;
  align-items: center;
  justify-content: center;
}
.cooper-preview .cp-foot {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  height: 68px;
  width: 100%;
  background: #000;
  color: #fff;
}
.cooper-preview .cp-foot-tool {
  width: 100%;
  height: 68px;
  line-height: 68px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.cooper-preview .cp-ft-item {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.cooper-preview .cp-ft-item .cp-ft-icon {
  display: inline-block;
  height: 20px;
  margin: 0 4px 0 18px;
}
.cooper-preview .cp-ft-item .cp-ft-text {
  font-size: 12px;
  line-height: 20px;
}
.cooper-preview .cp-ft-item .cp-image {
  flex-shrink: 0;
}
.cooper-preview .video-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.viewer-container-fullscreen .cooper-preview-header {
  display: none;
}
.viewer-container-fullscreen .viewer-container .viewer-footer {
  height: 0px !important;
  overflow: hidden !important;
}
.viewer-container-fullscreen .viewer-container .viewer-navbar {
  height: 0px;
}
.viewer-container-fullscreen-cancel {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 40px;
  top: 40px;
  z-index: 10000;
  background-image: url('./icon/close.png');
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center center;
  cursor: pointer;
}
.viewer-container-fullscreen-cancel::before {
  content: attr(title);
  display: block;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  top: -20px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 12px;
  color: #fff;
  text-align: center;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  background: rgba(255, 255, 255, 0.9);
  color: #222A35;
  border-radius: 4px;
}
.viewer-container-fullscreen-cancel:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.viewer-container-fullscreen-cancel:hover::before {
  opacity: 1;
}
