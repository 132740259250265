/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-tag {
  display: inline-block;
  border-radius: 2px;
  height: 20px;
  line-height: 20px;
  font-weight: bold;
  background: #E5F2FE;
  color: #047FFE;
  padding: 0 4px;
  font-size: 12px;
  box-sizing: content-box;
  margin-left: 6px;
  transform: scale(0.916);
  white-space: nowrap;
}
.cooper-tag .icon {
  width: 12px;
  margin-right: 2px;
  position: relative;
  bottom: 2px;
}
.cooper-tag .icon-close {
  font-size: 10px;
  margin-left: 4px;
}
.blue {
  background: #E5F2FE;
  color: #047FFE;
}
.yellow {
  background: #F7DA9C;
  color: #6D4800;
}
.gray-line {
  border: 1px solid rgba(34, 42, 53, 0.1);
  background: #FFF;
  color: rgba(34, 42, 53, 0.5);
}
.out-yellow {
  color: #F18B00;
  background: rgba(241, 139, 0, 0.1);
}
.safety-level-2 {
  color: #f1b44f;
  background-color: rgba(241, 180, 79, 0.12);
}
.safety-level-3 {
  color: #ff9300;
  background-color: rgba(255, 147, 0, 0.1);
}
.safety-level-4 {
  color: #ff5917;
  background-color: rgba(255, 89, 23, 0.12);
}
.safety-level-5 {
  color: #E73838;
  background-color: #FFE7EA;
}
