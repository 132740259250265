/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.import-tip :global .ant-modal-header {
  display: none;
}
.import-tip :global .ant-modal-close {
  display: none;
}
.import-tip :global .ant-modal-body {
  padding: 24px;
}
.import-tip-modal.ant-modal-wrap {
  z-index: 2000;
}
