/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  max-width: 50%;
}
.header-left .back-icon {
  font-size: 18px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-left .icon-word {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 2px;
}
.header-left .file-name {
  font-size: 14px;
  max-width: calc(100% - 40px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header-right .open-in-cooper {
  display: flex;
  align-items: center;
  width: auto;
  height: 32px;
  border: 1px solid #FFFFFF80;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  position: relative;
  margin-right: 30px;
  padding: 0 16px 0 12px;
  background-color: transparent;
  cursor: pointer;
}
.header-right .open-in-cooper img {
  width: 18px;
  height: 18px;
}
.header-right .open-in-cooper::after {
  content: '';
  height: 70%;
  width: 1px;
  background-color: #FFFFFF;
  position: absolute;
  right: -21px;
}
.header-right .button {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 10px 0;
  opacity: 0.8;
  cursor: pointer;
  border: none;
}
.header-right .button img {
  width: 18px;
}
.header-right .button:focus {
  outline: none;
}
.header-right .button:hover {
  opacity: 1;
}
.header-right .button.disabled {
  opacity: 0.4;
}
.header-right .button.disabled:hover {
  background-color: #333333;
}
.header-right .more {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 12px;
}
.header-right .more img {
  width: 16px;
  height: 3px;
}
.header-right .more:hover {
  background: #333333;
  border-radius: 3px;
}
.hover-no-auth-tip {
  width: 254px;
}
.hover-no-auth-tip .ant-tooltip-inner {
  color: #222A35 !important;
  background-color: #FFFFFF !important;
  padding: 16px !important;
}
.hover-no-auth-tip .ant-tooltip-inner .top-text {
  font-size: 14px;
  line-height: 22px;
}
.hover-no-auth-tip .ant-tooltip-inner .top-text .auth-type {
  margin: 0px 3px;
  font-weight: bold;
}
.hover-no-auth-tip .ant-tooltip-inner .bot-text-link {
  display: block;
  color: #047FFE;
  font-size: 12px;
  line-height: 17px;
}
.hover-no-auth-tip .ant-tooltip-inner .bot-text-link:hover {
  text-decoration: underline;
}
.hover-no-auth-tip .ant-tooltip-inner .bot-text-link:last-child {
  margin-top: 6px;
}
